import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import {BlueDivider } from 'components/mui/divider/divider';
import ArticleCardA from 'components/mui/cards/articleCardA';
import {Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import {useInView } from 'react-intersection-observer'
import HomeSteps from './homeSteps';


const ProductsDemo = () =>{
    const demoMidDes ={
        title:'Display Video GoStory',
        content:'You create a great story and we create true engagement. We ensure your content reaches your intended audience through our robust targeting and broad distribution.'
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    const containerRef = React.useRef(null);    
    return (
        <WideContainer className='home-productdemo-container center-align'>
            <BlueDivider width='40%' height='20px' marginTop='30px' marginLeft='0'/>
            <Grid className='center-align' pt={{lg:0,xs:5}}>
            <NarrowContainer>
            <Slide timeout={{enter:1000}} in={inView} direction="right" container={containerRef.current}>
                    <Grid item xs={12} sm={5} md={4} className='center-align'>
                        <ArticleCardA width='300px' height='500px' title={demoMidDes.title} content={demoMidDes.content} link='publishers'/>
                    </Grid>
            </Slide>
            <Grow timeout={{enter:800}} in={inView}>
                    <Grid item xs={12} sm={7} md={8} className='home-steps'>
                        <HomeSteps/>
                    </Grid>
            </Grow>
            </NarrowContainer>
            <div className='observer' ref={inViewRef}></div>
            </Grid>          
        </WideContainer>
    );
}

export default ProductsDemo;