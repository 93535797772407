import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import ArticleCardA from '../cards/articleCardA';

const ContentRowB = ({title='',contents='',picSrc='',button=true,long=true,btnText='Learn More',link='',video=false,src=''}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        backgroundColor:'var(--pxft-lightgrey)',
        overflow:'hidden'
    }
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div ref={inViewRef1}></div>
                <div className='center-align content-column' style={containerStyle}>
                    <NarrowContainer className='mobile-reverse'>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                            {video?
                            <video preload='none' autoPlay webkit-playsinline="true" className='contentVideo' muted playsInline loop src={src}/>
                            :  <img loading='lazy' className='contentPic' src={'/' + picSrc} alt=''/>
                            }
                            
                        </Grid>
                    </Grow>
                    <Slide timeout={{enter:800}} in={inView} direction="left" container={containerRef.current}>
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            <ArticleCardA width='300px' height='500px' title={title} content={contents} long={long} button={button} btnText={btnText} link={link}/>
                        </Grid>
                    </Slide>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default ContentRowB;