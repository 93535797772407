import React, { Component } from 'react';
import ContactusHeader from 'components/company/contactus/header';
import ContactusArticle from 'components/company/contactus/contactusArticle';
import 'assets/styles/company.css'
class ContactUs extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return (
            <div>
                <ContactusHeader/>
                <ContactusArticle/>
            </div>
        );
    }
}

export default ContactUs;