import { NarrowContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import React from 'react';
import BlogCard from './blogCard';


const BlogsArticle = ({blogs})=> {
    return (
        <div className='center-align' style={{display:'flex',flexDirection:'column'}}>
        <NarrowContainer >
            {blogs.map((blog,i)=>
                    <Grid item xs={12} md={6} lg={4} p={2} key={i} className='center-align'>
                        <BlogCard card={blog}/>
                    </Grid>)}
        </NarrowContainer>
        </div>
    );
}

export default BlogsArticle;