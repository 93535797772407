import React from 'react';
import ArticleCardA from 'components/mui/cards/articleCardA';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'

const Overview = () => {
    const article = {
        title:'Programmatic Advertising Technology',
        content:'PixFuture is the only company of its kind run by publishing veterans. We’ve walked in your shoes, we know the right questions, and we’re better prepared to help you meet your goals by using our technology for video & display ads.'
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='home-overview-container'>
            <div className='center-align'>
                <NarrowContainer className='overview-content'>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={6} md={8} className='overview-left'>
                            <div className='overview-pic'>
                                  <img loading='lazy' src='/images/home/overview-pic.png' alt='overview'/>
                            </div>
                        </Grid>
                    </Grow>
                        <Slide timeout={{enter:1200}} in={inView} direction="left" container={containerRef.current}>
                        <Grid item xs={12} sm={6} md={4} className='center-align'>
                            <ArticleCardA title={article.title} content={article.content} link='about-us'/>
                        </Grid>
                        </Slide>
                    <div className='observer' ref={inViewRef}></div>
                </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default Overview;