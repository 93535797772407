import React,{forwardRef, useImperativeHandle} from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';

const TwitterButton = forwardRef(({url,quote},ref)=> {
    const mainurl='https://twitter.com/intent/tweet?'
    const urlString=mainurl+'text='+encodeURIComponent(quote)+'&url='+ encodeURIComponent(url)
    const windowHeight=400
    const windowWidth=550
    const topPosition=window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - windowHeight / 2
    const leftPosition=window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - windowWidth / 2
    useImperativeHandle(ref, () => ({
        openDialog(){
            const style = `top=${topPosition},left=${leftPosition},width=${windowWidth},height=${windowHeight}`
            window.open(urlString,'',style)
        }
      }));
    return (
        <div className='center-align'>
            <TwitterIcon/>
        </div>
    );
});

export default TwitterButton;