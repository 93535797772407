import React,{useState} from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import FolderIcon from '@mui/icons-material/Folder';
import Link from '@mui/material/Link';


import 'assets/styles/docs.css'
const files = ["pixfuture-data-solution.zip","mod_pixfuturedatasolution.zip","app-ads.txt","ads.txt","VAST_publisher_macros.pdf","VAST_demand_settings.pdf","TCF-CCPA-Check.pdf","Roku-SDK-Direct-Integration.pdf","PixFuture-First-Party-Identity-Solution.pdf",
"PixFuture%20DFP%20Tag%20Integration%20March%202020.pdf","Mobile-pixfuture-macros.pdf","How%20to%20implement%20tags%20via%20Word%20Press.pdf","CTV-pixfuture-macros.pdf","Automated_AdsTXT.pdf","Ads.txt%20in%20WordPress.pdf","AMPGuide.pdf"];


const Docs =() => {
  const [dense, setDense] = useState(false);
    return (
      <div className='docs-container'>
      <List dense={dense}>
  
      {files.map(function(data, idx){
         return (<ListItem>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <Link href={"/docs/"+data}>
          <ListItemText
            primary={data}
            secondary={null
          }
          /></Link>
        </ListItem>)
       })}

      
    </List>
    </div>
    );
}

export default Docs;