import React from 'react';
import ContentRowA from 'components/mui/containers/contentRowA';
import ContentRowB from 'components/mui/containers/contentRowB';
import ContentRowC from 'components/mui/containers/contentRowC';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';
import TestimonyContainer from 'components/testimony/testimonyContainer';
import ContactInfo from 'components/contactInfo/contactInfo';
import { Grid } from '@mui/material';


const BuyersArticle=()=> {
    const row = [
        {
            title:'AuctionX Bidding & Campaign Management',
            article:'AuctionX can help buyers better understand the context of the content before they place a bid. ',
            picsrc:'images/buyers/buyer-feature1.png',
        },
        {
            title:'Target Potential Clients',
            article:'Our advanced targeting options allow advertisers to connect to their key audience at scale. We offer to target by category, device type, keywords, and much more.',
            picsrc:'images/buyers/buyer-feature2.png',
        },
        {
            title:'Cross Platform Audience',
            article:'Ads are posted in different formats and exposed to third-party audience segments through multiple devices.',
            picsrc:'images/buyers/buyer-feature3.png',
        },
        {
            title:'High ROI \n&\n CTR Rate',
            article:'The eye-catching content will enhance the exposure of ads and lead to a high ROI and CTR rate consequence.',
            picsrc:'images/buyers/buyer-feature4.png',
        },
        {
            title:'Display',
            article:'',
            list:['Worldwide Coverage with Maximum Monetization','Targeted Ads by GEO, Audience, Content, and etc..','Active Block List Management','Pre-approved Creatives','COPPA compliant'],
            picsrc:'images/buyers/buyer-ads1.png',
        },
        {   title:'Outstream Video',
            article:'',
            list:['Campaigns From The World’s Leading Brands','High Fill-Rates At Maximum CPMs','Delivery Across Every Device','Single-Step Integration','Robust Analytics'],
            picsrc:'images/buyers/buyer-ads2.png',
        },
        {   title:'Instream Video',
            article:'',
            list:['Monetization-First Approach','Optimized for Performance','Scalable Content Solutions'],
            picsrc:'images/buyers/buyer-ads3.png',
        },
        {
            title:'GoStory',
            article:'',
            list:['Premium Distribution','Engaging & Relevant','Brand Protection','High CTR rate','Any Device'],
            picsrc:'images/publishers/publisher-ads3.png',
        }  
    ]
    const containerStyle={
        display:'flex',
        flexDirection:'column',
        padding:'50px 0 0px 0',
        background:`url('/images/column-back-a.png') no-repeat top center`,
    }
    return (
        <div>
            <div style={{position:'relative'}}>
            <YellowDivider width='60%' height='15px' marginTop='15px' marginRight='0' className='right-align'/>
            <Grid pt={{lg:0,xs:5}}>
            <ContentRowA title={row[0].title} contents={row[0].article} picSrc={row[0].picsrc} long={false} button={false}/>
            </Grid>
            <BlueDivider width='10%' height='25px' marginBottom='20px' marginLeft='0' className='left-align'/>
            </div>
            <div style={{position:'relative'}}>
            <YellowDivider width='40%' height='15px' marginTop='40px' marginLeft='0' className='left-align'/>
            <Grid pt={{lg:0,xs:5}}>
            <ContentRowB title={row[1].title} contents={row[1].article} picSrc={row[1].picsrc} long={false} button={false}/>
            </Grid>
            </div>
            <div style={{position:'relative'}}>
            <YellowDivider width='20px' height='100px' marginTop='0' marginRight='15%'/>   
            <ContentRowC title={row[2].title} contents={row[2].article} picSrc={row[2].picsrc} long={false} button={false}/>
            </div>
            <div style={{position:'relative'}}>
            <YellowDivider width='40%' height='10px' marginTop='15px' marginRight='0' className='right-align'/>
            <BlueDivider width='60%' height='15px' marginTop='45px' marginRight='0' className='right-align'/>
            <Grid pt={{lg:0,xs:5}}>
            <ContentRowB title={row[3].title} contents={row[3].article} picSrc={row[3].picsrc} long={false} button={false}/>
            </Grid>
            </div>
            
            <WideContainer>
                <div className='center-align' style={containerStyle}>
                    <NarrowContainer>
                        <div style={{color:'black',width:'100%',textAlign:'center',padding:'50px 0'}}>
                            <h1>We Support Different Formats of Ads</h1>
                        </div>
                        <BasicRowB title={row[4].title} list={row[4].list} picSrc={row[4].picsrc} long={false} button={false}/>
                        <BasicRowA title={row[5].title} list={row[5].list} picSrc={row[5].picsrc} long={false} link='video'/>
                        <BasicRowB title={row[6].title} contents={row[6].article} list={row[6].list} picSrc={row[6].picsrc} long={false} link='instream-video'/>
                        <BasicRowA title={row[7].title} list={row[7].list} picSrc={row[7].picsrc} long={false} link='gostory-buyers'/>
                    </NarrowContainer>
                </div>
                {/* <div className='center-align buyer-container'>
                    <TestimonyContainer/>
                </div> */}
                <div className='center-align'>
                    <ContactInfo/>
                </div>
            </WideContainer> 
        </div>
    );
}

export default BuyersArticle;