import React from 'react';
import FooterData from 'data/footer/footerItem.json';
import FooterLinkItem  from './footerLinkItem';
import { WideContainer} from 'components/mui/containers/PxftContainer';
import FooterLogo from 'assets/images/footer-logo.png'
import { Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <WideContainer className="pxft-footer-container">
            <div className="center-align">
                <Grid container className='footer-content'>
                    <Grid item xs={12} sm={12} md={4} className="center-align">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                  <img loading='lazy' src={FooterLogo} alt=''/>
                            </div>
                            <div className="footer-widget-content">
                                <div className="content">
                                    <p>
                                    PixFuture is the only company of its kind run by publishing veterans. We’ve walked in your shoes, we know the right questions, and we’re better prepared to help you meet your goals by using our technology for video & display ads.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    {FooterData && FooterData.map((item, i) => {
                            return(
                                <Grid key={i} item xs={6} sm={6} md={2}>
                                    <FooterLinkItem data={item} key={i} />
                                </Grid>
                            ); 
                    })}
                    <Grid item xs={6} sm={6} md={2}>
                    <div className="footer-widget">
                        <h4 className="footer-widget-title">Community</h4>
                        <div className="footer-widget-content">
                            <ul>
                            <li><a href="https://twitter.com/pixfuture" target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="13" height="13" viewBox="0 0 50 50" style={{margin: '1px 3px 0 1px'}}>
                                <path fill="white" d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                                </svg>
                                X</a></li>
                            <li><a href="https://www.instagram.com/pixfuture/" target="_blank" rel="noopener noreferrer"><InstagramIcon fontSize='12px'/> Instagram</a></li>
                            <li><a href="https://www.facebook.com/pixfuture" target="_blank" rel="noopener noreferrer"><FacebookIcon fontSize='12px'/> Facebook</a></li>
                            <li><a href="https://www.linkedin.com/company/pixfuture/" target="_blank" rel="noopener noreferrer"><LinkedInIcon fontSize='12px'/> Linkedin</a></li>
                            </ul>
                        </div>
                    </div>
                    </Grid>
                </Grid>
            </div>
            <div className='copyright-container'>
                <p className="copyright">&copy; Developed by <strong>pixfuture</strong> (PixFuture Media Inc.)
                <br/>2008-{currentYear}. All Rights Reserved. </p>
            </div>
        </WideContainer>
    )
}

export default Footer;