import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer'


const Container = styled(NarrowContainer)`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 40px 20px 40px;

        & .title {
            color:black;
            text-align:center;
            padding:40px 0 40px 0;
        }

        & img{
            width:100%;
        }
`;


const BasicRowC = ({title='',contents='',picSrc='',btnText='Learn More'}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align'>
                    <Container>
                    <Slide timeout={{enter:800}} in={inView} direction="up" container={containerRef.current}>
                        <h1 className='title'>{title}</h1>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                          <img loading='lazy' src={'/' + picSrc} alt=''/>
                    </Grow>
                    </Container>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer>
    );
}

export default BasicRowC;