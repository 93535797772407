import React from "react";
import 'assets/styles/base/layout.css';
import { Grid } from '@mui/material';
import { NarrowContainer } from 'components/mui/containers/PxftContainer';
import InfoMessage from "components/base/infoMessage";

class Home extends React.Component {
  render() {
   console.log(this.props);

     const { status, message, type } = this.props;
   
    return (
      <>
        <div id="home-page" className="info-container">
        <NarrowContainer className="center-align">
          <Grid item xs={6}>
            <img loading='lazy' className='contentPic' src='/images/not-found.png' alt=''/>
          </Grid>
          <Grid item xs={6}>
          <h2>Info Page</h2>
         <InfoMessage status={status} message={message} type={type} />
          </Grid>
        </NarrowContainer >
        </div>
      </>
    );
  }
}
export default Home;
