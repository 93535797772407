import React from 'react';
import BasicHeader from 'components/mui/containers/basicHeader'

const CMPHeader = ()=> {
    const header = {
        title:'CCPA & GDPR Consent Solution',
        article:'PixFuture welcomes privacy laws that protect consumers. We’re building the feature to help publishers manage their compliance with the General Data Protection Regulation (GDPR) in the EEA and California Consumer Privacy Act (CCPA).\n\n– Instantly give access to users to manage privacy choices\n\n– IAB standards support for CCPA and GDPR\n\n– Absolutely FREE for all publishers',
        picsrc:'images/resources/cmp-header.png',
        iconsrc:'images/buyers/buyer-icon.png',
        link:'https://portal.pixfuture.com/registration'
    }
    return (
        <BasicHeader title={header.title} article={header.article} button={true} buttonContent='Sign Up' link={header.link} divider={true} long={false} picsrc={header.picsrc} iconsrc={header.iconsrc}/>
    );
}

export default CMPHeader