import React,{ useState,useEffect } from 'react';
import DemoViews from './demoViews';
import { Grid,Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import GostoryMediaPlayer from './gostoryMediaPlayer';
import { useInView } from 'react-intersection-observer'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;

        & .title {
            display: flex;
            flex-direction: row;
            color:white;
            text-align:left;
            line-height:1.5;
            white-space: pre-line;
        }

        & .line {
            padding: 0 12px;
            border-left:2px solid var(--pxft-yellow);
        }

        & .content {
            color:white;
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
        }
`;

const GostorySocial=()=> {
    const card={
        title:'Streamline Your\nFacebook & Instagram',
        content:'GoStory supports content imported from social media sources like Facebook or Instagram and optimizes the exposition of your social stories.',
    }
    const [lineHeight, setLineHeight] = useState('130px');
    const getHeightofTitle =(element) =>{
        let h1height = element.getAttribute('height');
        setLineHeight(h1height);
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    useEffect(()=>{
        let element = document.getElementsByTagName("h1")[0];
        getHeightofTitle(element);
    },[])

    return (
        <Grid container className='gostory-social-container'>
            <div className='background'></div>
            <Grid item xs={12} md={7} className='gallary'>
                <DemoViews/>
                <GostoryMediaPlayer inview={inView}/>
            </Grid>

            <Grid item xs={12} md={5} className='content'>
                <ArticleBox sx={{py:6,px:3,width:'350px'}}>
                <Box className='title'>
                    <div className='line' style={{'height':lineHeight}}></div>
                    <h1>{card.title}</h1>
                </Box>
                <p className='content'>{card.content}</p>
                <div className='social-media'>
                    <FacebookIcon fontSize='large'/><InstagramIcon fontSize='large'/>
                </div>
                </ArticleBox>
            </Grid>
            <div className='observer' ref={inViewRef}></div>
        </Grid>
    );
}

export default GostorySocial;