import React, { useEffect} from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import GostoryHeader from 'components/gostory/gostoryHeader';
import GostoryArticle from 'components/gostory/gostoryArticle';

const Gostory=()=>{
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    
    return (
        <>
        <WideContainer>
            <GostoryHeader/>
            <GostoryArticle/>
        </WideContainer>
        </>

);
}

export default Gostory;