import React from 'react';
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const NextBtn = styled.button`
        background-color: var(--pxft-mildgrey);
        border-radius: 25px;
        border: 0;
        color: var(--pxft-darkgrey);
        font-size: var(--pxft-font-normal);

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms ease;
        cursor: pointer;

        &:hover {
            background-color: var(--pxft-lightgrey);
            border: 2px solid var(--pxft-mildgrey);
        }
        & .active {
            backgroundColor: var(--pxft-mildgrey);
        }
        & .focusVisible {
            boxShadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
            outline: none;
        }
        & .disabled {
            opacity: 0.5;
            cursor: not-allowed;
 `;
const NextButton =({width='50px', height='50px'})=> {
    return (
        <NextBtn style={{'height':height,'width':width}}>
            <ArrowForwardIosIcon />
        </NextBtn>
    );
}

export default NextButton;