import React, { useState,useEffect}  from 'react';
import BlogsArticle from 'components/company/blogs/blogsArticle';
import BlogsHeader from 'components/company/blogs/header';
// import BlogsData from 'data/blogs/blogItem.json';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import 'assets/styles/company.css'

import axios from 'axios.js';

const chips = [
    {title:'All Categories',
    value:'all'},
    {title:'Anouncement',
    value:'announcements'},
    {title:'Product Update',
    value:'product updates'},
    {title:'Performance',
    value:'performance'},
    {title:'Event',
    value:'event'},
    {title:'News',
    value:'news'},
]




const Blogs = () => {
    const[blogs,setBlogs]=useState([])
    const blogsPerPage = 6
    const[pageCount,setPageCount] = useState(0)
    const[currpage,setCurrpage]=useState(1)
    const[blogOffset,setBlogOffset] = useState(0)

    const[isDataLoaded,setDataLoaded]=useState(false)


    const getBlogs = async () => {

        await axios.get("/api/content/blogs/all")
          .then(
            (res) => {
              setBlogs(res.data.blogs || { blogs: [] })
              setDataLoaded(true)
            }
          )
          .catch((error) => {
            setBlogs({ blogs: [] })
            setDataLoaded(true)
          });
      }
    
    
      useEffect(() => {
        getBlogs();
      }, []);

    const handlePageClick = (event,page) => {
        let pathname = window.location.pathname
        let url = pathname.split('/')
        let chipsValue=['anouncements','product updates','performance','event','news']
        if(url[1]==='blogs' && chipsValue.includes(url[2])){
            if(url[2]!=='page')
            window.location.href = `${window.location.origin}/blogs/${url[2]}/${page}`;
            else
            window.location.href = `${window.location.origin}/blogs/page/${page}`;
        }else
        window.location.href = `${window.location.origin}/blogs/page/${page}`;
    };
    useEffect(()=>{
        if(blogs.length == 0) {
            return;
        }

        window.scrollTo(0, 0)
        let pathname = window.location.pathname
        let url = pathname.split('/')
        let chipsValue=['all','anouncements','product updates','performance','event','news']
        const setPage = (blogs,currentPage)=>{

            setBlogOffset((currentPage-1) * blogsPerPage);
      

            let blogOff = (currentPage-1) * blogsPerPage;
        
            const endOffset = blogOff + blogsPerPage;
            setBlogs(blogs.slice(blogOff,endOffset));
            setPageCount(Math.ceil(blogs.length/blogsPerPage));
        }

        if(url[1]==='blogs' && url[2]==='page'){
            setCurrpage(url[3])
            setPage(blogs,url[3])
        }else if(url[1]==='blogs' && chipsValue.includes(decodeURI(url[2]))){
            let category = decodeURI(url[2])
            if(category.toLowerCase()==='all'){
                setPage(blogs,1)
            }else{
                let selectedBlogs = blogs.filter(blog=>{ 
                    return blog.categories.toLowerCase().includes(category.toLowerCase());
                })


                if(url[3])setCurrpage(url[3])
                setPage(selectedBlogs,1)
            }
        }
        else{
            const endOffset = blogOffset + blogsPerPage;
            setBlogs(blogs.slice(blogOffset,endOffset));
            setPageCount(Math.ceil(blogs.length/blogsPerPage));
        }
    },[isDataLoaded,setBlogs]);


    return (
        <div>
            <BlogsHeader chips={chips}/>
            {blogs.length == 0 ? <p> </p> :<BlogsArticle blogs={blogs}/> }
            <Stack sx={{py:6}} spacing={2} className='center-align'> <Pagination page={parseInt(currpage)} onChange={handlePageClick} count={pageCount} variant="outlined" shape="rounded" /> </Stack>
            
        </div>
    );
}

export default Blogs;