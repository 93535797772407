import React from 'react';
import 'assets/styles/ads.css'
import {NarrowContainer, WideContainer} from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import HeaderCard from 'components/mui/cards/headerCard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import VideoFeatures from './features';

const Ctv = () => {
    const header = {
        title:'CTV/OTT Monetization',
        article:'PixFuture helps to monetize the next generation of the content consumed using smart TV technology. Our mission is to help all types of developers to be successful in what they are doing by monetizing their valuable content with the AuctionX platform.',
        src:'https://cdn.pixfuture.com/corporate/videos/ctv-video.mp4',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='video-mobile-container'>
            <div className='center-align'>
                <NarrowContainer>
                <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{px:6}}>
                        <Link underline="hover" color="inherit" href="/video">
                        Video
                        </Link>
                        <p>Connected TV</p>
                        </Breadcrumbs>
                </Slide>
                </NarrowContainer>
            </div>
            <div className='center-align'>
                <NarrowContainer className='header-content'>
                    <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={6} md={5.5} lg={4.5} className='center-align'>
                            <HeaderCard title={header.title} content={header.article} iconsrc={header.iconsrc} long={false} button={false}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align'>
                        <div className='center-align'>
                            <video style={{width:'90%'}} preload="none" webkit-playsinline="true" muted playsInline autoPlay loop src={header.src}/>
                        </div>
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </NarrowContainer>
            </div>
            <div className='center-align'>
                <VideoFeatures/>
            </div>
        </WideContainer>
    );
}

export default Ctv;