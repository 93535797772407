import React, { useEffect}  from 'react';
import BrandbookArticle from 'components/brandbook/brandbookArticle';
import BrandbookHeader from 'components/brandbook/header';

const Brandbook=()=> {    
    useEffect(()=>{
        window.scrollTo(0, 0)
        })
    return (
        <div>
            <BrandbookHeader/>
            <BrandbookArticle/>
        </div>
    );
}

export default Brandbook;