import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Box,Chip,Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import icon from 'assets/images/icons/buyer-icon.png'

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;
        max-width:400px;
        padding: 130px 0 0 0;
        align-items:center;
        justify-content:center;

        & .title-container{
            display:flex;
            align-items:center;
            justify-content:center;
            padding:20px;
        }

        & .title {
            display: flex;
            flex-direction: column;
            color:black;
            text-align:left;
            padding:0 10px;
            white-space: pre-line;
        }

        & .line {
            padding: 6px 0 0 0;
            border-top:2px solid var(--pxft-blue);
        }

        & .content {
            padding:10px 0;
            color:black;
            width:80%;
            line-height:1.3;
            text-align:center;
            white-space: pre-line;
        }
`;

const Header =({chips})=> {
    const header = {
        title:'Blog',
        article:'Find all our latest news, insights, and events',
        iconsrc:'buyer-icon.png',
    }
    const chipClick =(value)=>{
        window.location.href = `${window.location.origin}/blogs/${value}`;
    }
    return (
        <WideContainer>
            <div className='center-align'>
                <ArticleBox className='headerBox'>
                    <div style={{display:'flex',padding:'30px'}}>
                        <div style={{width:'40px'}}>
                              <img style={{width:'100%'}} src={icon} alt=''/>
                        </div>
                        <h1 className='title'>{header.title}</h1>
                    </div>
                    <div className='line' style={{width:'100%'}}></div>
                    <h4 className='content'>{header.article}</h4>
                    <Grid container className='center-align' sx={{py:3}}>
                        {chips.map((chip,i)=> 
                        <Grid item key={i}>
                            <Chip sx={{my:1, mx:0.5}} label={chip.title} onClick={()=>chipClick(chip.value)} variant="outlined"/>
                        </Grid>
                        )}
                    </Grid>
                </ArticleBox>
            </div>
        </WideContainer>
    );
}

export default Header;