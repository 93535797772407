import React from 'react';

const GostoryMediaPlayer=(inview)=> {
    let srchtml=`<div></div><script src='/cw/socialPlayer-widget.js' pixId='14x38' init='pxft-main'}></script>`
    return (
        <div>
            {
                inview &&
                <iframe loading='lazy' srcDoc={srchtml} title="social media gostory" className='social-player-iframe'>
                </iframe>
            }
        </div>
    );
}

export default GostoryMediaPlayer;