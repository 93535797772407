import React from 'react';
import {WideContainer} from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import HeaderCard from 'components/mui/cards/headerCard';

const Header=()=> {
    const header = {
        title:'Our story',
        article:'In 2008, PixFuture was founded with a mission to help publishers and advertisers maximize their profit and become more successful in a crowded online marketplace.  \n\nPixFuture quickly became a leading digital media technology company that offers innovative advertising and traffic monetization solutions through both Video and Display ad formats.',
        picsrc:'images/company/aboutus/aboutus-1.png',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
            <WideContainer className='aboutus-header-container'>
                    <div className='center-align'>
                        <Grid container className='header-content'>
                            <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                                <Grid item xs={12} sm={6} md={5} lg={5} className='center-align'>
                                    <HeaderCard title={header.title} content={header.article} iconsrc={header.iconsrc} long={false} button={false}/>
                                </Grid>
                            </Slide>
                            <Grow timeout={{enter:1200}} in={inView}>
                                <Grid item xs={12} sm={6} md={7} lg={7} className='center-align'>
                                <div className='center-align'>
                                      <img loading='lazy' src={'/' + header.picsrc} alt=''/>
                                </div>
                                </Grid>
                            </Grow>
                            <div className='observer' ref={inViewRef}></div>
                        </Grid>
                    </div>
            </WideContainer>
    );
}

export default Header;