import React from 'react';
import CenterTitle from 'components/mui/containers/centerTitle';

const Header=()=> {
    const header = {
        title:'Brand Assets',
        article:'Thanks for your interest in pixfuture. We have a few guidelines for using our brand resources. Please take a moment to familiarize yourself with them.',
    }
    const containerStyle={
        display:'flex',
        flexDirection:'column',
        padding:'100px 0',
        background:`url('/images/column-back-a.png') no-repeat top center`,
    }
    return (
        <div style={containerStyle}>
            <CenterTitle title={header.title} article={header.article}/>
        </div>
    );
}

export default Header;