import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import ArticleCardA from '../cards/articleCardA';

const ContentRowA = ({title='',contents='',picSrc='',button=true,long=true,video=false,src='',btnText='Learn More'}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        background:`url('/images/column-back-a.png') no-repeat top center`,
        overflow:'hidden'
    }
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align content-column' style={containerStyle}>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            <ArticleCardA width='300px' height='500px' title={title} content={contents} button={button} long={long} btnText={btnText}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                            {video?
                            <video preload='none' autoPlay webkit-playsinline="true" className='contentVideo' muted playsInline loop src={src}/>
                            :  <img loading='lazy' className='contentPic' src={'/' + picSrc} alt=''/>
                            }
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default ContentRowA;