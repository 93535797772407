import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import reportWebVitals from 'reportWebVitals';
import Layout from 'views/base/Layout.js';
import Home from 'views/home/Home.js';
import Docs from 'views/docs/Docs.js';
import Publishers from 'views/publishers/Publishers';
import Buyers from 'views/buyers/Buyers.js'
import Video from 'views/video/Video';
import Gostory from 'views/gostory/Gostory';
import AboutUs from 'views/company/AboutUs';
import ContactUs from 'views/company/ContactUs';
import Blogs from 'views/company/Blogs';
import Info from 'views/base/Info.js';
import { baseTheme } from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import BlogDetails from 'views/company/BlogDetails';
import MobileVideo from 'views/ads/mobileVideo';
import DesktopVideo from 'views/ads/desktopVideo';
import Ctv from 'views/ads/ctv';
import TermsAndConditions from 'views/resources/TermsAndConditions';
import Support from 'views/resources/Support';
import Brandbook from 'views/brandbook/brandbook';
import CMP from 'views/resources/CMP';
import GostoryAds from 'views/gostoryAds/Gostory';
import InstreamVideo from 'views/instreamVideo/InstreamVideo';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <ThemeProvider theme={baseTheme}>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/publishers" element={<Publishers />} />
            <Route path="/mobile-video" element={<MobileVideo />} />
            <Route path="/desktop-video" element={<DesktopVideo />} />
            <Route path="/ctv" element={<Ctv />} />
            <Route path="/video" element={<Video />} />
            <Route path="/instream-video" element={<InstreamVideo/>} />
            <Route path="/buyers" element={<Buyers />} />
            <Route path="/advertisers" element={<Buyers />} />
            <Route path="/gostory" element={<Gostory />} />
            <Route path="/gostory-buyers" element={<GostoryAds/>} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/support" element={<Support />} />
            <Route path="/consent-management-solution" element={<CMP/>} />
            <Route path="/brandbook" element={<Brandbook />} />
            <Route path="/blog" element={ <Navigate to="/blogs" /> } />
            <Route path="/2021/04/27/how-ads-viewability-improves-revenue/" element={ <Navigate to="/blogs/blog/how-ads-viewbility-improves-revenue" /> } />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route path="/blogs/blog/:blogUrl" element={<BlogDetails />} />
            <Route path="/blogs/:categories" element={<Blogs />} />
            <Route path="/blogs/:categories/:pageId" element={<Blogs />} />
            <Route path="*" element={<Info status={404} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



