import React from 'react';
import {BlueDivider } from 'components/mui/divider/divider';
import { NarrowContainer, WideContainer} from 'components/mui/containers/PxftContainer';
import IconCardB from 'components/mui/cards/iconCardB';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useInView } from 'react-intersection-observer'

const OurPlatform=()=> {
    const iconcardList = [
        {
            id:1,
            icon:'demand',
            title:'Monetize',
            contents:'Find perfect monetization solutions by using our proprietary technology.',
            iconWidth:'50',
            iconHeight:'50',
            fill:'#0077D3',
        },
        {
            id:2,
            icon:'revenue',
            title:'Advertise',
            contents:'Promote your product or service by doing programmatic deals with our premium publishers.',
            iconWidth:'50',
            iconHeight:'50',
            fill:'#0077D3',
        }
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    const containerRef = React.useRef(null);

    return (
        <WideContainer className='center-align home-our-platform-container' width='100%'>
            <BlueDivider width='100%' height='15px' marginTop='50%' marginRight='0'/>
            <div className='center-align'>
            <NarrowContainer vertical className='home-our-platform-content'>
                <Slide timeout={{enter:1500}} in={inView} direction="up" container={containerRef.current}>
                    <div>
                    <h1>Our Platform</h1>
                    <h1>All your advertising needs</h1>
                    <Grid container pt={6} className='center-align'>
                            {
                                iconcardList.map(listItem =>(
                                    <IconCardB key={listItem.id} IconCard={listItem}/>
                                ))
                            }
                    </Grid>
                    </div>
                </Slide>
            </NarrowContainer>
            </div>
            <div className='observer' ref={inViewRef}></div>
        </WideContainer>
    );
}

export default OurPlatform;