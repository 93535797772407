import React from 'react';
import {Helmet} from "react-helmet"


function MetaTags({tag={}}) {
    return (
        <div>
            <Helmet>
                <meta property='og:title' content={tag.webTitle}/>
                <meta property="og:site_name" content={tag.webSiteName}/>
                <meta property="og:type" content={tag.type} />
                <meta property='og:image' content={tag.webImage}/>
                <meta property='og:description' content={tag.webDescription}/>
                <meta property='og:url' content={tag.webUrl}/>
                <meta property='og:locale' content={tag.locale}/>
                <title>{tag.webTitle}</title>
            </Helmet>
        </div>
    );
}

export default MetaTags;