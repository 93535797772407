import React from 'react';

const GostoryMediaPlayer=(inview)=> {
    let srchtml=`<div></div><script src='/cw/buyerPlayer-demo2.js' pixId='5351x799' init='pxft-main'}></script>`
    return (
        <div className='buyers-player-container'>
            {
                inview &&
                <iframe loading='lazy' srcDoc={srchtml} title="buyer media gostory" className='buyers-player-iframe'>
                </iframe>
            }
        </div>
    );
}

export default GostoryMediaPlayer;