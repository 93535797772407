import React from 'react';
import {WideContainer} from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import HeaderCard from 'components/mui/cards/headerCard';
import { useInView } from 'react-intersection-observer'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const GostoryHeader = () => {
    const header = {
        title:'GoStory',
        article:'GoStory player allows publishers to rotate their content in the most popular Story format on the website.',
        src:'',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);

    return (
            <WideContainer className='gostory-header-container'>
                    <div className='center-align'>
                        <Grid container className='header-content'>
                            <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                                <Grid item xs={12} sm={6} md={5.5} lg={4.5} className='center-align'>
                                    <HeaderCard title={header.title} content={header.article} iconsrc={header.iconsrc} long={false} button={false}/>
                                </Grid>
                            </Slide>
                                <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align'>
                                {isMobile ? <iframe loading='lazy'
                                    src="https://www.youtube.com/embed/BPoGmFKmKFs"
                                    frameBorder="0"
                                    width="90%"
                                    height='140%'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="video"
                                />:<iframe loading='lazy'
                                src="https://www.youtube.com/embed/BPoGmFKmKFs"
                                frameBorder="0"
                                width="920"
                                height="510"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="video"
                            />
                                }
                                </Grid>
                            <div className='observer' ref={inViewRef}></div>
                        </Grid>
                    </div>
            </WideContainer>
    );
}

export default GostoryHeader;