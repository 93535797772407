import React, { Component } from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import PublisherHeader from 'components/publishers/header';
import PublisherArticle from 'components/publishers/publisherArticle';

class Publishers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return (
            <WideContainer>
                <PublisherHeader/>
                <PublisherArticle/>
            </WideContainer>
        );
    }
}

export default Publishers;