import React from 'react';
import ArticleCardA from 'components/mui/cards/articleCardA';
import ArticleCardB from 'components/mui/cards/articleCardB';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'

const BasicRowA = ({title='',contents='',picSrc='',width='300px',height='500px',long=true,button=true,btnText='Learn More',link='',list=[]}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align' style={{overflow:'hidden'}}>
                    <NarrowContainer className='mobile-reverse'>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                              <img loading='lazy' className='contentPic' src={'/' + picSrc} alt=''/>
                        </Grid>
                    </Grow>
                    <Slide timeout={{enter:800}} in={inView} direction="left" container={containerRef.current}>
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            {
                                list.length>0?
                                <ArticleCardB width={width} height={height} title={title} list={list} button={button} long={long} btnText={btnText} link={link}/>
                                :<ArticleCardA width={width} height={height} title={title} content={contents} button={button} long={long} btnText={btnText} link={link}/>
                            }
                        </Grid>
                    </Slide>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default BasicRowA;