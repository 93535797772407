import React from 'react';

function GostoryTerms() {
    const openTab=(tab)=>{
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" tab-active", "");
        }
        var tabContent=tab+"-content";
        document.getElementById(tabContent).style.display = "block";
        document.getElementById(tab).classList.add("tab-active");
    }
    return (
        <div id="gostory-terms-content" className="tabcontent">
        <div className="updated-time">Last Revised: April 27, 2022</div>
        <h3 className="policy-title">GoStory Terms</h3>
        <h3>1. General</h3>
        <p>
            a. These Channels Terms of Use (the “<strong>Channels Terms</strong>“)
            apply to your (“<strong>you</strong>“, “<strong>your</strong>“, “ <strong>Publisher</strong>“): (i) use of our Channels service (as defined
            below); (ii) any documentation related thereto Channels Platform<strong> </strong>(collectively (i)-(ii) the “<strong>Channels</strong> <strong>Platform</strong>“), which are owned or operated by Pixfuture Media
            Inc. (“<strong>us</strong>“, “<strong>we</strong>“, “<strong>our</strong>”
            or “<strong>Pixfuture</strong>“).<strong></strong>
        </p>
        <p>
            b. Please read these Channels Terms carefully; if you access or use the
            Channels Platform you agree to be bound by these Channels Terms and by our <a href='/terms-and-conditions#privacy-policy' rel="noreferrer" onClick={() =>openTab('privacy-policy')}> <u>Privacy Policy</u> </a>
            , which is incorporated herein by reference.<strong></strong>
        </p>
        <p>
            c. Please note that if you create and/or embed content in connection with
            your use of the Channels Platform – Pixfuture’s <a href='/terms-and-conditions' rel="noreferrer" onClick={() =>openTab('platform-terms')}>
                <u>Terms of Use</u>
            </a> shall apply.<strong></strong>
        </p>
        <h3>2. Definitions</h3>
    <p>
        a. <strong>“Advertisements”</strong> shall mean any advertising materials
        (including content recommendations and/or any form of sponsored materials),
        in whatever format (including graphics, links, audio, display ads, video
        ads, etc.), as made available or delivered through the Channels Platform
        and/or Channels.<strong></strong>
    </p>
    <p>
        b. <strong>“Asset(s)” </strong>shall mean the domain(s) or sites of the
        Publisher as described in a separate and signed Insertion Order or
        Agreement.<strong></strong>
    </p>
    <p>
        c. “<strong>Assets Content</strong>” shall mean all materials and content
        on the Assets, including but not limited to text, programming code,
        graphics, animations, themes, logos, brands, photographs, images, video,
        audio, in whatever format.<strong></strong>
    </p>
    <p>
        d. <strong>“Channels”</strong> shall mean the GoStory video player,
        including the Pixfuture analytics/impact platform, and any extensions or
        derivatives or any part thereof (including any APIs, pixels, tags, cookies,
        iFrames, scripts or any other software components), including all related
        features and products as made available therein, as well as all materials
        and content, including text, programming code, graphics, animations,
        themes, logos, brands, photographs, images, video, audio, in whatever
        format, as provided by Pixfuture to the Publisher for the purpose of
        placing such content on Publisher’s Assets during the term of the
        agreement.<strong></strong>
    </p>
    <p>
        e. <strong>“Confidential Information” </strong>shall mean any non-public,
        proprietary, confidential and/or trade secret information of a party
        hereof, whether furnished before or after the date the Channels Terms
        entered into effect, and regardless of the manner in which it is furnished,
        and which given the totality of the circumstances, a reasonable person or
        entity should have reason to believe is proprietary, confidential, or
        competitively sensitive, including, without limitation, research and
        development, formulas, programming, know-how, proprietary knowledge,
        technology and any related documentation, engineering, production,
        operation and any enhancements or modifications relating thereto, and other
        designs, drawings, engineering notebooks, industrial models, software and
        specifications, financial and marketing information, business plans,
        business procedures, clients’ list, business partners or other information
        disclosed by one of the parties hereto (the<strong>“Disclosing Party”</strong>) to the other party (the <strong>“Receiving Party”</strong>) either directly or indirectly in
        writing, orally or by drawings. Confidential Information shall not,
        however, include any information which: (i) was known to the Receiving
        Party or in its possession at the time of disclosure without any
        confidentiality obligation; (ii) becomes publicly known and made generally
        available after disclosure by the Disclosing Party to the Receiving Party
        through no action or inaction of the Receiving Party; (iii) is
        independently developed by the Receiving Party without reliance on or use
        of the Confidential Information or any part thereof and the Receiving Party
        can show written proof of such independent development; (iv) is required to
        be disclosed by applicable law, regulatory authority or a valid court
        order, provided that the Receiving Party shall provide the Disclosing Party
        with reasonable prior written notice of the required disclosure in order
        for the Disclosing Party to obtain a protective order and the disclosure
        shall be limited to the extent expressly required; (v) is approved for
        release by prior written authorization of the Disclosing Party; or (vi) the
        Receiving Party can demonstrate was disclosed by the Disclosing Party to a
        third party without any obligations of confidentiality.<strong></strong>
    </p>
    <p>
        f. <strong>“Data” </strong>shall mean all data transmitted to Pixfuture
        through the Tag (as hereinafter defined), including metadata from the
        Channels Platform, and any personal and non-personal information pertaining
        to Users collected through their interaction with the Channels Platform,
        Channels, the Advertisements and/or with the Asset.<strong></strong>
    </p>
    <p>
        g. <strong>“Pixfuture Demand” </strong>means Advertisements provided by
        Pixfuture, whether owned by Pixfuture or provided to Pixfuture on behalf of
        third parties, which will be served by Pixfuture through the embedment of
        an Pixfuture Tag.<strong></strong>
    </p>
    <p>
        h. <strong>“Fraudulent Activity” </strong>shall mean (a) the display,
        promotion, distribution or interaction with the Channels Platform and/or
        the Advertisements (as hereinafter defined) in any manner which engages
        with anything other than natural persons viewing actually displayed
        Advertisements and/or other materials made available via the Channels
        Platform in the normal course of using any device, including, without
        limitation, browsing through online, mobile or any other technology or
        platform, as determined by Pixfuture in its sole discretion which may lead
        to falsely generated or artificially-inflated revenues; and/or (b) the
        automatic redirection of visitors, blind text links, misleading links,
        forced clicks, etc. from the Channels Platform and/or the Advertisements.
        Without limiting the foregoing, Fraudulent Activity shall include any of
        the following practices:
        <span>
            i. Inclusion or counting of views or clicks by:
        </span>
        <span>
            a. A natural person who has been engaged for the purpose of viewing the
            Channels and/or the Advertisements, whether exclusively or in conjunction
            with any other activities of that person (including, without limitation,
            employing any means to induce, encourage, incentivize or trick the end-user
            into viewing or clicking on content made available via the Channels
            Platform and/or Advertisements);
        </span>
        <span>
            b. Non-human visitors (such as bots); and
        </span>
        <span>
            ii. Inclusion or counting of views that are not actually visible to the
            human eye, discernible to human senses or perceived by a human being;
        </span>
        <span>
            iii. Masking or cloaking the Asset’s URL, or employ any means to obscure
            the true source of traffic, or conceal conversions;
        </span>
        <span>
            iv. Generating automated, fraudulent or otherwise invalid impressions,
            inquiries, views, clicks or conversions, or artificially inflating
            impressions, inquiries, views, clicks, or conversions, or employing any
            misleading, deceptive or inappropriate practices (such as repeated manual
            clicks); and/or
        </span>
        <span>
            v. Installing or transmitting Malicious Code.
        </span>
        <span>
            i. <strong>“Insertion Order” </strong>shall mean a binding written
            agreement (including an online registration page or online order form)
            entered into or accepted by Publisher (and/or Pixfuture, as applicable)
            that incorporates these Channels Terms, or is otherwise related to the use
            of the Channels Platform.
        </span>
        <span>
            j. <strong>“Malicious Code”</strong> shall mean viruses, worms, malware,
            spyware, time bombs, Trojan horses and other harmful or malicious code,
            files, scripts, agents or programs, including code that is intended to or
            has the effect of misappropriating, hijacking, commandeering, or disrupting
            access to or use or operation of any information, device, hardware, system
            or network.
        </span>
        <span>
            k. <strong>“Objectionable Content”</strong> shall mean content that
            promotes or contains links to content that is (i) pornographic, sexually
            explicit or obscene, (ii) harassing, threatening, abusive, inflammatory or
            racist, (iii) illegal, contrary to public policy or that could facilitate
            the violation of any applicable law, regulation or government policy, (iv)
            libelous or defamatory, (v) infringes upon the intellectual property rights
            of any third party, including copyrights, trademarks, trade names, trade
            secrets or patents of such third party; (vi) violates the privacy,
            publicity, moral or any other right of any third party; (vii) offers or
            disseminates any fraudulent goods, services, schemes, investment
            opportunities, promotions or advice not permitted by law; (viii) promotes
            illegal gambling, the use of illegal substances, or the consumption of
            alcoholic beverages or tobacco-related products; (ix) directed or primarily
            appeals to children under the age of 16; (x) harmful to Pixfuture’s or any
            other party’s systems and networks, including Malicious Code, adware,
            spyware or drive-by download applications; (xi) violates any obligation of
            confidentiality; or (xii) unacceptable in Pixfuture’s sole discretion.
        </span>
        <span>
            l. <strong>“Proprietary Rights” </strong>shall mean all intellectual
            property rights, including, without limitation: (a) all inventions, whether
            patentable or not, all improvements thereto and derivatives thereof, and
            all patents and patent applications; (b) all registered and unregistered:
            marks, trademarks, service marks, trade names, trade dress and associated
            logos, domain names and registrations and applications for registration
            thereof; (c) all copyrights in copyrightable works, all other rights of
            authorship, including without limitation moral rights, and all applications
            and registrations in connection therewith; (d) all trade secrets and
            Confidential Information; (e) all rights in databases and data compilations
            (including, with respect to Pixfuture, the Data), whether or not
            copyrightable; and (f) all copies and tangible embodiments of any or all of
            the foregoing (in whatever form, including electronic media).
        </span>
        <span>
            m. <strong>“Tag” </strong>shall mean certain code in the Channels and/or
            the Advertisements and/or the Assets (including cookies, script codes,
            pixels, URL links, tags or any other implementation or technological means)
            that permits PIXFUTURE and/or its partners or service providers to collect
            Data in accordance with Pixfuture’s
            <a href={ '/terms-and-conditions#privacy-policy'} rel="noreferrer" onClick={() =>openTab('privacy-policy')}> <u>Privacy Policy </u> </a>
            and these Channels Terms
        </span>
    </p>
    <h3>3. License and Restrictions</h3>
    <p>
        a. Subject to these Channels Terms, we grant you a limited, non-exclusive,
        non-transferable, and revocable license to access and use our Channels
        Platform as provided herein.
    </p>
    <p>
        b. Subject to these Channels Terms, you grant Pixfuture a non-exclusive,
        royalty free, revocable right and license to place the Channels (and
        Advertisements) on the Assets, including but not limited to: the
        use/distribution of Assets Content; Publisher’s RSS Feed; and for any other
        future Pixfuture features or products made available to you (and you
        accept) for which a license would be naturally required. For the avoidance
        of doubt, all title and interest in the Assets Content and/or RSS Feed
        shall remain the property of Publisher/applicable owner.
    </p>
    <p>
        c. You may not, and may not encourage or assist others to:
        <span>
            i. Publish, distribute, duplicate, sell, re-sell, disclose, “frame”,
            market, license, sublicense, rent, lease, display, provide, transfer or
            make available the Channels Platform or make any other use of Channels
            Platform, any portion thereof or any content displayed therein, to any
            third party, except as explicitly permitted in these Channels Terms or with
            our prior written permission;
        </span>
        <span>
            ii. Modify, remove, edit, amend, truncate, obscure, bypass or reorder any
            aspect of the Channels Platform or the Advertisements, unless Pixfuture
            makes available the means for the foregoing through an explicit embedded
            feature or functionality offered by Pixfuture in the Channels Platform and
            to the extent enabled by such feature or functionality;
        </span>
        <span>
            iii. Download, extract, republish, retransmit, reproduce or make any other
            use of the Channels Platform as a stand- alone file;
        </span>
        <span>
            iv. Decompile, reverse engineer, disassemble, enhance, or otherwise make
            any attempt to discover the source code of, hack, or create derivative
            works from the Channels Platform or any portion thereof.
        </span>
        <span>
            v. Copy, crawl, index, cache or store any information derived by Pixfuture,
            or use any robot, spider, data gathering or extraction tool, or similar
            mechanisms, with respect to the Channels Platform and/or any underlying
            technology;
        </span>
        <span>
            vi. Edit, remove, obscure or alter any credits, watermarks, tradenames,
            trademarks, logos, service marks or copyright notices set on Channels
            Platform, Advertisements and/or Pixfuture Content, or remove or obscure the
            attribute/hyperlink to Pixfuture and the Website (“Powered by Pixfuture” or
            any other similar wording);
        </span>
        <span>
            vii. Use the Channels Platform and/or any underlying technology to
            introduce any routine, device or other undisclosed feature, including a
            so-called time bomb, virus, software lock, drop dead device, malicious
            logic, worm, Trojan horse, or trap door, that is designed to delete,
            disable, deactivate, interfere with or otherwise harm any software,
            program, data, device, system or service, or which is intended to provide
            unauthorized access or to produce unauthorized modifications.
        </span>
    </p>
    <p>
        d. We may edit, modify, disable access or remove any content from our
        Channels Platform, for any reason, at any time and without prior notice. We
        also reserve the right to remove or disable access to any content or
        material posted, uploaded, published or incorporated in or to the Channels
        Platform, including by any users that are repeated infringers of our
        policies.
    </p>
    <p>
        e. If you choose to submit comments, ideas or feedback, you agree that we
        are free to use them without any restriction or compensation to you.
    </p>
    <h3>4. Publisher Representations and Warranties</h3>
    <p>
        a. Publisher represents and warrants that:
        <span>
            i. It has the full right to grant the rights granted hereunder;
        </span>
        <span>
            ii. It owns and operates the Asset, or is otherwise properly licensed to
            embed the Channels Platform, Advertisements (if applicable) and/or the Tag
            on the Asset;
        </span>
        <span>
            iii. It will not, directly or indirectly, violate these Channels Terms, or
            infringe upon the rights of any third party, including proprietary, privacy
            or publicity rights;
        </span>
        <span>
            iv. It is solely responsible to adopt any necessary measures to limit or
            restrict access to the Channels Platform, as required by applicable law
            and/or industry best practices, including with respect to the protection of
            minors or children;
        </span>
        <span>
            v. It will not embed or promote the Channels Platform in association with
            any material or content (including any content displayed, made available on
            or linked from the Asset) which is, or which may be reasonably considered
            by Pixfuture (at its sole discretion) as Objectionable Content; and any
            information Publisher provides Pixfuture (including contact information,
            payment information or any reports that were or ought to be provided by
            Publisher to Pixfuture) will at all times be complete and accurate, and
            will be maintained up-to-date at all times.
        </span>
        <span>
            vi. It is solely responsible for any content created, provided or otherwise
            made available by Publisher in connection with Publisher’s use of the
            Channels Platform, including without limitation the Assets Content and/or
            RSS Feed.
        </span>
    </p>
    <p>
        b. Publisher further agrees that it will not:
        <span>
            i. Remove, alter, disable or circumvent any Tag or otherwise interfere with
            Pixfuture’s ability to monitor user interaction in accordance with these
            Channels Terms;
        </span>
        <span>
            ii. Engage in any Fraudulent Activity;
        </span>
        <span>
            iii. Engage in any action or practice that disparages or devalues
            Pixfuture, the Channels Platform, the Advertisements, or the reliability,
            reputation or goodwill of any of the foregoing, or engage in any action or
            practice that might impede the performance reliability or quality of the
            Channels Platform; and
        </span>
        <span>
            iv. Place and/or embed Channels Platform on Assets that utilize “zombie
            cookies” and/or “super-cookies”, and/or otherwise circumvent the end user’s
            privacy collection preferences.
        </span>
    </p>
    <p>
        c. Without derogating from Publisher’s representations and warranties under
        these Channels Terms, Publisher agrees that Pixfuture or anyone delegated
        on its behalf shall have the right, but not the obligation, to monitor
        Publisher’s compliance with these Channels Terms, in any form or technology
        chosen by Pixfuture according to its sole discretion. Publisher shall have
        no claim or demand against Pixfuture with respect to such monitoring.
    </p>
    <p>
        d. Pixfuture is not responsible for any Objectionable Content or
        information made available on or linked from any Asset, Assets Content,
        Channels, Channels Platform, Pixfuture Demand and/or Advertisements.
        Publisher confirms that it is aware that some of the content made available
        through the Channels Platform is user generated content (“UGC”) (including,
        but not limited to, Giphy content) and that the sole responsibility for UGC
        lies with its various respective creators and/or uploaders. Pixfuture does
        not warrant and cannot guarantee that the UGC will not be infringing in any
        way. It is hereby clarified that in case of infringement or other content
        issues that may arise with the UGC, Publisher’s sole remedy with respect to
        such content shall be to submit a written complaint to Pixfuture, which
        will review such complaint and, if applicable, remove such UGC in
        accordance with its Terms of Use. Pixfuture does not monitor, pre-screen or
        approve UGC (including any translations) made available via the Channels
        Platform, and Pixfuture cannot guarantee its quality, accuracy or fitness
        for any purpose.
    </p>
    <h3>5. Data Protection</h3>
    <p>
        a. Publisher grants pixfuture a non-exclusive, royalty-free right and
        license to place the Tag on the Channels Platform, Channels and the
        Advertisements embedded on the Assets and to collect, use and disclose any
        Data obtained through the Tag, in accordance with the pixfuture <a href={ '/terms-and-conditions#dpa-terms'} rel="noreferrer" onClick={() =>openTab('dpa-terms')}>
            <u>Data Protection Addendum</u> </a>
        (as applicable), which is incorporated herein by reference.
    </p>
    <p>
        b. Publisher shall provide its end users with a notice and obtain their
        consent, as required by applicable laws and regulations in connection with
        the collection, use and disclosure of Data through the use or access to the
        Channels Platform and the Advertisements, and storing and accessing of
        cookies or other information on the end users’ devices, including with
        respect to the use of any technological methods for the purpose of
        collecting such Data (including, without limitation, the Tag and any
        cookies, web beacons, etc.), and including with respect to displaying
        interest-based or targeted content.
    </p>
    <p>
        c. Without limiting from the foregoing, Publisher shall ensure that end
        users are provided with a notice that third parties may collect, use and
        share Data in accordance with their respective privacy policy through an
        adequate privacy policy, which will be presented to end users. Such privacy
        policy shall address the Publisher’s practices with respect to the
        collection, use and disclosure of any user’s Data obtained from the end
        user (including through the Tag) and shall be easily accessible by the end
        users prior to any collection, use or disclosure of Data.
    </p>
    <p>
        d. Pixfuture may enable its partners (including its Publishers) to use a
        data management platform that will enable them to manage their audience
        information and Data, pursuant to the terms of an Insertion Order or any
        other form of agreement entered between Publisher and Pixfuture. You
        acknowledge that you are solely responsible and assume all liability in
        connection with your use of any audience information and Data (whether
        through the data management platform or otherwise), including with respect
        to compliance with any applicable data protection laws or regulations.
    </p>
    <p>
        e. Without limiting from the foregoing, Publisher shall collect, use or
        disclose Data in accordance with any applicable laws and with its privacy
        policy, and shall allow Pixfuture to provide any link or notice it deems
        necessary for the provision of any disclosure in or around the Channels
        Platform or Advertisements.
    </p>
    <h3>6. Advertisements</h3>
    <p>
        a. Publisher agrees that the Channels are ad-supported and therefore
        Pixfuture may (but is not obligated to) deliver or include any
        Advertisements, within or adjacent to the Channels on the Asset(s), for any
        promotional or marketing purposes.
    </p>
    <p>
        b. Publisher further agrees not to include any advertising, promotional or
        marketing materials of any kind within the Channels Platform, unless
        explicitly agreed upon by Pixfuture by entering into a separate and valid
        Insertion Order or any other form of written agreement.
    </p>
    <h3>7. Reports</h3>
    <p>
        a. In the event that Pixfuture provides reports to the Publisher, under an
        Insertion Order or any other form of agreement entered between Publisher
        and Pixfuture (“<strong>Reports</strong>“), such Reports shall be solely
        based on Pixfuture’s tracking systems and will be considered as final and
        non-appealable proof for the purpose of computing the fees due and payable
        to any party pursuant to the terms of the Insertion Order or applicable
        agreement. Publisher acknowledges that no other measurements or statistics
        of any kind will be accepted by Pixfuture or have any effect on the fee due
        under that Insertion Order or applicable agreement.
    </p>
    <p>
        b. Notwithstanding the foregoing, Publisher acknowledges that any
        information included in Pixfuture Analytics and in the Reports is subject
        to Publisher’s traffic quality, performance and compliance with the terms
        and conditions of these Channels Terms and are thereby subject to
        adjustments or modifications by Pixfuture at all times. The information
        included in Pixfuture Analytics and the Reports is not guaranteed to be
        accurate and is subject to adjustments resulting from, among other things,
        a final accounting, deductions, traffic performance or bad referrals, all
        as determined by Pixfuture’s sole and absolute discretion. In no event and
        for no reason shall Pixfuture be liable for any unavailability or
        inaccuracy, temporary or otherwise, of PIXFUTURE Analytics and/or of the
        Reports.
    </p>
    <h3>8. Proprietary Rights</h3>
    <p>
        a.
        <strong>
            All right, title and interest in and to the Channels Platform,
            including compilations, digital conversions, magnetic translations,
            software and other materials related to the Channels Platform are and
            shall at all times remain the sole and exclusive property of Pixfuture,
            and are protected by applicable intellectual property laws and
            treaties.
        </strong>
    </p>
    <p>
        b.
        <strong>
            All materials and contents displayed, made available, included,
            incorporated, uploaded, posted, published, contributed, performed or
            used on the Channels Platform, which were created by Pixfuture
            (together “Pixfuture Content”), are:
        </strong>
        <span>
            i. The sole property of Pixfuture and/or third parties, as applicable
            (including, without limitation, with respect to content from Getty Images
            as further detailed below); and
        </span>
        <span>
            ii. Protected by applicable intellectual property rights, including
            copyright laws.
        </span>
    </p>
    <p>
        c.
        <strong>
            All data related to the Channels Platform or collected through the
            Channels Platform, if any (including through the use of tags, pixels,
            cookies, scripts or any other software components) shall be the sole
            property of Pixfuture. All trademarks, service marks, trade names,
            logos, and any other proprietary designations of Pixfuture used herein,
            are trademarks or registered trademarks of Pixfuture. Any other
            trademarks, service marks, trade names, logos and any other proprietary
            designations are the trademarks or registered trademarks of their
            respective parties. In addition, the arrangement and compilation of the
            Website and the applications (including, without limitation, the “look
            &amp; feel”) are the exclusive property of Pixfuture and are protected
            by copyright laws.
        </strong>
    </p>
    <p>
        d.
        <strong>
            All rights not expressly granted to you in these Channels Terms are
            reserved by Pixfuture.
        </strong>
    </p>
    <p>
        e.
        <strong>
            The Channels Platform contains user content and Pixfuture Content. Such
            content is protected by copyrights, trademarks, service marks, patents,
            trade secrets or other proprietary rights and laws. You shall abide by
            and maintain all copyright notices, information, and restrictions
            contained in any content accessed through the Channels Platform.
        </strong>
    </p>
    <h3>9. Reporting Abuse</h3>
    <p>
        If you feel that any person or content is abusing our policies, or
        otherwise violating your rights, please contact us at:
        <a href="https://support.pixfuture.com/tickets/index.php?a=add">
            <u>Support</u>
        </a>
        . Be sure to identify the specific content and the reason you feel it
        should be removed.
    </p>
    <h3>10. Copyright Policy</h3>
    <p>
        a. In the appropriate circumstances and under our sole discretion, we may
        disable or terminate the Pixfuture accounts of users who repeatedly
        infringe or are repeatedly charged with infringing the copyrights or other
        intellectual property rights of others.
    </p>
    <p>
        In accordance with the Digital Millennium Copyright Act of 1998, we will
        respond expeditiously to claims of copyright infringement committed using
        the Channels Platform that are reported to Pixfuture’s Designated Copyright
        Agent, identified in the sample notice below.
    </p>
    <p>
        b. If you are a copyright owner, or are authorized to act on behalf of one,
        and you believe in good faith that any material or content made available
        on or through the Channels Platform has been used or exploited in a manner
        that infringes or violates any work or other intellectual property you own
        or control, please report the alleged copyright infringements taking place
        on or through the Channels Platform by completing the following DMCA Notice
        of Alleged Infringement (“Notice”) and delivering it to Pixfuture’s
        Designated Copyright Agent. Upon receipt of the Notice as described below,
        we will take whatever action, in our sole discretion, as we deem
        appropriate, including removal of the challenged material from the Channels
        Platform.
    </p>
    <p>
        c. You can submit a Notice by:
        <span>
            i. Filling in and submitting our online form; or
        </span>
        <span>
            ii. Providing the following information to our Designated Copyright Agent:
        </span>
        </p>
        <ul>
            <li>
                Identify the copyrighted work that you claim has been infringed, or – if
                multiple copyrighted works are covered by this Notice – you may provide a
                representative list of the copyrighted works that you claim have been
                infringed.
            </li>
            <li>
                Identify:
                <span>
                    I. the material that you claim is infringing (or to be the subject of
                    infringing activity) and that is to be removed or access to which is to be
                    disabled, and information reasonably sufficient to permit us to locate the
                    material, including at a minimum, if applicable, the URL of the link(s)
                    shown on the Channels Platform where such material may be found; and
                </span>
                <span>
                    II. the reference or link to the material or activity that you claim to be
                    infringing, that is to be removed or access to which is to be disabled, and
                    information reasonably sufficient to permit us to locate that reference or
                    link, including at a minimum, if applicable, the URL of the link(s) shown
                    on the Channels Platform where such reference or link may be found.
                </span>
            </li>
        </ul>
        <ul>
            <li>
                Provide your contact details – mailing address, telephone number, and, if
                available, email address.
            </li>
            <li>
                Include both of the following statements in the body of the Notice:
                <br />
                “I hereby state that I have a good faith belief that the disputed use of
                the copyrighted material or reference or link to such material is not
                authorized by the copyright owner, its agent, or the law (e.g., as a fair
                use).”
                <br />
                “I hereby state that the information in this Notice is accurate and, under
                penalty of perjury, that I am the owner, or authorized to act on behalf of
                the owner, of the copyright or of an exclusive right under the copyright
                that is allegedly infringed.”
            </li>
            <li>
                Provide your full legal name and your electronic or physical signature.
                <br />
                Deliver this Notice, with all items completed, to Pixfuture’s Designated
                Copyright Agent:
                <span>
                    Via client support portal:
                    <a href="https://support.pixfuture.com/tickets/index.php?a=add"> <u>Support</u> </a>
                </span>
                <span>
                    Attn: Designated Copyright Agent
                </span>
                <span>
                    7191 Yonge St, Suite 812,
                </span>
                <span>
                    Toronto, ON, L3T0C4
                </span>
            </li>
        </ul>
    <h3>11. Privacy Policy</h3>
    <p>
        To demonstrate our commitment to your privacy, we have developed our<a href={ '/terms-and-conditions#privacy-policy'} rel="noreferrer" onClick={() =>openTab('privacy-policy')}> <u>Privacy Policy</u> </a>
        which outlines our practices with respect to the collection, use and
        disclosure of information relating to your activities on the Channels
        Platform, Channels and the Advertisements. By using our Channels Platform,
        you acknowledge that you have reviewed our
        <a href={ '/terms-and-conditions#privacy-policy'} rel="noreferrer" onClick={() =>openTab('privacy-policy')}> <u>Privacy Policy</u> </a>
        and agree to be bound by its terms and conditions.
    </p>
    <h3>12. Links or Access to Third Party Content</h3>
    <p>
        It is hereby clarified that our Channels Platform and/or the Advertisements
        may contain links to third party websites, apps, services, offering,
        materials, products, advertisements, recommendations or other events or
        activities (“<strong>Third Party Content</strong>“) that are not owned or
        controlled by us. We do not control, endorse or assume any responsibility
        for any such Third Party Content. If you access any Third Party Content
        from our Channels Platform, you do so at your own risk and you agree that
        we will have no liability arising from your use of or access to any Third
        Party Content. Please be aware that such Third Party Content own terms of
        use and privacy policy will apply and govern your activities and any
        information you disclose while interacting with such Third Party Content.
    </p>
    <h3>13. Third Party Software</h3>
    <p>
        Portions of the Channels Platform may include software that we license from
        third parties, which may include open source software or related components
        (“<strong>Third Party Software</strong>“) and is subject to the terms
        imposed by third party licensors. We do not make any warranty with respect
        to Third Party Software. For further information about Third Party
        Software, please visit here. You agree that your use of the Channels
        Platform is subject to the applicable Third Party Software referred to
        therein, as updated from time to time.
    </p>
    <h3>14. Termination</h3>
    <p>
        a. Pixfuture may terminate or suspend the licenses provided herein at any
        time and cease providing you access to our Channels Platform, with or
        without cause or notice to you.
        <br />
        If you violate these Channels Terms or if you violate any terms,
        restrictions or limitations specified in any additional agreement(s) you
        may enter into with Pixfuture or that may apply to your specific use of the
        Channels Platform, or if you otherwise create risk or possible legal
        exposure for us, we can immediately stop providing you access to all or
        part of the Channels Platform and terminate the licenses provided herein
        (restricting your access to our Channels Platform) and/or the additional
        agreement(s), as applicable.
    </p>
    <p>
        b. Upon termination, you continue to be bound by sections: License and
        Restrictions, Proprietary Rights, Privacy Policy, Termination,
        Indemnification, Limitations on Liabilities, Warranty Disclaimer, Disputes
        and Miscellaneous.
    </p>
    <p>
        c. Termination of a Publisher’s access to and use of the Channels Platform
        shall not relieve Publisher of any obligations arising or accruing prior to
        such termination or limit any liability which Publisher otherwise may have
        to Pixfuture, including without limitation any indemnification obligations
        contained herein.
    </p>
    <h3>15. Indemnification</h3>
    <p>
        If anyone brings a claim against us related to your use of our Channels
        Platform, you will indemnify and hold us (including our and our
        subsidiaries’ shareholders, directors, officers, employees, partners and
        agents) harmless from and against all damages, losses, costs and expenses
        of any kind (including reasonable legal fees and costs) related to such
        claim.
    </p>
    <h3>16. Limitations on Liabilities</h3>
    <p>
        a. To the maximum extent permitted by law, Pixfuture (including its and its
        subsidiaries’ shareholders, directors, officers, employees, and agents)
        shall not be liable for any personal injury, indirect, incidental, special,
        exemplary, consequential or punitive damages, or any loss of profits or
        revenues (regardless of whether we were advised of the possibility of such
        damages), whether incurred directly or indirectly, or any loss of data,
        use, good-will, or other intangible losses.
    </p>
    <p>
        b. In no event shall Pixfuture’s (including its and its subsidiaries’
        shareholders, directors, officers, employees, and agents) aggregate
        liability for all claims relating to the use of the Channels Platform
        and/or applicable signed Insertion Order agreement exceed the fees paid, if
        any, to Publisher by Pixfuture in the six (6) month period preceding the
        events giving rise to the liability. The existence of one or more claims
        will not enlarge this limit. The parties acknowledge and agree that this
        section is an essential element of the agreement and that in its absence,
        the economic terms of this agreement would be substantially different. This
        section is severable and shall survive any termination or expiration of
        this agreement. Applicable laws may not allow the limitation or exclusion
        of liability or incidental or consequential damages, so the above
        limitation or exclusion may not apply to you. In such cases, our liability
        will be limited to the fullest extent permitted by applicable laws.
    </p>
    <h3>17. Warranty Disclaimer</h3>
    <p>
        a. The Channels Platform and all included content (including any Pixfuture
        Content, the Channels and/or the Advertisements) are provided on an “AS IS”
        and “AS AVAILABLE” basis without warranty of any kind. We specifically
        disclaim any and all warranties, express or implied, and conditions of
        merchantability, title, fitness for a particular purpose, and
        non-infringement. We do not guarantee that the Channels Platform (including
        the Channels and the Advertisements) and all included content will always
        be complete, accurate, safe, secure, bug-free or error-free, or that the
        Channels Platform and all included content will always function without
        disruptions, delays or imperfections. We may change, suspend or discontinue
        the Channels Platform (or any part thereof, including the Pixfuture
        Content) at any time, including the availability of any feature or
        database, without notice or liability. In addition, we may impose limits on
        certain features and services or restrict Publisher’s access to the
        Channels Platform (or any part thereof) without notice or liability.
    </p>
    <p>
        b. We do not control or direct Publisher’s actions on our Channels Platform
        and are not responsible for the content or information Publisher transmits
        or publishes on or through our Channels Platform.
    </p>
    <p>
        c. To the fullest extent permitted by law, we disclaim any responsibility
        or liability for the accuracy, reliability, availability, completeness,
        operability of the Channels Platform or to any content included in the
        Channels Platform.
    </p>
    <p>
        d. You expressly acknowledge and agree that the use of the Channels
        Platform is at your sole risk and that the entire risk as to satisfactory
        quality, performance, accuracy and efforts is solely with you.
    </p>
    <h3>18. Disputes</h3>
    <p>
        <strong>Governing law</strong>
    </p>
    <p>
        a. These Channels Terms and your use of the Channels Platform shall be
        governed by the laws of the Province of Ontario, without respect to its
        conflict of laws principles. You and Pixfuture agree to submit to the
        personal jurisdiction of competent courts located in Ontario Province,
        Toronto, for any actions not subject to the Arbitration section below.
    </p>
    <p>
        <strong>Arbitration &amp; className action waiver </strong>
    </p>
    <p>
        b. For any dispute, claim or controversy arising out of or in relation to
        these Channels Terms or to the breach, termination, enforcement,
        interpretation or validity thereof, or to your access or use of the
        Channels Platform (together “<strong>Dispute</strong>“), you agree to first
        contact us and attempt to resolve the Dispute with us informally. If
        Pixfuture has not been able to resolve the Dispute with you informally, you
        and we agree to resolve any Dispute by binding individual arbitration. Any
        provision of applicable laws notwithstanding, the arbitrator will not have
        authority to award damages, remedies or awards that conflict with these
        Channel Terms. The arbitration shall be conducted in Ontario Province,
        Toronto. If your claim does not exceed $10,000, then the arbitration will
        be conducted solely on the basis of documents you and Pixfuture submit to
        the arbitrator, unless the arbitrator determines that a hearing is
        necessary.
    </p>
    <p>
        c. Nothing in this section shall prevent either party from seeking
        injunctive or other equitable relief from the courts to prevent the actual
        or threatened infringement, misappropriation or violation of a party’s
        copyrights, trademarks, trade secrets, patents, or other intellectual
        property rights and for matters related to data security or unauthorized
        access to the Channels Platform. All claims must be brought in the parties’
        individual capacity, and not as a plaintiff or className member in any
        purported className or representative proceeding, and, unless we agree
        otherwise, the arbitrator may not consolidate more than one person’s
        claims.
    </p>
    <p>
        d. You agree that, by entering into these Channels Terms, you and Pixfuture
        are each waiving the right to a trial by jury or to participate as a className
        representative or className member on any className claim you may have against us
        including any right to className arbitration or any consolidation of individual
        arbitrations. You also agree not to participate in claims brought in a
        private attorney general or representative capacity, or consolidated claims
        involving another person, if we are a party to the proceeding.
    </p>
    <p>
        <strong> </strong>
    </p>
    <h3>19. Modifications</h3>
    <p>
        We may revise these Channels Terms from time to time, in our sole
        discretion and the most current version will always be posted on our
        website (as reflected in the “Last Revised” heading). We encourage you to
        review these Channels Terms regularly for any changes. By continuing to
        access or use the Channels Platform after any revisions become effective,
        you agree to be bound by the revised Channels Terms.
    </p>
    <h3>20. Miscellaneous</h3>
    <p>
        <strong>Email Notifications</strong>
    </p>
    <p>
        a. We reserve the right to determine the form and means of providing
        notifications to you, and you agree to receive legal notices electronically
        if we so choose.
    </p>
    <p>
        b. If you provided Pixfuture with your contact information, Pixfuture may
        send you marketing communications, from time to time, relating to our
        business or the businesses of carefully-selected third parties which
        Pixfuture think may be of interest to you, pursuant to our <a href={ '/terms-and-conditions#privacy-policy'} rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
            <u>Privacy Policy</u></a>
        . By providing us your contact details (whether through an online
        registration form, an Insertion Order or in any other way) you specifically
        agree to this. You may unsubscribe at any time from such marketing
        communication(s) by contacting us through <a href="https://support.pixfuture.com/tickets/index.php?a=add">
            <u>Support</u>
        </a>
        .
    </p>
    <p>
        <strong>Third Party Beneficiaries</strong>
    </p>
    <p>
        c. Pixfuture’s subcontractors and third-party service providers are
        intended third-party beneficiaries of these Channel Terms and upon your
        acceptance of these Channel Terms, Pixfuture’s subcontractors and
        third-party service providers will have the right to enforce these Channels
        Terms as related to the licenses granted herein against you as third-party
        beneficiaries thereof.
    </p>
    <p>
        <strong>Severability and Entire Agreement</strong>
    </p>
    <p>
        d. These Channels Terms, <a href={ '/terms-and-conditions#privacy-policy'} rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
            <u>Privacy Policy</u>
        </a>
        , Data Protection Addendum (if applicable), and any amendments thereto and
        any additional agreements you may enter into with Pixfuture or that may
        apply to your specific use of the Channels Platform, constitute the entire
        agreement between you and Pixfuture with respect to the use of the Channels
        Platform, and supersede all prior or contemporaneous communications and
        proposals, whether oral, written or electronic, between you and Pixfuture.
        If any provision of these Channels Terms is found to be unenforceable or
        invalid, that provision will be limited or eliminated to the minimum extent
        necessary so that these Channels Terms will otherwise remain in full force
        and effect and enforceable. The failure of either party to exercise in any
        respect any right provided for herein shall not be deemed a waiver of any
        further rights hereunder.
    </p>
    <p>
        <strong>Force Majeure</strong>
    </p>
    <p>
        e. Pixfuture shall not be liable for any failure to perform its obligations
        hereunder where such failure results from any cause beyond our reasonable
        control, including, without limitation, mechanical, electronic or
        communications failure or degradation.
    </p>

    <p>
        <strong>Assignment</strong>
    </p>
    <p>
        f. These Channels Terms are personal to you, and are not assignable,
        transferable or sub-licensable by you except with our prior written
        consent. We may assign, transfer or delegate any of our rights and
        obligations hereunder without consent.
    </p>
    <p>
        <strong>Relationship</strong>
    </p>
    <p>
        g. No agency, partnership, joint venture, or employment relationship is
        created as a result of these Channel Terms and neither party has any
        authority of any kind to bind the other in any respect.
    </p>
    <p>
        <strong>No Waiver</strong>
    </p>
    <p>
        h. Our failure to enforce any part of these Channels Terms shall not
        constitute a waiver of our right to later enforce that or any other part of
        these Channels Terms. Waiver of compliance in any particular instance does
        not mean that we will waive compliance in the future. In order for any
        waiver of compliance to be binding, we must provide you with written notice
        of such waiver through one of our authorized representatives.
    </p>
    <p>
        <strong>Export Control</strong>
    </p>
    <p>
        i. You agree to comply with the export laws and regulations of the United
        States and any other country with jurisdiction over the Channels Platform,
        and that you shall not export or re-export, directly or indirectly, the
        Channels Platform or any technical information disclosed hereunder to any
        destination prohibited or restricted by applicable export control
        regulations.
    </p>
    <p>
        <strong>Headings</strong>
    </p>
    <p>
        j. The section and paragraph headings in these Channels Terms are for
        convenience only and shall not affect their interpretation.
    </p>
    <p>
        <strong>Contact Us</strong>
    </p>
    <p>
        k. We value hearing from our users and welcome your feedback. You may
        contact us anytime at the following Client support portal: <a href="https://support.pixfuture.com/tickets/index.php?a=add">
            <u>Support</u>.
        </a>
    </p>
    <p>
        <strong>Publicity</strong>
    </p>
    <p>
        l. Publisher agrees that Pixfuture may use the Publisher’s name and logo
        for advertising and publicity purposes and may refer to the existence of
        their relationship on the Website and in press releases, advertising, or
        marketing materials distributed to prospective partners, and for that
        purpose Publisher hereby grants Pixfuture an irrevocable, perpetual,
        worldwide license to use Publisher’s name, logo, label or any other
        trademarks or symbols associated with the Publisher
        <br />
    </p>
    <p>
        <strong>Getty Content</strong>
    </p>
    <p>
        m. As applicable, Publisher agrees that its use of Getty Content (as
        specified in the <a href={ '/terms-and-conditions'} rel="noreferrer">
            <u>Pixfuture Terms</u>
        </a>
        ) when embedding Channels Platform on the Asset shall be performed solely
        in a manner consistent with the rights and restrictions in Getty Images
        Premium Access Standard Terms and Conditions, as available <a href={ '/terms-and-conditions'} rel="noreferrer"><u>here</u></a>
        (as may be amended from time to time) (in addition to the <a href={ '/terms-and-conditions'} rel="noreferrer">
            <u>Pixfuture Terms</u> </a>
        and the terms provided herein), and Publisher specifically represents that
        Publisher may not embed Channels Platform which incorporate Getty Content
        on Assets that are designed to induce or involving the sale, license or
        other distribution of “on demand” products (e.g., products in which the
        Getty Content is selected by a third party for customization of such
        product on a made-to-order basis), including, without limitation,
        postcards, mugs, t-shirts, calendars, posters, electronic greeting cards,
        wallpaper or ringtones for mobile telephones and other items.
    </p>
    <p>
        <strong> </strong>
    </p>
    <p>
        <strong>Social Media</strong>
        <strong>
            <br />
        </strong>
        <strong>YouTube</strong>
    </p>
    <p>
        1. Publisher acknowledges and agrees that embedding Channels Platform which
        include videos uploaded from or hosted on YouTube (through YouTube API)
        shall be performed solely in a manner consistent with YouTube’s <a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer"><u>Terms of Service</u></a>,
        YouTube’s <a href="https://www.youtube.com/yt/policyandsafety/communityguidelines.html" target="_blank" rel="noreferrer">
            <u>Community Guidelines</u>
        </a>
        , YouTube’s <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer">
            <u>Privacy Policy</u> </a>
        and YouTube’s <a href="https://developers.google.com/youtube/terms?hl=en" target="_blank" rel="noreferrer">
            <u>API Terms</u>
        </a>
        .
        <br />
    </p>
    <p>
        <strong>Instagram/Meta/Facebook</strong>
    </p>
    <p>
        2. Publisher acknowledges and agrees that embedding Channels Platform which
        include videos/pictures/posts uploaded from or hosted on
        Instagram/Meta/Facebook (through Meta API) shall be performed solely in a
        manner consistent with Instagram/Meta/Facebook’s <a href="https://www.facebook.com/legal/terms" target="_blank" rel="noreferrer"><u>Terms of Service</u></a>.
    </p>
    </div>
    );
}

export default GostoryTerms;