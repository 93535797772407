import { React,useState,useEffect} from 'react';
import axios from 'axios.js';
import { Grid,TextField,Button, Snackbar, Alert,Backdrop,CircularProgress} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const initialFormValues = {
    email_type: 'sales',
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    message: "",
  };

const initialAlertValues ={
        alertOpen: false,
        alertType: "success",
        alertMessage: ""
    }
const CssTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        backgroundColor:'white',
        color:'var(--pxft-midgrey)',
    },
})
const ContactFormContent =()=> {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [alertValues, setAlertValues ] = useState(initialAlertValues);
    const [errors, setErrors] = useState({});
    const [onchangeItem,setOnChangeItem]=useState({});
    const [loading, setLoading] = useState(false)
    const [loadingTimeOut,setLoadingTimeOut] = useState(false)

    useEffect(()=>{
        const validate = (fieldValues = formValues) => {
            let temp = {...errors}
            if ('firstname' in fieldValues){
                if(formValues.firstname.length===0){
                    temp.firstname=''
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.firstname)){
                    temp.firstname="The first name must be only letters.";
                }else 
                temp.firstname = (formValues.firstname.length<=250)? "" : "The first name must be less than 250 characters";
            }
            if ('lastname' in fieldValues){
                if(formValues.lastname.length===0){
                    temp.lastname=''
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.lastname)){
                    temp.lastname="The last name must be only letters.";
                }else 
                temp.lastname = (formValues.lastname.length<=250)? "" : "The last name must be less than 250 characters";;
            }
            if ('email' in fieldValues){
                if(formValues.email.length===0){
                    temp.email=''
                }else if(!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)){
                    temp.email="Email is not valid.";
                }else 
                temp.email = (formValues.email.length > 5 && formValues.email.length <= 250)? '':"The email must be more than 5 and less than 250 characters";
            }
            if ('phone' in fieldValues){
                if(formValues.phone.length===0){
                    temp.phone=''
                }else if(!/^\(?(([+][0-9])?[0-9]?[0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(fieldValues.phone)){
                    temp.phone="The phone number is not valid.";
                }else
                temp.phone = (formValues.phone.length<=250)? "" : "The number must be less than 250 degits"
            }
            if ('company' in fieldValues){
                if(formValues.company.length===0){
                    temp.company=''
                }else
                temp.company = (formValues.company.length<=250)? "" : "The company must be less than 250 characters"
            }
            if ('website' in fieldValues){
                if(formValues.website.length===0){
                    temp.website=''
                }else if(!/^https?:\/\//.test(fieldValues.website)){
                    temp.website="Address should begin with http:// or https://.";
                }else 
                temp.website = (formValues.website.length > 5 && formValues.website.length <= 250)? '':"The website must be more than 5 and less than 250 characters";
            }
            if ('message' in fieldValues){
                if(formValues.message.length===0){
                    temp.message=""
                }else
                temp.message = (formValues.message.length > 5 && formValues.message.length <= 250) ? "" : "The message must be more than 5 and less than 250 characters";
            }
            setErrors({
                ...temp
            });
        }
        validate(onchangeItem)
    },[onchangeItem,formValues,errors])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertValues(initialAlertValues)
    };

    const formIsValid = (fieldValues = formValues) => {
        const isValid =
          fieldValues.firstname &&
          fieldValues.lastname &&
          fieldValues.email &&
          fieldValues.phone &&
          fieldValues.message &&
          Object.values(errors).every((x) => x === "");
        return isValid;
      };

    const sendEmailAsync = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('submit_contact_form');
            const updatedFormValue = {...formValues,"g-recaptcha-response":token}
            const status = await axios.post("/email/send",updatedFormValue)
            .then((res) => {
                return (res.data && res.data.status != null) ? res.data : {status: false}
            })
            .catch((error)=> {
                return {status: false}
            });
            return status;
        }else{
            setLoading(true)
        }
    }
    useEffect(()=>{
        if(executeRecaptcha){
            setLoading(false)
        }
    },[executeRecaptcha])

    useEffect(()=>{
        if(loading){
            setTimeout(() => {
                setLoadingTimeOut(true)
            }, 30000);
        }else{
            setLoadingTimeOut(false)
        }
    },[loading])

    useEffect(()=>{
        if(loadingTimeOut && loading){
            setAlertValues({
                alertOpen: true,
                alertType: "warning",
                alertMessage: "Request expired. Please refresh the page."
            })
        }
    },[loadingTimeOut,loading])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()){
            setLoading(true)
            let info = await sendEmailAsync();
            if(info){
                setLoading(false)
            setAlertValues({
                alertOpen: true,
                alertType: (info.status) ? "success" : "error",
                alertMessage: info.msg || "Something went wrong."
            })
            event.target.reset();
            setFormValues(initialFormValues)
            }
        }else{
            console.log('errors',errors)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOnChangeItem({ [name]: value })
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

    return (
            <form onSubmit={handleSubmit} style={{maxWidth:'900px'}}>
            <Grid container sx={{p:2}} alignItems="center" justify="center">
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField id="firstname-input"
                    name="firstname"
                    label="First Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.firstname}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["firstname"] && { error: true, helperText: errors["firstname"] })}/>
                </Grid>
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField  id="lastname-input"
                    name="lastname"
                    label="Last Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.lastname}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["lastname"] && { error: true, helperText: errors["lastname"] })}/>
                </Grid>
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField id="email-input"
                    name="email"
                    label="Email"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["email"] && { error: true, helperText: errors["email"] })}/>
                </Grid>
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField  id="phone-input"
                    name="phone"
                    label="Phone"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.phone}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["phone"] && { error: true, helperText: errors["phone"] })}/>
                </Grid>
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField  id="company-input"
                    name="company"
                    label="Company"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.company}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["company"] && { error: true, helperText: errors["company"] })}/>
                </Grid>
                <Grid item xs={12} md={6} className='center-align'>
                <CssTextField id="website-input"
                    name="website"
                    label="Website"
                    type="text"
                    placeholder='http://'
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.website}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["website"] && { error: true, helperText: errors["website"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <TextField id="message-input"
                    name="message"
                    label="Message"
                    type="text"
                    multiline rows={4}
                    sx={{ m: 1, width: '95%',}}
                    value={formValues.message}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["message"] && { error: true, helperText: errors["message"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                    <Button sx={{ m: 1, width: '95%' }} variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </Grid>
                <Snackbar open={alertValues.alertOpen} autoHideDuration={20000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertValues.alertType} sx={{ width: '100%' }}>
                    {alertValues.alertMessage}
                </Alert>
                </Snackbar>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: '99'}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </form>
    );
}

export default ContactFormContent;