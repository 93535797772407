import Article from 'components/instreamVideo/Article';
import Header from 'components/instreamVideo/Header';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import React,{useEffect} from 'react';

const InstreamVideo=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <WideContainer>
            <Header/>
            <Article/>
        </WideContainer>
    );
}

export default InstreamVideo;