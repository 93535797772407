import React,{useState,useEffect} from 'react';
import DemandPolicy from './demandPolicy';
import DpaTerms from './dpaTerms';
import GostoryTerms from './gostoryTerms';
import PlatformTerms from './platformTerms';
import PrivacyPolicy from './privacyPolicy';
import SupplyAgreement from './supplyAgreement';
import SupplyPolicy from './supplyPolicy';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Article = () => {
    const openTab=(tab)=>{
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" tab-active", "");
        }
        var tabContent=tab+"-content";
        document.getElementById(tabContent).style.display = "block";
        document.getElementById(tab).classList.add("tab-active");
    }
    const[tag,setTag]=useState('platform-terms');
    const selectChange = (event) => {
        setTag(event.target.value);
    }
    useEffect(()=>{
        let location = window.location.hash;
        let tabs = ['#platform-terms','#gostory-terms','#dpa-terms','#demand-policy','#supply-policy','#privacy-policy']
        if (tabs.includes(location)){
            let keyword = location.split('#');
            setTag(keyword[1])
        }
    },[])
    useEffect(()=>{
        document.getElementById(tag).click();
    })
    return (
        <div>
            <div className="tabcontent-container">
                <div className="tab">
                    <a href="#platform-terms" id='platform-terms' className="tablinks tab-active" onClick={() =>openTab('platform-terms')}>Platform Terms</a>
                    <a href="#supply-agreement" id='supply-agreement' className="tablinks" onClick={() =>openTab('supply-agreement')}>Supply Agreement</a>
                    <a href="#gostory-terms" id='gostory-terms' className="tablinks" onClick={() =>openTab('gostory-terms')}>GoStory Terms</a>
                    <a href="#dpa-terms" id='dpa-terms' className="tablinks" onClick={() =>openTab('dpa-terms')}>DPA Terms</a>
                    <a href="#demand-policy" id='demand-policy' className="tablinks" onClick={() =>openTab('demand-policy')}>Demand Policy</a>
                    <a href="#supply-policy" id='supply-policy' className="tablinks" onClick={() =>openTab( 'supply-policy')}>Supply Policy</a>
                    <a href="#privacy-policy" id='privacy-policy' className="tablinks" onClick={() =>openTab( 'privacy-policy')}>Privacy Policy</a>
                </div>
                <div className='tab-mobile'>
                <Select
                    labelId="tag-select-label"
                    id="tag-select"
                    value={tag}
                    label=""
                    onChange={selectChange}
                    fullWidth
                    >
                    <MenuItem value={'platform-terms'}>Platform Terms</MenuItem>
                    <MenuItem value={'supply-agreement'}>Supply Agreement</MenuItem>
                    <MenuItem value={'gostory-terms'}>GoStory Terms</MenuItem>
                    <MenuItem value={'dpa-terms'}>DPA Terms</MenuItem>
                    <MenuItem value={'demand-policy'}>Demand Policy</MenuItem>
                    <MenuItem value={'supply-policy'}>Supply Policy</MenuItem>
                    <MenuItem value={'privacy-policy'}>Privacy Policy</MenuItem>
                </Select>
                </div>
                <PlatformTerms/>
                <SupplyAgreement/>
                <GostoryTerms/>
                <DpaTerms/>
                <DemandPolicy/>
                <SupplyPolicy/>
                <PrivacyPolicy/>
            </div>
        </div>);
    }
export default Article;