import React,{ useEffect} from 'react';
import Mobile from 'components/video/screens/mobile';

const MobileVideo=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div>
            <Mobile/>
        </div>
    );
}

export default MobileVideo;