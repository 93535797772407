import React from 'react';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';
import { Divider, Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import GostoryPlayer from './gostoryPlayer';
import GostoryMediaPlayer from './gostoryMediaPlayer';



const GostoryFeatures=()=> {
    const features = [
        {
            title:'Native Stories.',
            article:'Stories builder will help you to <b>create stories</b> or automatically generate them from your blog‘s RSS feed.',
            src:'https://cdn.pixfuture.com/corporate/buyers/buyer-gostory/gostory-demo1.mp4',
        },
        {
            title:'Native Social.',
            article:'Connect your Instagram/Facebook to streamline your social posts and stories automatically to the GoStory widget.',
            src:'https://cdn.pixfuture.com/corporate/buyers/buyer-gostory/gostory-demo2.mp4',
        }
]
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const [inViewRef4, inView4] = useInView({
        triggerOnce: true,
    });
    const [inViewRef5, inView5] = useInView({
        triggerOnce: true,
    });
    const [inViewRef6, inView6] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        background:`url('/images/buyers/buyer-gostory/bg-4.png') no-repeat center`,
        backgroundSize: 'cover',
    }
    const containerStyle2={
        background:`url('/images/buyers/buyer-gostory/bg-5.png') no-repeat center`,
        backgroundSize: 'cover',
    }
    const containerStyle3={
        background:`url('/images/column-back-a.png') no-repeat center`,
    }
    return (
        <WideContainer>
            <div className='buyers-gostory-content'>
                <div>
                    <Grow timeout={{enter:1200}} in={inView1}>
                        <div className='center-align overview' style={containerStyle3}>
                            <div className='title'>
                                <h1>Share a Story About Brand or Product</h1>
                            </div>
                            <p>Native Stories format is <span>eye-catching</span> and <span>non-intrusive</span> creative to explain your brand or product.</p>
                            <p><span>Premium publisher</span> - create widgets to feature the top content of their websites.</p>
                            <p><span>Advertisers</span> - get highly engaging placement and non-intrusive similar-looking style of creative to feature content within content stories. It's a Win-Win for both.</p>
                            <div className='observer' ref={inViewRef1}></div>
                        </div>
                    </Grow>
                </div>
                <div className='center-align demos' style={containerStyle}>
                    <NarrowContainer>
                        <Grid container>
                            <Slide timeout={{enter:1200}} in={inView2} direction="right">
                            <Grid item xs={12} sm={4} md={4} className='demo-content'>
                                <h1>Native Look <br/> On Featured Content.</h1>
                                <p>Build your ad creative Stories in minutes or streamline your content automatically from Website(RSS), Instagram, and Facebook.</p>
                                {/* <div className='icons'>
                                    <img height='100%' loading='lazy' src={'/images/buyers/buyer-gostory/facebook-icon.png'} alt='' />
                                    <img height='100%' loading='lazy' src={'/images/buyers/buyer-gostory/instagram-icon.png'} alt='' />
                                </div> */}
                            </Grid>
                            </Slide>
                            <Grow timeout={{enter:1200}} in={inView2}>
                            <Grid item xs={12} sm={8} md={8} className='center-align'>
                                <div className='story-demos'>
                                    <div className='gostory-container'>
                                        <GostoryPlayer/>
                                    </div>
                                    <div className='gostory-media-container'>
                                        <GostoryMediaPlayer/>
                                    </div>
                                </div>
                            </Grid>
                            </Grow>
                        </Grid>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef2}></div>
                </div>
            <WideContainer>
                <div className='center-align gostory-content'>
                    <NarrowContainer className='mobile-reverse'>
                        <YellowDivider width='57%' height='20px' marginBottom='30px' marginLeft='0'/>
                        <Grow timeout={{enter:1200}} in={inView3}>
                            <Grid item xs={12} sm={6} md={6} sx={{py:7}} className='center-align'>
                                <video width='100%' preload='none' autoPlay webkit-playsinline="true" muted playsInline loop src={features[0].src}/>
                            </Grid>
                        </Grow>
                        <Slide timeout={{enter:1200}} in={inView3} direction="left">
                            <Grid item xs={12} sm={6} md={6} className='center-align' display='flex' flexDirection='column'>
                                <div style={{padding:'48px 24px',maxWidth:'350px'}}>
                                    <div className='gostory-title'>
                                        <div className='gostory-line' style={{height:'57px'}}></div>
                                        <h1>Native Stories.</h1>
                                    </div>
                                    <p className='gostory-txt'>Stories builder will help you to <b>create stories</b> or <b>automatically generate</b> them from your blog‘s RSS feed.</p>
                                </div>
                                <div className='devices'>
                                    <p>Available on Mobile, Desktop & Tablet</p>
                                    <img height='100%' loading='lazy' src={'/images/buyers/buyer-gostory/devices-icon.svg'} alt='' />
                                </div>
                            </Grid>
                        </Slide>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef3}></div>
                </div>
            </WideContainer>
            <WideContainer>
                <div className='center-align gostory-content' style={{background:'var(--pxft-lightgrey)'}}>
                <BlueDivider width='55%' height='20px' marginRight='0' marginBottom='30%'/>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView4} direction="right">
                        <Grid item xs={12} sm={6} md={6} className='center-align' display='flex' flexDirection='column'>
                            <div style={{padding:'48px 24px',maxWidth:'350px'}}>
                                <div className='gostory-title'>
                                    <div className='gostory-line' style={{height:'57px'}}></div>
                                        <h1>Native Social.</h1>
                                    </div>
                                <p className='gostory-txt'><b>Connect your Instagram/Facebook </b>to streamline your social posts and stories automatically to the GoStory widget.<br/>
                                <b>Grow your follower</b> base by showing your social content to millions of users.</p>
                            </div>
                            <div className='devices'>
                                <p>Available on Mobile, Desktop & Tablet</p>
                                <img height='100%' loading='lazy' src={'/images/buyers/buyer-gostory/devices-icon.svg'} alt='' />
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView4}>
                        <Grid item xs={12} sm={6} md={6} py={3} className='center-align'>
                             <video width='50%' preload='none' autoPlay webkit-playsinline="true" muted playsInline loop src={features[1].src}/>
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef4}></div>
                </div>
            </WideContainer>
            <div>
                <Grow timeout={{enter:1200}} in={inView5}>
                        <div className='center-align stats'>
                            <h2>Choose Your Audience and Reach</h2>
                            <NarrowContainer>
                                <Grid container className='center-align'>
                                    <Grid item xs={12} sm={3.5} md={3.5}>
                                        <h1>1800+</h1>
                                        <p>DIRECT PUBLISHERS</p>
                                    </Grid>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Grid item xs={12} sm={3.5} md={3.5}>
                                        <h1>5 <span>Billion</span></h1>
                                        <p>IMPRESSIONS/MTH</p>
                                    </Grid>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Grid item xs={12} sm={3.5} md={3.5}>
                                        <h1>230+ <span>Million</span></h1>
                                        <p>UNIQUE VISITORS/MTH</p>
                                    </Grid>
                                </Grid>
                            </NarrowContainer>
                            <div className='observer' ref={inViewRef5}></div>
                        </div>
                </Grow>
            </div>
            <div className='center-align' style={containerStyle2}>
                <NarrowContainer className='center-align'>
                    <Slide timeout={{enter:1200}} in={inView6} direction="up">
                        <div className='center-align  brands'>
                            <h1>We have access to the best Storytellers</h1>
                            <img loading='lazy' className='contentPic' src='/images/buyers/logos.png' alt=''/>
                        </div>
                    </Slide>
                    <div className='observer' ref={inViewRef6}></div>
                </NarrowContainer>
            </div>
        </div>
        </WideContainer>
    );
}

export default GostoryFeatures;