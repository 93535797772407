import React from 'react';

Icon.defaultProps = {
    width: "100",
    height: "100",
    fill: "#707070"
  }

function Icon({icon,fill,width,height}) {
    const demand = (
        <svg width={width} height={height} viewBox="0 0 100 100">
            <defs>
                <clipPath>
                <rect id="Rectangle_423" data-name="Rectangle 423" width="100" height="99.997" fill="#707070"/>
                </clipPath>
            </defs>
            <g id="Group_7262" data-name="Group 7262" clipPath="url(#clip-path)">
                <path fill={fill} d="M99.324,68.01,83.664,55.576a1.787,1.787,0,0,0-2.9,1.4v6.306H67.084c0-.05.015-.1.015-.151v-45.6a1.786,1.786,0,0,0-1.786-1.786H61L69.611,4.9l8.612,10.847H73.911a1.786,1.786,0,0,0-1.786,1.786V58.713a1.786,1.786,0,1,0,3.572,0v-22H87.888a6.129,6.129,0,1,0,0-3.572H75.7V19.32h6.225a1.786,1.786,0,0,0,1.4-2.9L71.01.919a1.786,1.786,0,0,0-2.8,0L55.9,16.424a1.786,1.786,0,0,0,1.4,2.9h6.225V33.176a1.767,1.767,0,0,0-.317-.032h-45.6a1.786,1.786,0,0,0-1.786,1.786v4.313L4.981,30.631l10.847-8.612v4.313a1.786,1.786,0,0,0,1.786,1.786H58.793a1.786,1.786,0,0,0,0-3.572H36.718V11.988a6.129,6.129,0,1,0-3.572,0V24.547H19.4V18.321a1.786,1.786,0,0,0-2.9-1.4L1,29.233a1.786,1.786,0,0,0,0,2.8L16.5,44.34a1.786,1.786,0,0,0,2.9-1.4V36.716H33.39V82.474a1.786,1.786,0,0,0,1.786,1.786h4.393l-8.735,11-8.735-11h4.393a1.786,1.786,0,0,0,1.786-1.786V40.716a1.786,1.786,0,0,0-3.572,0V63.281H11.989a6.13,6.13,0,1,0,0,3.572H24.707V80.688H18.4a1.786,1.786,0,0,0-1.4,2.9l12.433,15.66a1.786,1.786,0,0,0,2.8,0l12.433-15.66a1.786,1.786,0,0,0-1.4-2.9H36.962V66.853H82.553a1.786,1.786,0,0,0,1.786-1.786V60.673l11,8.735-11,8.735V73.75a1.786,1.786,0,0,0-1.786-1.786H40.8a1.786,1.786,0,1,0,0,3.572H63.282V88.009a6.127,6.127,0,1,0,3.572,0V75.536H80.768v6.306a1.786,1.786,0,0,0,2.9,1.4l15.66-12.433a1.786,1.786,0,0,0,0-2.8M93.751,32.374a2.556,2.556,0,1,1-2.558,2.558,2.558,2.558,0,0,1,2.558-2.558M32.374,6.126a2.556,2.556,0,1,1,2.558,2.558,2.558,2.558,0,0,1-2.558-2.558M6.13,67.623a2.556,2.556,0,1,1,2.553-2.559A2.561,2.561,0,0,1,6.13,67.623M36.962,36.716H63.209a1.809,1.809,0,0,0,.317-.032V63.13c0,.052.011.1.015.151H36.962ZM67.623,93.871a2.556,2.556,0,1,1-2.554-2.558,2.557,2.557,0,0,1,2.554,2.558" transform="translate(0 0)"/>
                <path fill={fill} d="M109.112,93.6H95.741a1.786,1.786,0,0,0-1.786,1.786v13.372a1.786,1.786,0,0,0,1.786,1.786h13.372a1.786,1.786,0,0,0,1.786-1.786V95.384a1.786,1.786,0,0,0-1.786-1.786m-1.786,13.372h-9.8v-9.8h9.8Z" transform="translate(-52.006 -51.809)"/>
            </g>
        </svg>

    )
    const revenue = (
        <svg width={width} height={height} viewBox="0 0 100 100">
            <defs>
                <clipPath>
                <rect id="Rectangle_426" data-name="Rectangle 426" width="100" height="100" fill="#707070"/>
                </clipPath>
            </defs>
            <g id="Group_7268" data-name="Group 7268" clipPath="url(#clip-path)">
                <path fill={fill}  d="M2.125,70.206a1.781,1.781,0,0,0,1.193-.457L48.352,29.292,60.4,39.694a1.785,1.785,0,0,0,2.425-.084L96.6,6.094v9.56a1.786,1.786,0,1,0,3.572,0V1.786A1.787,1.787,0,0,0,98.387,0H84.527a1.787,1.787,0,0,0,0,3.574h9.543L61.482,35.907,49.5,25.558a1.786,1.786,0,0,0-2.36.023L.931,67.089a1.787,1.787,0,0,0,1.194,3.117" transform="translate(-0.187 0.001)"/>
                <path fill={fill}  d="M98.214,133.312h-9.2V70.25h6.2a1.787,1.787,0,0,0,0-3.574H87.227a1.787,1.787,0,0,0-1.786,1.787v64.849H73.924V85.947h6.2a1.787,1.787,0,0,0,0-3.574H72.138a1.787,1.787,0,0,0-1.786,1.787v49.152H59.142V99.655h6.2a1.787,1.787,0,0,0,0-3.574H57.356a1.787,1.787,0,0,0-1.786,1.787v35.445H44.053V89.683h6.2a1.787,1.787,0,0,0,0-3.574H42.267A1.787,1.787,0,0,0,40.48,87.9v45.416H29.27V102.893h6.2a1.787,1.787,0,0,0,0-3.574H27.484a1.787,1.787,0,0,0-1.786,1.787v32.207H14.181V115.624h6.2a1.787,1.787,0,0,0,0-3.574H12.395a1.787,1.787,0,0,0-1.786,1.787v19.476H1.786a1.787,1.787,0,0,0,0,3.574H98.214a1.787,1.787,0,0,0,0-3.574" transform="translate(0 -36.887)"/>
            </g>
        </svg>
    )
    const monetize =(
        <svg width={width} height={height} viewBox="0 0 100 88">
            <defs>
                <clipPath>
                <rect width="100" height="88.015" fill="#707070"/>
                </clipPath>
            </defs>
            <g transform="translate(12 -3)">
                <g transform="translate(-12 3)" clipPath="url(#clip-path)">
                <path fill={fill}  d="M149.911,66.317a14.674,14.674,0,0,0-10.356,4.27c-.017.016-.036.027-.052.042s-.027.035-.042.052a14.713,14.713,0,1,0,10.45-4.364M160.9,79.3a1.792,1.792,0,0,0-.444-.062h-8.748V70.06A11.151,11.151,0,0,1,160.9,79.3m-17.477-7.294a11.066,11.066,0,0,1,4.69-1.949V76.7Zm6.488,20.155a11.117,11.117,0,0,1-9.03-17.613l7.759,7.759,0,0a1.788,1.788,0,0,0,.271.222c.046.031.1.052.143.078a1.03,1.03,0,0,0,.351.145c.051.015.1.035.151.045a1.781,1.781,0,0,0,.352.036h10.545a1.792,1.792,0,0,0,.444-.062,11.143,11.143,0,0,1-10.99,9.39" transform="translate(-74.432 -36.512)"/>
                <path fill={fill}  d="M94.607,0H5.393A5.4,5.4,0,0,0,0,5.393V82.622a5.4,5.4,0,0,0,5.393,5.393H94.607A5.4,5.4,0,0,0,100,82.622V5.393A5.4,5.4,0,0,0,94.607,0m0,84.42H5.393a1.8,1.8,0,0,1-1.8-1.8V5.393a1.8,1.8,0,0,1,1.8-1.8H94.607a1.8,1.8,0,0,1,1.8,1.8v7.64H9.546a1.8,1.8,0,0,0,0,3.6H96.4V82.622a1.8,1.8,0,0,1-1.8,1.8"/>
                <path fill={fill}  d="M196.483,18.526A1.986,1.986,0,1,0,194.5,16.54a1.988,1.988,0,0,0,1.986,1.986" transform="translate(-107.085 -8.013)"/>
                <path fill={fill}  d="M180.483,18.526A1.986,1.986,0,1,0,178.5,16.54a1.988,1.988,0,0,0,1.986,1.986" transform="translate(-98.275 -8.013)"/>
                <path fill={fill}  d="M164.483,18.526A1.986,1.986,0,1,0,162.5,16.54a1.988,1.988,0,0,0,1.986,1.986" transform="translate(-89.466 -8.013)"/>
                <path fill={fill}  d="M25.282,135.8a1.8,1.8,0,0,0,1.8-1.8V127.8a1.8,1.8,0,1,0-3.6,0V134a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-12.93 -69.372)"/>
                <path fill={fill}  d="M43.282,120.386a1.8,1.8,0,0,0,1.8-1.8V99.8a1.8,1.8,0,0,0-3.6,0v18.79a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-22.84 -53.956)"/>
                <path fill={fill}  d="M61.282,130.3a1.8,1.8,0,0,0,1.8-1.8V117.8a1.8,1.8,0,1,0-3.6,0v10.7a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-32.75 -63.866)"/>
                <path fill={fill}  d="M79.282,109.374a1.8,1.8,0,0,0,1.8-1.8V79.8a1.8,1.8,0,0,0-3.6,0v27.779a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-42.661 -42.945)" />
                <path fill={fill}  d="M97.282,109.374a1.8,1.8,0,0,0,1.8-1.8V79.8a1.8,1.8,0,1,0-3.6,0v27.779a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-52.571 -42.945)" />
                <path fill={fill}  d="M115.282,98.363a1.8,1.8,0,0,0,1.8-1.8V59.8a1.8,1.8,0,1,0-3.6,0V96.565a1.8,1.8,0,0,0,1.8,1.8" transform="translate(-62.481 -31.933)" />
                <path fill={fill}  d="M100.719,160.179H25.283a1.8,1.8,0,1,0,0,3.6h75.437a1.8,1.8,0,0,0,0-3.6" transform="translate(-12.93 -88.19)"/>
                </g>
            </g>
        </svg>

    )


    switch (icon) {
        case "demand":
          return demand;
        case "revenue":
          return revenue;
        case "monetize":
          return monetize;
        default:
          return null;
      }

}


export default Icon;