import React,{ useState, useEffect} from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import QuoteCard from 'components/mui/cards/quoteCard';
import TeamCard from './teamCard';


const AboutusArticle =()=> {
    const row = [
        {
            title:'Our Vision',
            article:'Accelerate advertising sales through our unique proprietary platform and highly efficient customer support, we aim to provide the best value and results for both advertisers and publishers. \n\nOur monetization network is a gateway to the world of RTB, and we have partnered up with all the major ad exchanges, demand partners, and advertisers worldwide to create an optimal platform.',
            picsrc:'images/company/aboutus/aboutus-2.png',
        },
        {
            title:'Our Journey',
            article:'',
            picsrc:'images/company/aboutus/aboutus-3.png',
        },
        {
            title:'Our Team',
            article:'',
            picsrc:'',
        }
    ]
    const teams = [
        {
            name:'Alex Kapralov',
            title:'CEO',
            picDev:'images/company/aboutus/teams/alex-def.png',
            picAct:'images/company/aboutus/teams/alex-act.png',
            border:false
        },
        {
            name:'Lana Bravo',
            title:'Account Executive',
            picDev:'images/company/aboutus/teams/lana-def.png',
            picAct:'images/company/aboutus/teams/lana-act.png',
            border:false
        },
        {
            name:'Matvey Rasner',
            title:'Account Executive',
            picDev:'images/company/aboutus/teams/matvey-def.png',
            picAct:'images/company/aboutus/teams/matvey-act.png',
            border:false
        },
        {
            name:'Rohit Lall',
            title:'Account Executive',
            picDev:'images/company/aboutus/teams/rohit-def.png',
            picAct:'images/company/aboutus/teams/rohit-act.png',
            border:false
        },
        {
            name:'Vitali Ioussoupov',
            title:'Senior Developer',
            picDev:'images/company/aboutus/teams/vitali-def.png',
            picAct:'images/company/aboutus/teams/vitali-act.png',
            border:false
        },
        {
            name:'Oleksandr Polishchuk',
            title:'Platform Developer',
            picDev:'images/company/aboutus/teams/sasha-def.png',
            picAct:'images/company/aboutus/teams/sasha-act.png',
            border:false
        },
        {
            name:'Aleksei Kheba',
            title:'Full Stack Developer',
            picDev:'images/company/aboutus/teams/leha-def.png',
            picAct:'images/company/aboutus/teams/leha-act.png',
            border:false
        },
        {
            name:'Jing Xu',
            title:'Web Developer',
            picDev:'images/company/aboutus/teams/jing-def.png',
            picAct:'images/company/aboutus/teams/jing-act.png',
            border:false
        },
        {
            name:'Mykola Tsurkan',
            title:'Back End Developer',
            picDev:'images/company/aboutus/teams/mykola-def.png',
            picAct:'images/company/aboutus/teams/mykola-act.png',
            border:false
        },
        {
            name:'Dmytro Kuchma',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/dmytro-def.png',
            picAct:'images/company/aboutus/teams/dmytro-act.png',
            border:false
        },
        {
            name:'Yana Horlova',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/yana.png',
            picAct:'images/company/aboutus/teams/yana.png',
            border:true
        },
        {
            name:'Alona Baturina',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/alona.png',
            picAct:'images/company/aboutus/teams/alona.png',
            border:true
        },
        {
            name:'Andrii Balitskyi',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/andrii.png',
            picAct:'images/company/aboutus/teams/andrii.png',
            border:true
        },
        {
            name:'Maryna Dymar',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/maryna.png',
            picAct:'images/company/aboutus/teams/maryna.png',
            border:true
        },
        {
            name:'April Roche',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/april-def.png',
            picAct:'images/company/aboutus/teams/april-act.png',
            border:true
        },
        {
            name:'Mark Jeciel',
            title:'Media Buyer',
            picDev:'images/company/aboutus/teams/mark-def.png',
            picAct:'images/company/aboutus/teams/mark-act.png',
            border:true
        },
        {
            name:'Roberta',
            title:'Robot',
            picDev:'images/company/aboutus/teams/robot.jpeg',
            picAct:'images/company/aboutus/teams/robot.jpeg',
        },        

    ]
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        padding:'50px 0',
        background:`url('/images/column-back-a.png') no-repeat top center`,
    }

    const containerRef = React.useRef(null);

    const [smallScreen,setSmallScreen]= useState();
    const [windowWidth,setWindowWidth] = useState(window.innerWidth);
    const handleCheckWidth = () => {
        setWindowWidth(window.innerWidth)
    }
    useEffect(()=>{
        window.addEventListener('resize', handleCheckWidth);
        setSmallScreen(windowWidth<900? true:false)
        return(() => {
            window.removeEventListener('resize', handleCheckWidth);
        })
    },[windowWidth])

    return (
        <WideContainer className='aboutus-content'>
                <div className='center-align' style={containerStyle}>
                    <NarrowContainer>
                    <div className='column-title'>
                            <h1>{row[0].title}</h1>
                    </div>
                    <div style={{position:'relative',width:'100%',height:'100%'}}>
                        <Slide timeout={{enter:800}} in={inView1} direction="up" container={containerRef.current}>
                            <Grid item xs={12} sm={6} md={6} className='center-align'>
                                  <img loading='lazy' className='contentPic' src={'/' + row[0].picsrc} alt=''/>
                            </Grid>
                        </Slide>
                        <Grow timeout={{enter:1200}} in={inView1} className='quotation-container'>
                            <Grid item xs={12} sm={8} md={8}>
                                <QuoteCard content={row[0].article}/>
                            </Grid>
                        </Grow>
                    </div>
                    <div className='observer' ref={inViewRef1}></div>
                    </NarrowContainer>
                </div>
                <div className='center-align'>
                    <NarrowContainer className='center-align'>
                        <div className='column-title'>
                                <h1>{row[1].title}</h1>
                        </div>
                        <Grow timeout={{enter:800}} in={inView2}  container={containerRef.current} className='center-align'>
                            <div style={{width:smallScreen?'100%':'85%'}}>
                                  <img loading='lazy' className='contentPic' src={'/' + row[1].picsrc} alt=''/>
                            </div>
                        </Grow>
                        <div className='observer' ref={inViewRef2}></div>
                    </NarrowContainer>
                </div>
                <div className='center-align'>
                    <NarrowContainer className='center-align'>
                        <div className='column-title'>
                                <h1>{row[2].title}</h1>
                        </div>
                        <Slide timeout={{enter:1000}} in={inView3} direction="up" container={containerRef.current}>
                            <Grid container px={smallScreen?'':8}>
                                {teams.map((team,i)=>
                                <TeamCard key={i} team={team}/>)}
                                <Grid item xs={6} sm={4} md={3} className='aboutus-teamcard'>
                                <div className='avatar-circle'>
                                    <a href='https://ca.indeed.com/cmp/Pixfuture-Media-Inc.-1/jobs' rel="noopener noreferrer">This could be you</a>
                                </div>
                            </Grid> 
                            </Grid>
                        </Slide>
                    </NarrowContainer>
                    <div className='long-observer' ref={inViewRef3}></div>
                </div>

            </WideContainer> 
    );
}

export default AboutusArticle;