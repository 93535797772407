import {React,useState} from 'react';
import { Grid } from '@mui/material';

const TeamCard =({team})=> {
    const[avatarHover,setAvatarHover]=useState(false);
    return (
        <Grid item xs={6} sm={4} md={3} className='aboutus-teamcard'>
            <div className='avatar' onMouseOver={()=> setAvatarHover(true)} onMouseLeave={()=>setAvatarHover(false)}>
                  <img loading='lazy' className='active'style={{display: avatarHover?'block':'none',border:team.border? '1px solid var(--pxft-mildgrey)':''}} src={'/' + team.picAct} alt=''/>
                  <img loading='lazy' className='dev' style={{display: avatarHover?'none':'block',border:team.border? '1px solid var(--pxft-mildgrey)':''}} src={'/' + team.picDev} alt=''/>
            </div>
            <h6>{team.name}</h6>
            <p>{team.title}</p>
        </Grid>
    );
}

export default TeamCard;