import React from 'react';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Button } from '@mui/material';
import GostorCard from 'components/mui/cards/gostoryCard';
import CenterTitle from 'components/mui/containers/centerTitle';

const BrandbookArticle=()=> {
    const containerStyle={
        display:'flex',
        flexDirection:'column',
        padding:'50px 0 100px 0',
        background:`url('/images/column-back-b.png') no-repeat bottom center`,
    }
    const bulletStyle={
        background:`url('/images/bullet-symbol.svg') no-repeat left center`,
        backgroundSize: '8px'
    }
    return (
        <div className='brandbook-container'>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer className='mobile-reverse'>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                          <img loading='lazy' className='contentPic' src='/images/brandbook/brandbook-name.png' alt=''/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title='Our Name' content='"pixfuture" is one word, spelled without any space between letters.'/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title='Our Logo' content='Our logo is using two colors or only white color when the background is dark.'/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                          <img loading='lazy' className='contentPic' src='/images/brandbook/brandbook-logo.png' alt=''/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer className='mobile-reverse'>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <Grid container className='center-align'>
                                <Grid item xs={6} sm={10} md={10}className='color-box' style={{backgroundColor:'var(--pxft-blue)'}}>#0b3599</Grid>
                                <Grid item xs={6} sm={10} md={10} className='color-box' style={{backgroundColor:'var(--pxft-yellow)'}}>#fcb402</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title='Our Theme Color' content='We use yellow and blue colors as our main colors.'/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content' style={{backgroundColor:'var(--pxft-lightgrey)'}}>
                <NarrowContainer>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title='The Fine Print' content='This is a friendly legal reminder that all the graphics are proprietary and protected under intellectual property laws.'/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align fine-print-container' style={{flexDirection:'column'}}>
                            <h3>Please Do:</h3>
                            <div className='content'>
                                <p style={bulletStyle}>Provide enough space around pixfuture logo.</p>
                                <p style={bulletStyle}>Make the logo clear to be recognize.</p>
                            </div>
                            <h3>Please Don't:</h3>
                            <div className='content'>
                                <p style={bulletStyle}>Alter the files in any way.</p>
                                <p style={bulletStyle}>Use these graphics as part of your own product, business, or service’s name.</p>
                            </div>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content' style={containerStyle}>
                <NarrowContainer>
                        <Grid item xs={12} sm={12} md={12} className='center-align'>
                            <CenterTitle title='Downloads' article='you could download the source that you need here.'/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className='center-align'>
                                <Grid item xs={12} sm={3} md={3} className='center-align'><a href='/images/brandbook/logo-main(png).zip'><Button sx={{p:2,m:3}} variant='contained'>PNG Logo (color)</Button></a></Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align'><a href='/images/brandbook/logo-white(png).zip'><Button sx={{p:2,m:3}} variant='contained'>PNG Logo (white)</Button></a></Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align'><a href='/images/brandbook/logo-main(eps).zip'><Button sx={{p:2,m:3}} variant='contained'>EPS Logo (color)</Button></a></Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align'><a href='/images/brandbook/logo-white(eps).zip'><Button sx={{p:2,m:3}} variant='contained'>EPS Logo (white)</Button></a></Grid>
                            </Grid>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
        </div>
    );
}

export default BrandbookArticle;