import {React} from 'react';
import { Grid,Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const CardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    width:60%;
    height:250px;
    max-width:300px;
    padding: 50px 20px;
    margin:10px 0;

    & .title {
        color: black;
        white-space: pre-line;
        text-align:center;
        justify-content:center;
        padding:10px 0;
        width:100px;
        border-bottom:2px solid var(--pxft-primary);
    }
    & .quotation img{
        width:80px;
        position:absolute;
        top:-40px;
        right:20px;
    }
    & p {
        padding:30px 0;
        color: var(--pxft-midgrey);
        white-space: pre-line;
        text-align:center;
    }
`

const ContactCard =({card})=> {
    return (
        <Grid item xs={12} sm={12} md={4} className='center-align'>
            <CardPaper elevation={10} className='center-align'>
                <h2 className='title'>{card.title}</h2>
                <p>{card.content}</p>
            </CardPaper>
        </Grid>

    );
}

export default ContactCard;