import React from 'react';
import { Grid } from '@mui/material';

const NumberCard=({card})=> {
    return (
        <Grid item xs={12} md={6} lg={6} className='numbercard-container'>
            <h1 className='numbercard-title'>{card.title}</h1>
            <p className='numbercard-article'>{card.article}</p>
        </Grid>
    );
}

export default NumberCard;