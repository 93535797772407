import React from 'react';
import Carousel from 'react-material-ui-carousel'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useInView } from 'react-intersection-observer'

const HomeSteps =() =>{
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <div>
        <div ref={inViewRef}></div>
        <Carousel
        NextIcon={<ChevronRightIcon/>}
        PrevIcon={<ChevronLeftIcon/>}
        sx={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div className='center-align'>
                  <img loading='lazy' style={{width:'85%'}} src='/images/home/display.gif' alt=''/>
            </div>
            <div className='center-align'>
                <video preload='none' autoPlay style={{width:'80%'}} muted webkit-playsinline="true" playsInline loop src='https://cdn.pixfuture.com/corporate/home/video-demo.mp4'/>
            </div>
            <div className='center-align'>
                <video preload='none' autoPlay style={{width:'90%'}} muted webkit-playsinline="true" playsInline loop src='https://cdn.pixfuture.com/corporate/home/gostory-demo.mp4'/>
            </div>
        </Carousel>
        </div>

    );
}

export default HomeSteps;