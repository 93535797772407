import React from 'react';

function PrivacyPolicy() {
    const openTab=(tab)=>{
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" tab-active", "");
        }
        var tabContent=tab+"-content";
        document.getElementById(tabContent).style.display = "block";
        document.getElementById(tab).classList.add("tab-active");
    }
    return (
        <div id="privacy-policy-content" className="tabcontent">
        <div className="updated-time">Last Revised: April 27, 2022</div>
        <h3 className="policy-title">Privacy Policy</h3>
        <p><strong>PixFuture is deeply committed to protecting the privacy of Internet users.</strong><br/>Accordingly, we
            strictly adhere to all industry guidelines, and will continue to review and improve our privacy policy and
            procedures to ensure the safety and protection of consumer information. This Privacy Statement presents our
            privacy policy, including PixFuture’s privacy practices for our web site. Below is information regarding
            PixFuture’s commitment to protecting the privacy of Internet users.</p>
        <p>PixFuture uses own or third party ad serving technology to serve ads at this site and at our clients’ sites. In
            the course of delivering an ad to you, the third party ad serving party may place or recognize a unique cookie
            on your browser and use information (not including your name, address, email address or telephone number) about
            your visits to this and other Web sites in order to provide advertisements about goods and services that may be
            of interest to you</p>
        <p><strong> WHAT INFORMATION DO WE COLLECT? </strong><br/>When End Users visit a Digital Property that uses our technology, we (pixfuture/our partners/vendors) may use and deploy tracking technologies to automatically collect certain information about the End Users and their devices. The information we collect includes data about ads served, viewed, or clicked; IP address, referrer URL, and the date, time, of your request..</p>
        <p><strong> HOW DO WE USE YOUR INFORMATION? </strong><br/>We use the information we collect for a variety of business
            purposes. PixFuture uses the information for the following purposes:<br/>
            </p><p> <strong>Serving Ads: </strong> To allow Publisher Clients to offer advertising inventory in their Digital Properties
                and Media Buyers to bid on and fill that inventory with relevant ads.</p>
            <p> <strong>Ad Reporting and Conversions: </strong> To provide information and reports to Publishers about when and how
                End
                Users have been exposed to their ads, clicked on their ads, or visited their Digital Property.</p>
            <p> <strong>Frequency Capping: </strong> To prevent End Users from seeing the same ad too many times.</p>
            <p> <strong>Performance Analytics: </strong> To analyze ad performance, such as tracking views of ads, as well as
                click-through rates to websites or app stores and/or installs of apps that have been advertised.</p>
            <p> <strong>Location-Based Advertising: </strong> To deliver location-based advertising, services, and content in
                real-time
                through the use of your device’s physical location (where permitted by law). </p>
            <p> <strong>Fraud Detection and Prevention and Security: </strong> To identify invalid ad impressions, clicks, installs,
                or ad queries, protect us and our Publisher Clients from fraudulent behavior, and protect the security
                of the Ad Services.</p>
            <p> <strong>As we believe to be necessary or appropriate: </strong> (a) under applicable law, including laws outside an
                End
                User’s country of residence; (b) to comply with subpoenas, warrants, or other legal process; (c) to
                respond to requests from public and government authorities including public and government authorities
                outside an End User’s country of residence; (d) to enforce our terms and conditions; (e) to protect our
                operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property,
                and/or that of our affiliates, End Users, or others; and (g) to allow us to pursue available remedies or
                limit the damages that we may sustain.</p>
            <p><strong>COOKIES AND SIMILAR TRACKING TECHNOLOGIES</strong><br/>We use cookies and other similar tracking
                technologies (such as pixel tags, IDFA, AAID/GAID, UDID, ETags, and web or browser caches) to collect
                information automatically from End Users’ devices. They help us identify unique browsers or devices used by an
                End User and to perform functions like, for example, limiting the same ad from continuously reappearing,
                ensuring that ads are properly displayed on our Publisher Clients’ Digital Properties, and serving an ad more
                relevant to the End User. To opt out of our use of such technologies for interest-based advertising purposes,
                please click the button below:</p>
            <br/>
                <iframe loading='lazy' src="https://served-by.pixfuture.com/www/delivery/optout.html" title="opt" frameBorder="0" width="120px" height="40px" style={{verticalAlign: 'top'}}/>
                <p><strong> YOUR DATA PROTECTION RIGHTS </strong><br/>You have the following data protection rights: <br/>
                    You have the right to request access to or that we change, update, or delete your personal information at
                    any time. </p>
                    <p>If we have collected and process your personal information with your consent, then you can withdraw your
                        consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted
                        prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on
                        lawful processing grounds other than consent.</p>
                    <p>The right to opt out of receiving marketing communications from us. </p>
                    <p> If you are a resident of the European Economic Area (EEA), you may also have the following rights: </p>
                    <p>The right to object to processing of your personal information, restrict processing of your personal information,
                        or request portability of your personal information. To exercise these rights please get in touch with our <a href="https://support.pixfuture.com/tickets/index.php?a=add">Support team</a>. We will respond to your request in
                        accordance with our obligations under applicable privacy laws.
                        Please review our <a href='/terms-and-conditions#dpa-terms' rel="noreferrer" onClick={() =>openTab('dpa-terms')}>Data Processing Agreement</a> for further information and instructions on how to exercise your
                        rights.</p>
                    <p>Like most Internet sites, PixFuture and its third party ad serving party uses “cookies” to enhance the Internet
                        user’s experience on the Web. Neither PixFuture nor such third party ad serving party uses these cookies to
                        personally identify you in any way, and these cookies in no way damage your system or files.
                    </p>
                    <p>A cookie is a unique number that is assigned to you the first time you are served an ad. This number is stored in
                        a file on your computer. For instance, cookies help ensure that you don’t repeatedly see the same ad. Cookies
                        can also help advertisers measure how you utilize an advertiser’s site and the effectiveness of an ad. This
                        information can then be used by advertisers to better cater to your needs. In other words, cookies help us
                        deliver to you the types of ads in which you are more interested.</p>
                    <p>PixFuture is committed to protecting the privacy of Internet users. Accordingly, we strictly adhere to all
                        industry guidelines, including those established by the Direct Marketing Association. If you should have any
                        questions or concerns regarding this Privacy Statement, please contact our&nbsp;<a href="https://support.pixfuture.com/tickets/index.php?a=add">Customer Services</a>. We recommend that
                        you periodically review this Privacy Statement, as PixFuture may update it from time to time.</p>
                    <p>We use PixFuture and other third-party advertising companies to serve ads when you visit our Web site. These
                        companies may use information (not including your name, address, email address or telephone number) about your
                        visits to this and other Web sites in order to provide advertisements on this site and other sites about goods
                        and services that may be of interest to you. If you would like more information about this practice and to know
                        your choices about not having this information used by these companies, please <a href="https://support.pixfuture.com/tickets/index.php?a=add"> click here</a></p>
                    <p>In the course of serving advertisements to this site, our third-party advertiser may place or recognize a unique
                        “cookie” on your browser.</p>
    </div>
    );
}

export default PrivacyPolicy;