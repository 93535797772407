import React from 'react';

function Header(props) {
    return (
        <div className='center-align' style={{padding:'30px 0'}}>
            <h1>Terms &amp; Conditions</h1>
        </div>
    );
}

export default Header;