import React,{ useEffect} from 'react';
import CTV from '../../components/video/screens/ctv'

const Ctv =() => {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div>
            <CTV/>
        </div>
    );
}

export default Ctv;