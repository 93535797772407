import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import ContactCard from './contactCard';

const CardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    width:60%;
    height:250px;
    max-width:300px;
    padding: 50px 20px;
    margin:10px 0;

    & .title {
        color: black;
        white-space: pre-line;
        text-align:center;
        justify-content:center;
        padding:10px 0;
        width:100px;
        border-bottom:2px solid var(--pxft-primary);
    }
    & .quotation img{
        width:80px;
        position:absolute;
        top:-40px;
        right:20px;
    }
    & p {
        padding:30px 0;
        color: var(--pxft-midgrey);
        white-space: pre-line;
        text-align:center;
    }
`

const ContactusArticle = () => {
    const containerStyle={
        padding:'100px 0 150px 0',
        background:`url('/images/column-back-b.png') no-repeat top center`,
    }
    const cards = [
        {
            title:'Address',
            content:'7191 Yonge St \nSuite 812, 8th floor \nToronto, ON, Canada\nL3T 0C4'
        },
        {
            title:'Phone \n&\nFax',
            content:'+1 (888) 757-9997 '
        }
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <WideContainer className='contact-content'>
            <Grow timeout={{enter:1200}} in={inView}>
                <div style={containerStyle} className='center-align'>       
                    <NarrowContainer>
                        {cards.map((card,i)=><ContactCard card={card} key={i}/>)}
                        <Grid item xs={12} sm={12} md={4} className='center-align'>
                            <CardPaper elevation={10} className='center-align'>
                            <h2 className='title'>Client Support</h2>
                            <p>Please use automated<br/><a href='https://support.pixfuture.com/tickets/index.php?a=add'><b>Support System</b></a></p>
                            </CardPaper>
                        </Grid>
                    </NarrowContainer>
                <div className='observer' ref={inViewRef}></div>
            </div>
            </Grow> 
            <div>
                <WideContainer>
                <div className='map'>
                    <a href='https://www.google.com/maps?q=PixFuture+Media+Inc.&hl=en&sll=43.856579,-79.335065&sspn=0.008587,0.021136&hq=PixFuture+Media+Inc.&t=m&z=16&iwloc=A' target="_blank" rel="noopener noreferrer">
                      <img loading='lazy' src='/images/company/contactus/map.png' alt=''/>
                    </a>
                </div>
                </WideContainer>
            </div>
        </WideContainer>
    );
}

export default ContactusArticle;