import React, { useState,useEffect } from 'react';
import BlogDetailsArticle from 'components/company/blogDetails/blogDetailsArticle';
import BlogDetailsHeader from 'components/company/blogDetails/header';
import 'assets/styles/company.css'
import MetaTags from 'components/base/metaTags';
import axios from 'axios.js';

const  BlogDetails =()=>{

    const[blog,setBlog]=useState([])
    const[blogs,setBlogs] =useState([])

    const getBlogs = async () => {
      await axios.get("/api/content/blogs/all")
          .then(
              (res) => {
                  setBlogs(res.data.blogs || { blogs: [] })
              }
          )
          .catch((error) => {
              setBlogs({ blogs: [] })
          });
  }

      useEffect(() => {
        window.scrollTo(0, 0);
        getBlogs();
      }, []);

      useEffect(()=>{
        if(blogs.length>0){
          let pathname = window.location.pathname
          let url = pathname.split('/')
          setBlog(blogs.find(b=>b.url === url[3]));
        }else{
          setBlog({})
        }
      },[blogs])


    const metaTags={
        webTitle:'PixFuture | '+blog.title,
        webSiteName:"PixFuture Media Inc.",
        webImage: '/'+blog.src,
        webDescription:blog.content,
        webUrl:window.location.href,
        locale:"en_US",
        type:"article"
    }

    if(!blog) {
      return <></>
    }

    return (
        <>
            <MetaTags tag={metaTags}/>
            <BlogDetailsHeader blog={blog}/>
            <BlogDetailsArticle blog={blog} blogs={blogs}/>
        </>
    );
}

export default BlogDetails;