import React from 'react';
import { useInView } from 'react-intersection-observer'

const PicsGallary =({items})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const imgBox = (src) =>{
        return(
              <img loading='lazy' src={'/' + src} alt='' />
        ) 
    }

    const videoBox = (src) =>{
        return(
            <video preload='none' autoPlay muted webkit-playsinline="true" playsInline loop src={src} alt='' />
        ) 
    }

    return (
        <div className='gostory-pics-container'>
            <div ref={inViewRef}></div>
            {items.map((item,i) => {
                if(i===0 ||i===5){
                    return(
                    <div className={i === 0? 'left-imgbox':'right-imgbox'} key={i} style={{marginTop:item.marginTop,marginBottom:'auto'}}>
                        {item.img? imgBox(item.src):videoBox(item.src)}
                    </div>)
                }else{
                    return(
                    <div className='imgbox' key={i} style={{marginTop:item.marginTop,marginBottom:'auto'}}>
                    {item.img? imgBox(item.src):videoBox(item.src)}
                    </div>)
                }
            })}
        </div>
    );
}

export default PicsGallary;