import Article from 'components/resources/termsAndConditions/article';
import Header from 'components/resources/termsAndConditions/header';
import React,{ useEffect} from 'react';
import 'assets/styles/resource.css'

const TermsAndConditions=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div className='terms-and-conditions-container'>
            <Header/>
            <Article/>
        </div>

    );
}

export default TermsAndConditions;