import React, { useState, useEffect} from 'react';
import logo from 'assets/images/logo.png';
import 'assets/styles/base/navbar.css';
import MobileMenu from './mobileMenu';
import { WideContainer} from 'components/mui/containers/PxftContainer';
import { Button, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavLink } from 'react-router-dom'
import NaviData from 'data/navi/naviLinkItem.json';

const NavigationBar = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [windowWidth,setWindowWidth] = useState(window.innerWidth);
  const [smallScreen,setSmallScreen]= useState();

const handleScroll = () => {
    setScroll(window.scrollY);
};
const handleCheckWidth = () => {
    setWindowWidth(window.innerWidth)
}

  useEffect(() => {
    const navbar = document.querySelector(".nav");
    setHeaderTop(navbar.offsetTop);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener('resize', handleCheckWidth);
    setSmallScreen(windowWidth<900? true:false)
    return () => {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener('resize', handleCheckWidth);
    };
}, [windowWidth]);

const WindowMenu= () =>{
return(
  <Grid container className='nav-navi-contents'>
    <Grid item md={9} xs={6} className='center-align'>
      <Grid container justifyContent='left' className='center-align'>
        <Grid item pr={4} display='flex' alignItems='center' justifyContent='left' className='center-align'>
          <NavLink to='/'><img className='logoImg' src={logo} alt='logo'/></NavLink>
        </Grid >
            <nav className="site-main-menu" style={{display:smallScreen?'none':'block'}}>
            <ul>
                  {NaviData && NaviData[0].list.map((item,i)=>(
                        <li key={i}>
                            <a href={item.url} target={item.outSource? "_blank":''} rel="noopener noreferrer">{item.text}</a>
                        </li>
                      ))}
                  {NaviData && NaviData[1].list.map((item,i)=>(
                        <li key={i}>
                            <a href={item.url} target={item.outSource? "_blank":''} rel="noopener noreferrer">{item.text}</a>
                        </li>
                      ))}
                <li className="has-children">
                    <NavLink to='#'>
                      <span className="menu-text">{NaviData[2].title}<KeyboardArrowDownIcon fontSize='' sx={{pl:0.3,mt:0.3}}/></span>
                    </NavLink>
                    <ul className="sub-menu">
                      {NaviData && NaviData[2].list.map((item,i)=>(
                        <li key={i}>
                            <a href={item.url} target={item.outSource? "_blank":''} rel="noopener noreferrer">{item.text}</a>
                        </li>
                      ))}
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to='#'><span className="menu-text">{NaviData[3].title}<KeyboardArrowDownIcon fontSize='' sx={{pl:0.3,mt:0.3}}/></span></NavLink>
                    <ul className="sub-menu">
                      {NaviData && NaviData[3].list.map((item,i)=>(
                        <li key={i}>
                            <a href={item.url} target={item.outSource? "_blank":''} rel="noopener noreferrer">{item.text}</a>
                        </li>
                      ))}
                    </ul>
                </li>
            </ul>
        </nav>
      </Grid>  
    </Grid>
    <Grid item md={3} xs={6} className='center-align' style={{justifyContent:'flex-end'}}>
    {smallScreen? 
    <MobileMenu/>:
    <div style={{display:'flex',flexDirection:'row'}}>
      <a href="https://portal.pixfuture.com/login" target="_blank" rel="noreferrer"><Button sx={{width:120,mx:2}}>Log In</Button></a>
      <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer"><Button variant="contained" sx={{width:120}}>Sign Up</Button></a>
    </div>
    }
    </Grid>
    </Grid>
);
}

    return(
      <div>
        <WideContainer className={`nav ${scroll > headerTop ? "is-sticky" : ""}`}>
          <div className='center-align'>
              {WindowMenu()}
          </div>
        </WideContainer>
      </div>
    );

}
export default NavigationBar;