import React from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import { BlueDivider, YellowDivider} from 'components/mui/divider/divider';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import CenterTitle from 'components/mui/containers/centerTitle';
import MegaButton from 'components/mui/buttons/magaButton';

const Header=()=> {
    const header = {
        title:'Your Video Strategy Hub In One Place',
        article:'Unlock the full potential of your video content with the complete publisher video suite by PixFuture, designed to drive your success.',
        src:'',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='video-header-container'>
            <div ref={inViewRef1}></div>
            <YellowDivider marginTop='15%' width='60%' marginLeft='0' height='15px'/>
            <BlueDivider marginBottom='20%' width='100%' marginLeft='0' height='15px'/>
            <Grid className='center-align' flexDirection='column'>
                <Slide timeout={{enter:1200}} in={inView} direction="down" container={containerRef.current}>
                    <Grid className='center-align' flexDirection='column' pt={{lg:1,xs:4}}>
                        <CenterTitle title={header.title} article={header.article}/>
                    <Grid className='btn-container' mt={2} mb={3}>
                        <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                            <MegaButton width='200px' height='50px' content='register'/>
                        </a>
                    </Grid>
                    </Grid>
                </Slide>
                <Grid container className='header-content center-align' mt={{lg:5}}>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align header-video' style={{zIndex:'2'}}>
                            <Grid container>
                                <Grid item xs={12} md={8} className='center-align instream-header-desktop-video-container'>
                                    <video preload='none' playsInline loop autoPlay muted type="video/mp4" src="https://cdn.pixfuture.com/pixfuture/videos/instream-video/desktop-instream.mp4"></video>
                                </Grid>
                                <Grid item xs={12} md={4} className='center-align instream-header-mobile-video-container'>
                                    <video preload='none' playsInline loop autoPlay muted type="video/mp4" src="https://cdn.pixfuture.com/pixfuture/videos/instream-video/mobile-instream.mp4"></video>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Header;