import React from 'react';
import SupportForm from './supportForm';
import Divider from '@mui/material/Divider';
import { NarrowContainer } from 'components/mui/containers/PxftContainer';

const Article=()=> {
    return (
        <div className='center-align'>
            <NarrowContainer className='center-align'>
                <div className='center-align' style={{flexDirection:'column'}}>
                    <p>This is technical support for registered publishers/advertisers only.</p>
                    <p>Please fill up the form below to contact support team. If you are not registered client, 
                        please <a href='https://portal.pixfuture.com/registration'><b>sign-up</b></a> first.</p>
                    <Divider sx={{p:2,width:'100%'}}/>
                </div>
                <div className='center-align'>
                    <SupportForm/>
                </div>
            </NarrowContainer>
        </div>
    );
}

export default Article;