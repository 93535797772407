import React,{ useState} from 'react';
import { Grid } from '@mui/material';
import Icon from 'components/icons/icon'
import { styled } from '@mui/material/styles';


const ContainerGrid = styled(Grid)`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-right: 3px solid var(--pxft-transparent);
        color: var(--pxft-midgray);
        transition: 0.2s linear;
        padding:0 30px 0 0;
        margin:40px 0;

        &:hover {
            cursor:pointer;
        }

        & .icon{
            padding:0 15px;
        }
        & .content {
            width:100%;
            padding:0 15px;
        }
        & h3{
            padding:10px 0;
            font-weight: var(--pxft-font-bold);
        }
`;

const IconCardA = ({IconCard,inview=false})=> {
    const [isHover,setIsHover] = useState(false);
    const mouseEnter = () =>{
        setIsHover(true);
    }
    const mouseOut = () =>{
        setIsHover(false);
    }
        return (
            <ContainerGrid container sx={{borderRight:inview?'3px solid var(--pxft-primary)':'3px solid var(--pxft-transparent)',transition:'0.6s linear;'}} display='flex' flexDirection='row' my={4} onMouseEnter={() => mouseEnter()} onMouseLeave={() => mouseOut()}>
                <Grid item xs={4} className='icon'>
                    <Icon icon={IconCard.icon} width={IconCard.iconWidth} height={IconCard.iconHeight} fill={inview||isHover? 'var(--pxft-primary)':'var(--pxft-mildgray'}/>
                </Grid>
                <Grid item xs={8} className='content'>
                    <h3 style={{color:inview||isHover?'black':'var(--pxft-mildgray)'}}>{IconCard.title}</h3>
                    <p>{IconCard.contents}</p>
                </Grid>
            </ContainerGrid>
        );
}

export default IconCardA;