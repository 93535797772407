import React from 'react';

function DemandPolicy() {
    return (
        <div id="demand-policy-content" className="tabcontent">
        <div className="updated-time">Last Revised: March 20, 2013</div>
        <h3 className="policy-title">Demand Policy</h3>
        <p>These policies apply to our demand partners, meaning anyone who buys inventory from PixFuture platform. These policies don’t replace or alter your PixFuture agreement – they are in addition to those terms. PixFuture may update these policies from time to time.</p>
        <p><strong>Prohibited Creatives and Related Content</strong></p>
        <p>You cannot use the PixFuture for ads that do any of the following (or link to any website or other online property that does any of the following):</p>
        <p>&#8211; Contain nudity or suggestive content or images;<br />&#8211; Promote adult products, including sex toys and sexual enhancers;<br />&#8211; Promote adult-themed dating, escort services, “mail-order brides,” or similar services;<br />&#8211; Install spyware, trojans, viruses or other malware;<br />&#8211; Incite hatred of any race, religion, creed, className or ethnic group, or of any individual or group;<br />&#8211; Describe, depict, or glorify pain, suffering, torture, violence or death of or against humans or animals;<br />&#8211; Offer to sell or provide design materials for firearms, ammunition, bombs and other weapons;<br />&#8211; Sell or promote illegal drugs, drug paraphernalia, or ways to pass a drug test;<br />&#8211; Promote P2P file-sharing, torrent, or anything that facilitates or promotes copyright infringement;<br />&#8211; Infringe intellectual property rights;<br />&#8211; Promote or contain libel or fraud;<br />&#8211; Violate any applicable law, regulation, governmental rule or court order<br />&#8211; Contain profanity;<br />&#8211; Promote hacking, cracking, or warez;<br />&#8211; Promote any other products, services, or content that are illegal, promote harmful activity, or infringe on the rights of others. This includes sites that provide &#8220;how-to&#8221; information on bomb-making, lock-picking, and similar topics;<br />&#8211; Sell or promote tobacco;<br />&#8211; Are misleading or contain content that is not reflective of what the user will find on the clickthrough URL;<br />&#8211; Employ phishing techniques or seek to trick the user into providing sensitive information by misrepresenting the identity of the advertiser;<br />&#8211; Mimic system errors or messages; and/or<br />&#8211; Are unbranded.</p>
        <p> </p>
        <p><strong>Preapproval Required</strong></p>
        <p>You can only use the PixFuture for ads that do any of the following (or link to any website or other online property that does any of the following) if you have prior written approval from PixFuture (email sufficing):</p>
        <p>&#8211; Contain or depict alcohol, liquor or spirits;<br />&#8211; Sell or promote products related to sexual health, including condoms and birth control devices;<br />&#8211; Take over a page or expands beyond its original size;<br />&#8211; Promote or depict political or religious topics;<br />&#8211; Promote online gambling;<br />&#8211; Contain auto-initiated sound;<br />&#8211; Pop-up on a webpage, upon banner open, or upon page exit; and/or<br />&#8211; Contain excessive animation.</p>
        <p>You may <a href="https://www.pixfuture.com/tickets/index.php?a=add">contact support</a> directly to receive written consent to start such campaign.</p>
        <p> </p>
        <p><strong>Other Restricted Creatives and Related Content</strong></p>
        <p>If you use the PixFuture for ads related to alcohol, fantasy sports, or state lotteries, you are responsible for complying with all applicable laws and industry guidelines, including those that impose requirements related to the audience receiving such ads and those that require any notices to users regarding the legality of such ads in certain jurisdictions.</p>
        <p> </p>
        <p><strong>Prohibited Profiling</strong></p>
        <p>Demand partners may create targeting profiles or segments based on the ads they show to users and user interaction with those ads (as long as this is fully disclosed in your privacy policy and you follow all your legal and contractual obligations). Demand partners may <strong>not</strong> create targeting profiles or segments on the basis that a user has visited a particular publisher or website, the PixFuture category(ies) that apply to the inventory, website or publisher, the content on the web page or website, or the general interest area(s) covered by the publisher. This doesn’t prohibit you from targeting ads in real time on the basis of the inventory category(ies) shown in the PixFuture, as long as you don’t retain that information and/or include it in a targeting profile or segment.</p>
        <p> </p>
        <p><strong>Violations</strong></p>
        <p>At PixFuture’s sole discretion, PixFuture may assess a surcharge for any violation of these policies and/or immediately terminate your access to the platform (in addition to any other legal or contractual remedies). If PixFuture assesses a surcharge, we will use our reasonable discretion to set the amount of the charge, taking into account the nature of the violation, the additional work and expense PixFuture incurred in dealing with the violation, and the harm done to the PixFuture, PixFuture’s reputation and/or to other PixFuture clients.</p>
    </div>
    );
}

export default DemandPolicy;