import { React, useState, useEffect } from 'react';
import { Grid, FormControl, Select, InputLabel, MenuItem, TextField, Button, Snackbar, Alert,Backdrop,CircularProgress } from '@mui/material';
import axios from 'axios.js';
import CountryList from './countries.json'
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const initialFormValues = {
    email_type: 'support',
    role: "",
    country: "",
    name: "",
    email: "",
    domain: "",
    subject: "",
    description: "",
};

const initialAlertValues = {
    alertOpen: false,
    alertType: "success",
    alertMessage: ""
}

const SupportFormContent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [alertValues, setAlertValues] = useState(initialAlertValues);
    const [errors, setErrors] = useState({});
    const [onchangeItem, setOnChangeItem] = useState({});
    const [loading, setLoading] = useState(false)
    const [loadingTimeOut,setLoadingTimeOut] = useState(false)

    useEffect(() => {
        const validate = (fieldValues = formValues) => {
            let temp = { ...errors }
            if ('name' in fieldValues) {
                if(formValues.name.length===0){
                    temp.name = ''
                }else if (!/^[a-zA-Z\s]*$/.test(fieldValues.name)) {
                    temp.name = "The full name must be only letters.";
                }else  
                temp.name = (formValues.name.length > 2 && formValues.name.length <= 250)? '':"The name must be more than 2 and less than 250 characters";
 
            }
            if ('email' in fieldValues) {
                if(formValues.email.length===0){
                    temp.email = ''
                }else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)) {
                    temp.email = "Email is not valid.";
                }else 
                temp.email = (formValues.email.length > 5 && formValues.email.length <= 250)? '':"The email must be more than 5 and less than 250 characters";
            }
            if ('domain' in fieldValues) {
                if(formValues.domain.length===0){
                    temp.domain = ''
                }else
                temp.domain=(formValues.domain.length > 3 && formValues.domain.length <= 250)? '':"The domain must be more than 3 and less than 250 characters";
            }
            if ('subject' in fieldValues) {
                if(formValues.subject.length===0){
                    temp.subject = ''
                }else
                temp.subject = (formValues.subject.length > 3 && formValues.subject.length <= 250) ? "" : "The subject must be more than 3 and less than 250 characters"
            }
            if ('description' in fieldValues){
                if(formValues.description.length===0){
                    temp.description = ''
                }
                temp.description = (formValues.description.length > 5 && formValues.description.length <= 250) ? "" : "The description must be more than 5 and less than 250 characters";
            }
            setErrors({
                ...temp
            });
        }
        validate(onchangeItem)
    },[onchangeItem,formValues,errors])


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setOnChangeItem({ [name]: value })
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const formIsValid = (fieldValues = formValues) => {
        const isValid =
            fieldValues.name &&
            fieldValues.email &&
            fieldValues.domain &&
            fieldValues.subject &&
            fieldValues.description &&
            Object.values(errors).every((x) => x === "");
        return isValid;
    };
    const sendEmailAsync = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('submit_support_form');
            const updatedFormValue = {...formValues,"g-recaptcha-response":token}
            const status = await axios.post("/email/send", updatedFormValue)
                // .then((res) => res.json())
                .then((res) => {
                    return (res.data && res.data.status != null) ? res.data : { status: false }
                })
                .catch((error) => {
                    console.log(error)
                    return { status: false }
                });
            return status;
            // return blogs;
        }else{
            setLoading(true)   
        }
    }
    useEffect(()=>{
        if(executeRecaptcha){
            setLoading(false)
        }
    },[executeRecaptcha])

    useEffect(()=>{
        if(loading){
            setTimeout(() => {
                setLoadingTimeOut(true)
            }, 30000);
        }else{
            setLoadingTimeOut(false)
        }
    },[loading])

    useEffect(()=>{
        if(loadingTimeOut && loading){
            setAlertValues({
                alertOpen: true,
                alertType: "warning",
                alertMessage: "Request expired. Please refresh the page."
            })
        }
    },[loadingTimeOut,loading])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertValues({})
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()) {
            setLoading(true)
            let info = await sendEmailAsync();
            if(info){
                setLoading(false)
                setAlertValues({
                    alertOpen: true,
                    alertType: (info.status) ? "success" : "error",
                    alertMessage: info.msg || "Something went wrong."
                })
                event.target.reset();
                setFormValues(initialFormValues)
            } 
            }else {
                console.log('errors',errors)
            }
    };

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '900px' }}>
            <Grid container sx={{ p: 2 }} alignItems="center" justify="center">
                <Grid item sx={{ p: 2, my:2 }} xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="role-label">I am *</InputLabel>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            value={formValues.role}
                            name="role"
                            label="Role"
                            onChange={handleFormChange}
                            required
                            {...(errors["role"] && { error: true, helperText: errors["role"] })}
                        >
                            <MenuItem value={'publisher'}>Publisher</MenuItem>
                            <MenuItem value={'buyer'}>Buyer</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="country">I have an issue in *</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={formValues.country}
                            name="country"
                            label="I have an issue in"
                            onChange={handleFormChange}
                            required
                            {...(errors["country"] && { error: true, helperText: errors["country"] })}
                        >
                            {CountryList && CountryList.map((country, i) =>
                                <MenuItem key={i} value={country.Code}>{country.Name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6}>
                    <TextField id="name-input"
                        name="name"
                        label="My name is:"
                        type="text"
                        value={formValues.name}
                        onChange={handleFormChange}
                        fullWidth
                        autoComplete='false'
                        required
                        helperText=" "
                        {...(errors["name"] && { error: true, helperText: errors["name"] })} />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6}>
                    <TextField id="email-input"
                        name="email"
                        label="My login email on platform is:"
                        type="text"
                        value={formValues.email}
                        onChange={handleFormChange}
                        fullWidth
                        required
                        helperText=" "
                        {...(errors["email"] && { error: true, helperText: errors["email"] })} />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6}>
                    <TextField id="domain-input"
                        name="domain"
                        label="My domain name on platform is:"
                        placeholder=''
                        type="text"
                        value={formValues.domain}
                        onChange={handleFormChange}
                        fullWidth
                        required
                        helperText=" "
                        {...(errors["domain"] && { error: true, helperText: errors["domain"] })} />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6}>
                    <TextField id="subject-input"
                        name="subject"
                        label="Subject:"
                        type="text"
                        value={formValues.subject}
                        onChange={handleFormChange}
                        fullWidth
                        required
                        helperText=" "
                        {...(errors["subject"] && { error: true, helperText: errors["subject"] })} />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12}>
                    <TextField id="description-input"
                        name="description"
                        label="Description:"
                        type="text"
                        value={formValues.description}
                        onChange={handleFormChange}
                        fullWidth
                        multiline rows={4}
                        required
                        helperText=" "
                        {...(errors["description"] && { error: true, helperText: errors["description"] })} />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12}>
                    <Button fullWidth variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                    <Snackbar open={alertValues.alertOpen} autoHideDuration={20000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertValues.alertType} sx={{ width: '100%' }}>
                            {alertValues.alertMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
                <Backdrop sx={{ color: '#fff', zIndex: '99'}} open={loading}>
                <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        </form>
    );
}

export default SupportFormContent;