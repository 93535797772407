import React from 'react';

function InfoMessage(info) {

 let type = 'error';
 let msg = '';
 let additional_html = '';

 // eslint-disable-next-line default-case
 switch(info.status) {

  case 404:

   msg = 'The page you are trying to access does not exist';

  break;

  case 500:
   

  break;


 }


 return <p>{msg}</p>;
}


// function 


export default InfoMessage;