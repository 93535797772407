import React from 'react';
import {Grid, Paper} from '@mui/material';
import NextButton from 'components/mui/buttons/nextButton';
import { styled } from '@mui/material/styles';

const VideoCardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    max-width:260px;
    height: 400px;
    margin:10px 30px;
    
    & .videocardpaper-container{
        padding: 20px 10px;
        display:flex;
        flex-direction:column;
    }
    & .videocardpaper-pic img{
        width:90%;
    }
    & .videocardpaper-title {
        color: black;
        white-space: pre-line;
        justify-content:center;
        text-align:center;
        padding:10px 0;
    }

    & .videocardpaper-content {
        padding:0 0 20px 0;
        color: var(--pxft-midgrey);
        font-size: var(--pxft-font-s);
        text-align:center;
        white-space: pre-line;
    }
`
const VideoScreenCard=({card})=> {
    return (
        <Grid item xs={12} md={4} lg={4} className='center-align'>
            <VideoCardPaper elevation={3}>
                <div className='center-align videocardpaper-container'>
                    <div className='center-align videocardpaper-pic'>
                          <img loading='lazy' src={'/' + card.picsrc} alt=''/>
                    </div>
                    <h4 className='videocardpaper-title'>{card.title}</h4>
                    <p className='videocardpaper-content'>{card.article}</p>
                    <a href={card.link}><NextButton/></a>
                </div>
            </VideoCardPaper>
        </Grid>

    );
}

export default VideoScreenCard;