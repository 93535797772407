import React from 'react';
import { BlueDivider} from 'components/mui/divider/divider';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import HeaderCard from '../cards/headerCard';

const Header = ({title='',article='',picsrc='',iconsrc='',divider=false,long=false,button=false,buttonContent='',link=''})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='basic-header-container'>
            {divider? <BlueDivider marginBottom='15%' width='100%' marginLeft='0' height='15px' className='left-align'/>:''}
            <div className='center-align'>
                <Grid container className='header-content'>
                    <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={6} md={5.5} lg={4.5} className='center-align'>
                            <HeaderCard title={title} content={article} iconsrc={iconsrc} long={long} button={button} btnText={buttonContent} link={link}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align'>
                            <div>
                                  <img loading='lazy' src={'/' + picsrc} alt=''/>
                            </div>
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </div>
        </WideContainer>
    )
}

export default Header;