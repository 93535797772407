import React from 'react';
import BasicHeader from 'components/mui/containers/basicHeader'

const Header=()=> {
    const header = {
        title:'All in One Advertising Solution',
        article:'Premium campaigns deliver brand messages to site visitors worldwide in display, native, and video formats for the modern web.',
        picsrc:'images/buyers/buyer-header.png',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    return (
        <BasicHeader title={header.title} article={header.article} divider={true} button={false} long={false} picsrc={header.picsrc} iconsrc={header.iconsrc}/>
    );
}

export default Header;