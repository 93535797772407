import React from 'react';

function PlatformTerms() {
    const openTab=(tab)=>{
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" tab-active", "");
        }
        var tabContent=tab+"-content";
        document.getElementById(tabContent).style.display = "block";
        document.getElementById(tab).classList.add("tab-active");
    }
    return (
        <div id="platform-terms-content" className="tabcontent">
        <div className="updated-time">Last Revised: April 27, 2022</div>
        <h3 className="policy-title">Platform Terms</h3>
        <h3>1. General</h3>
        <p>
            a. These Terms of Use (the <strong>“Terms”</strong>) apply to your (<strong>“you”</strong>, <strong>“your”</strong> or <strong>“Customer”</strong>) use of: (i) our website (available at
            www.pixfuture.com, including all of its subdomains; the <strong>“Website”</strong>); (ii) our other products or services, including
            any documentation related thereto; and (iii) any extensions or derivatives
            of our Website or any part thereof (including any APIs, pixels, tags,
            cookies, iFrames, scripts or any other software components necessary to
            enable connection to the Website) which may be embedded on third party
            websites, blogs, social networking platforms, applications or other
            third-party online services (the <strong>“Embedded Items”</strong>)
            (collectively, the <strong>“Platform”</strong>), which are owned or
            operated by Pixfuture Media Inc. (<strong>“us”</strong>,<strong>“we”</strong>, <strong>“our”</strong> or<strong>“Pixfuture”</strong>).
        </p>
        <p>
            b. Please read these Terms carefully; if you access, register or use the
            Platform you agree to be bound by these Terms. You acknowledge that these
            Terms are binding, and you affirm and signify your consent to these terms,
            by either: (i) clicking on a button or checking a checkbox for the
            acceptance of these Terms; or (ii) Registering to, using or accessing the
            Platform, whichever is earlier (the “<strong>Effective Date</strong>”).
        </p>
        <p>
            c. In order to access the Platform you have to register to the Platform and
            create an account (the “<strong>Pixfuture Account</strong>“). When you
            create your Pixfuture Account, you must provide us with up-to-date,
            accurate, and complete information. You may not impersonate any person or
            entity or misrepresent your affiliation with any person or entity.
        </p>
        <p> d. You are responsible for safeguarding your password or log-in credentials.
            You agree that you will not disclose your password to any third party and
            that you will take sole responsibility for any activities or actions under
            your Pixfuture Account, whether you have authorized such activities or
            actions. You hereby release Pixfuture from any and all liability concerning
            such activities or actions and you will immediately notify us of any
            unauthorized use of your Pixfuture Account.</p>
        <h3>2. Definitions</h3>
        <p>a. <strong>“Assets”</strong>shall mean websites controlled and operated by, or otherwise sufficiently licensed to Customer.</p>
        <p>
            b. <strong>“Confidential Information”</strong>
            shall mean any non-public, proprietary, confidential and/or trade secret
            information of a party hereof, whether furnished before or after the date
            the Terms entered into effect, and regardless of the manner in which it is
            furnished, and which given the totality of the circumstances, a reasonable
            person or entity should have reason to believe is proprietary,
            confidential, or competitively sensitive, including, without limitation,
            research and development, formulas, programming, know-how, proprietary
            knowledge, technology and any related documentation, engineering,
            production, operation and any enhancements or modifications relating
            thereto, and other designs, drawings, engineering notebooks, industrial
            models, software and specifications, financial and marketing information,
            business plans, business procedures, clients’ list, business partners or
            other information disclosed by one of the parties hereto (the<strong>“Disclosing Party”</strong>) to the other party (the <strong>“Receiving Party”</strong>) either directly or indirectly in
            writing, orally or by drawings. Confidential Information shall not,
            however, include any information which: (i) was known to the Receiving
            Party or in its possession at the time of disclosure without any
            confidentiality obligation; (ii) becomes publicly known and made generally
            available after disclosure by the Disclosing Party to the Receiving Party
            through no action or inaction of the Receiving Party; (iii) is
            independently developed by the Receiving Party without reliance on or use
            of the Confidential Information or any part thereof and the Receiving Party
            can show written proof of such independent development; (iv) is required to
            be disclosed by applicable law, regulatory authority or a valid court
            order, provided that the Receiving Party shall provide the Disclosing Party
            with reasonable prior written notice of the required disclosure in order
            for the Disclosing Party to obtain a protective order and the disclosure
            shall be limited to the extent expressly required; (v) is approved for
            release by prior written authorization of the Disclosing Party; or (vi) the
            Receiving Party can demonstrate was disclosed by the Disclosing Party to a
            third party without any obligations of confidentiality.
        </p>
        <p>
            c.
            <strong>“Customer Content”</strong>
            means any content or materials uploaded or submitted by Customers (in
            whatever format, including text, photos, images, animations, graphics,
            logos, designs, links, audio, music and video files) via the interactive
            content formats that are included in the Platform, and/or other content
            (such as posts, comments or landing pages), submitted through or on the
            Platform.
        </p>
        <p>
            d.
            <strong>“Data”</strong>
            shall mean all data transmitted to Pixfuture through the Tag (as
            hereinafter defined), including metadata (such as title, author,
            description and other information pertaining to the Embedded Items),
            including any personal and non-personal information pertaining to Users
            collected through their interaction with the Embedded Items, Advertisements
            and/or with the Asset.
        </p>
        <p>
            e.
            <strong>“Insertion Order”</strong>
            shall mean a binding written agreement (including an online registration
            page or online order form) entered into or accepted by Customer (and/or
            Pixfuture, as applicable).
        </p>
        <p>
            f.
            <strong>“Malicious Code”</strong>
            shall mean viruses, worms, malware, spyware, time bombs, Trojan horses and
            other harmful or malicious code, files, scripts, agents or programs,
            including code that is intended to or has the effect of misappropriating,
            hijacking, commandeering, or disrupting access to or use or operation of
            any information, device, hardware, system or network.
        </p>
        <p>
            g.
            <strong>“Objectionable Content”</strong>
            shall mean content that promotes or contains links to content that is (i)
            pornographic, sexually explicit or obscene, (ii) harassing, threatening,
            abusive, inflammatory or racist, (iii) illegal, contrary to public policy
            or that could facilitate the violation of any applicable law, regulation or
            government policy, (iv) libelous or defamatory, (v) infringes upon the
            intellectual property rights of any third party, including copyrights,
            trademarks, trade names, trade secrets or patents of such third party; (vi)
            violates the privacy, publicity, moral or any other right of any third
            party; (vii) offers or disseminates any fraudulent goods, services,
            schemes, investment opportunities, promotions or advice not permitted by
            law; (viii) promotes illegal gambling, the use of illegal substances, or
            the consumption of alcoholic beverages or tobacco-related products; (ix)
            directed or primarily appeals to children under the age of 16; (x) harmful
            to Pixfuture’s or any other party’s systems and networks, including
            Malicious Code, adware, spyware or drive-by download applications; (xi)
            violates any obligation of confidentiality; or (xii) unacceptable in
            Pixfuture’s sole discretion.
        </p>
        <p>
            h.
            <strong>“Proprietary Rights”</strong>
            shall mean all intellectual property rights, including, without limitation:
            (a) all inventions, whether patentable or not, all improvements thereto and
            derivatives thereof, and all patents and patent applications; (b) all
            registered and unregistered: marks, trademarks, service marks, trade names,
            trade dress and associated logos, domain names and registrations and
            applications for registration thereof; (c) all copyrights in copyrightable
            works, all other rights of authorship, including without limitation moral
            rights, and all applications and registrations in connection therewith; (d)
            all trade secrets and Confidential Information; (e) all rights in databases
            and data compilations (including, with respect to Pixfuture, the Data),
            whether or not copyrightable; and (f) all copies and tangible embodiments
            of any or all of the foregoing (in whatever form, including electronic
            media).
        </p>
        <p>
            i.
            <strong>“Tag”</strong>
            shall mean certain code in the Embedded Items and/or the Assets (including
            cookies, script codes, pixels, URL links, tags or any other implementation
            or technological means) that permits Pixfuture and/or its partners or
            service providers to collect data in accordance with Pixfuture’s Privacy
            Policy and these Terms.
        </p>
        <p>
            j.
            <strong>“User” </strong>
            shall mean any individual visiting, interacting with or otherwise using the
            Platform, excluding the Customer.
        </p>
        <h3>3. Eligibility</h3>
        <p>
            a. You may use our Platform only if you can form a binding contract with
            Pixfuture. Any use or access by anyone under the age of 18 is prohibited.
            By accessing this Platform, you certify to us that you are at least 18
            years old.
        </p>
        <p>
            b. You can use the Platform either individually, or on behalf of your employer
            or any other entity which you represent. In case you represent your
            employer or another entity, you hereby represent that: (i) you have full
            legal authority to bind your employer or such entity to these Terms; and
            (ii) after reading and understanding these Terms and upon subscription you
            agree to these Terms on behalf of your employer or the respective entity,
            and these Terms shall bind your employer or such entity. Please note that
            you are deemed as an authorized representative of your employer or an
            entity if you are using your employer’s or an entity’s email address in
            registering into the Platform.
        </p>
        <p>
            c. By creating a Pixfuture Account and registering to the Platform you become,
            either individually or on behalf of your employer or any entity, on behalf
            of whom you created the account, a Pixfuture customer (the “<strong>Customer</strong>“).
        </p>
        <p>
            d. There may be several types of Pixfuture Account users, such as
            administrators, viewers and team members, all of whom are collectively
            referred to herein as the Customer. The features and functionalities
            available to the different Pixfuture Accounts are determined by the
            respective Subscription Plan governing such Pixfuture Account, and the
            privileges of each such users are assigned and determined by the Pixfuture
            Account administrator.
        </p>
        <h3>4. License and Restrictions</h3>
        <p>
            a. Subject to these Terms, Pixfuture grants you a limited, non-exclusive,
            non-assignable, non-transferable, non-sub-licensable and revocable license
            to access and use our Platform, during the Subscription Term (as defined
            below), as set out below and strictly as identified in the Subscription
            Plan (as defined below), and subject to any Usage Limits (as defined below)
            identified in the Subscription Plan:
            <span>
                i. <strong>Pixfuture Account</strong> – Customer may register an Pixfuture
                Account on the Platform that will enable the Customer to create and embed
                content.
            </span>
            <span>
                ii. <strong>Creation of Content &amp; Embedding</strong> – During the Term,
                Customer may utilize the available formats on the Platform to create
                Embedded Items that will be displayed or embedded on the Assets.
            </span>
            <span>
                iii. <strong>Customer Profile Page</strong>. If Customer chooses, create a
                profile page that will be displayed on the Platform and will include
                Customer’s logo, images, links to Customer’s social platforms, and links to
                all items created by Customer through the Platform.
            </span>
            <span>
                iv. <strong>Lead Generation</strong>. Customer may utilize the available
                formats on the Platform to create a form for the purpose of enabling
                end-users to volunteer contact information. It is hereby clarified that
                Customer is solely responsible to ensuring that it has obtained appropriate
                lawful grounds and satisfied applicable notice requirements, in accordance
                with applicable data protection laws and the DPA, with regards to any data
                directly submitted or volunteered by the Users. Customer shall be held
                solely reliable for any modifications, alterations, omissions or changes
                made with respect to any default notice or disclaimer made available by
                Pixfuture in connection with any of the embedded functionalities/features
                of the Platform, including the creation of a lead-generation form.
            </span>
            <span>
                v. <strong>Pixel Management</strong>: The Customer may provide Pixfuture
                with a pixel that will be included in the Embedded Items which will be
                served on the Assets. The pixel will enable the re-targeting of users that
                have completed the Embedded Items Item. Customer shall not use or enable
                the pixel to be used in such a way as to: (i) collect or link to any
                personal data (as this term is defined under applicable laws); (ii)
                compromise, impair or degrade the performance or security of the Platform
                and/or any third party’s device, software, websites and networks. Customer
                shall treat any information that it has collected and which is related to
                the above campaign in accordance with applicable laws and regulations,
                including any data protection laws.
            </span>
        </p>
        <p>
            b. <strong>Usage License</strong>
            . Subject to the terms of this Agreement, the Customer is hereby granted
            with a revocable, limited, non-exclusive, non-assignable, non-transferable,
            non-sub-licensable license to continue to display the Embedded Items the
            Assets (“<strong>Usage License</strong>”), and that the Usage License shall
            survive the termination or expiration of this Agreement, provided however
            that: (i) the Usage License shall continue to be subject to the following
            sections to this Agreement: License and Restrictions; Proprietary Rights;
            Privacy Policy; Termination; Indemnification; Limitations on Liabilities;
            Warranty Disclaimer; Disputes and Miscellaneous, which shall survive the
            termination or expiration of this Agreement; (ii) with the exception of
            Customer Content, the Customer is not granted with any ownership including
            any copyright, trademark and other intellectual property rights to the
            Platform or any portion thereof; (iii) nothing herein shall be construed as
            limiting Pixfuture’s right, at any time, to change, suspend or discontinue
            the Platform (or any part thereof), including the availability of any
            feature or Embedded Item, without notice or liability; (iv) Pixfuture may
            terminate the Usage License of the Getty Content (as applicable) at any
            time and with immediate effect; and (v) Pixfuture may terminate the Usage
            License with immediate effect in the event that Customer has breached any
            of the foregoing terms.
        </p>
        <p>
            c. Customer may not, and may not encourage or assist others to:
            <span>
                i. Publish, distribute, duplicate, sell, re-sell, disclose, “frame”,
                market, license, sublicense, rent, lease, display, provide, transfer or
                make available the Platform or make any other use of Platform (including
                the Embedded Items or any content available therefrom), any portion thereof
                or any content displayed therein, to any third party, except as explicitly
                permitted in these Terms or with our prior express and written permission;
            </span>
            <span>
                ii. Modify, remove, edit, amend, truncate, obscure, bypass or reorder any
                aspect of the Embedded Items, unless Pixfuture makes available the means
                for the foregoing through an explicit embedded feature or functionality
                offered by Pixfuture in the Platform and to the extent enabled by such
                feature or functionality;
            </span>
            <span>
                iii. Download, extract, republish, retransmit, reproduce or make any other
                use of the Embedded Items (including the Customer Content, Pixfuture
                Content or any portion thereof) as a stand-alone file;
            </span>
            <span>
                iv. Decompile, reverse engineer, disassemble, enhance, or otherwise make
                any attempt to discover the source code of, hack, or create derivative
                works from the Platform (including the Embedded Items or any content
                displayed or available therefrom) or any portion thereof.
            </span>
            <span>
                v. Copy, crawl, index, cache or store any information derived by Pixfuture,
                or use any robot, spider, data gathering or extraction tool, or similar
                mechanisms, with respect to the Platform (including the Embedded Items or
                any content available therefrom) and/or any underlying technology;
            </span>
            <span>
                vi. Edit, remove, obscure or alter any credits, watermarks, trade names,
                trademarks, logos, service marks or copyright notices set on Platform
                (including the Embedded Items or any content available therefrom), or
                remove or obscure the attribute/hyperlink to Pixfuture and the Website
                (“Powered by Pixfuture” or any other similar wording);
            </span>
            <span>
                vii. Use the Platform (including the Embedded Items or any content
                available therefrom) and/or any underlying technology to introduce any
                routine, device or other undisclosed feature, including a so-called time
                bomb, virus, software lock, drop dead device, malicious logic, worm, Trojan
                horse, or trap door, that is designed to delete, disable, deactivate,
                interfere with or otherwise harm any software, program, data, device,
                system or service, or which is intended to provide unauthorized access or
                to produce unauthorized modifications.
            </span>
        </p>
        <p>
            d. We may edit, modify, disable access or remove any content from our Platform
            (including from the Embedded Items), for any reason, at any time and
            without prior notice. We also reserve the right to remove or disable access
            to any content or material posted, uploaded, published or incorporated in
            or to the Platform by any Customers that are repeated infringers of our
            policies.
        </p>
        <p>
            e. If you choose to submit comments, ideas or feedback, including testimonials
            about your experience of our products and service, you agree that we are
            free to use them without any restriction or compensation to you.
        </p>
        <h3>5. Subscription Term, Renewal and Fees Payment</h3>
        <p>
            a.
            The Platform is provided on a subscription basis for the term specified in
            Customer’s Order Form, in accordance with the respective subscription plan
            (the “<strong>Subscription Plan</strong>“) purchased under such Order Form
            (the “<strong>Subscription Term</strong>“, and collectively “<strong>Subscription</strong>“).
        </p>
        <p>
            b.
            Our Order Form may be completed and placed in various ways, among which, an
            online form or in-product screens or any other mutually agreed upon offline
            form delivered by Customer to Pixfuture, including via mail, email or any
            other electronic or physical delivery mechanism (the “<strong>Order Form”</strong>). Such Order Form will list, at least, the
            Subscription Plan, Subscription Term and Subscription Fees.
        </p>
        <p>
            c.
            In consideration for the granting of license to use the Platform, Customer
            shall pay Pixfuture the applicable fees per the purchased Subscription
            Plan, as set forth in the applicable Order Form (the “ <strong>Subscription Fees</strong>“). Unless indicated otherwise,
            Subscription Fees are stated in US dollars. Customer hereby authorizes
            Pixfuture, either directly or through our payment processing service, to
            charge such Subscription Fees via Customer’s selected payment method upon
            due date. Unless expressly set forth herein, the Subscription Fees are
            non-cancelable and non-refundable. Pixfuture reserves the right to change
            the Subscription Fees at any time, upon notice to Customer if such change
            may affect Customer’s existing subscription upon renewal. In the event of
            failure to collect the Subscription Fees owed by Customer, Pixfuture may,
            in its sole discretion, retry to collect at a later time, and/or suspend or
            terminate the Pixfuture Account, without notice.
        </p>
        <p>
            d.
            The Subscription Fees are exclusive of any and all taxes (including without
            limitation, value, added taxes, sales tax, use tax, excise, goods and
            services tax, etc.), levies or duties, which may be imposed in respect of
            these Terms of Service and the subscription to the Platform (the “<strong>Taxes</strong>“), except for Taxes imposed on our income.
        </p>
        <p>
            e.
            During the Subscription Term, Customer may upgrade its Subscription Plan by
            either: (i) upgrading to a higher type of Subscription Plan; (ii) adding
            add-on features and functionalities; or (iii) upgrading to a longer
            Subscription Term (collectively “<strong>Subscription Upgrades</strong>“).
            Upon a Subscription Upgrade, Customer will be billed for the applicable
            increased amount of Subscription Fees, at the then current rates, either:
            (1) prorated for the remainder of the then current Subscription Term; or
            (2) whenever the Subscription Term is being restarted due to the
            Subscription Upgrade, then the Subscription Fees already paid by Customer
            will be reduced from the new upgraded Subscription Fees, and the difference
            shall be due and payable by Customer upon the date on which the
            Subscription Upgrade was made.
        </p>
        <p>
            f. We shall have the right, at our sole discretion, to offer subscription at
            different pricing and/or impose additional restrictions as for the upload,
            storage download and use of the Platform, including, without limitation,
            restriction on third party services, network traffic and bandwidth, size
            and/or length of content, quality and/or format of content, sources of
            content, volume of download time etc., if we believe that Customer have
            misused the Platform or otherwise used it in excessive manner compared to
            the anticipated standard use (set at our sole discretion).
        </p>
        <p>
            g. As part of registering, or submitting billing information, to the Platform,
            Customer agrees to provide us with updated, accurate and complete billing
            information, and authorize Pixfuture (either directly or through our
            affiliates, subsidiaries or other third parties) to charge, request and
            collect payment (or otherwise charge, refund or take any other billing
            actions) from Customer’s payment method or designated banking account, and
            to make any inquiries that we may consider necessary to validate Customer’s
            designated payment account or financial information, in order to ensure
            prompt payment, including for the purpose of receiving updated payment
            details from Customer’s credit card company or banking account (e.g.,
            updated expiry date or card number as may be provided to us by Customer’s
            credit card company).
        </p>
        <p>
            h. In order to ensure that Customer will not experience any interruption or
            loss of services, Customer’s Subscription includes an automatic renewal
            option by default, according to which, unless Customer disables the
            auto-renewal option or cancels its subscription prior to its expiration,
            the subscription will automatically renew upon the end of the then
            applicable Subscription Term, for a renewal period equal to the greater of
            (i) a minimum of one (1) year or (ii) equal in time to the original
            Subscription Term (excluding extended periods) – and, unless otherwise
            notified to Customer, at the same price (subject to applicable Tax changes
            and excluding any discount or other promotional offer provided for the
            first Subscription Term). Accordingly, unless either Customer or Pixfuture
            cancel the Subscription prior to its expiration, we will attempt to
            automatically charge Customer the applicable Subscription Fees upon or
            immediately prior to the expiration of the then applicable Subscription
            Term. If Customer wishes to avoid such auto-renewal, Customer shall cancel
            its Subscription at least sixty (60) days prior to its expiration, at any
            time through the Account settings or by contacting us. Except as expressly
            set forth in these Terms, in case a Customer cancels its Subscription,
            during a Subscription Term, the Subscription will not renew for an
            additional period, but Customer will not be refunded or credited for any
            unused period within the Subscription Term.
        </p>
        <p>
            i. If any invoiced amount is not received by Pixfuture by the due date, then
            without limiting our rights or remedies, those charges may accrue late
            interest at the rate of 1.5% of the outstanding balance per month, or the
            maximum rate permitted by law, whichever is lower.
        </p>
        <h3>6. Billing and Refund</h3>
        <p>
            a.
            Your Pixfuture Account is being charged for the annual or monthly
            subscription plan you’ve chosen. The subscription fee (plus any applicable
            taxes and other charges) will be charged to you at the beginning of the
            paying portion of your subscription.
        </p>
        <p>
            b.
            Subscription fees are billed in advance for the subsequent year, as
            applicable.
        </p>
        <p>
            c.
            If you cancel your Subscription, your cancellation takes effect on your
            next billing cycle. This means we won’t be able to refund you for any
            unused portion of your services.
        </p>
        <h3>7. Trial Service</h3>
        <p>
            a. We may grant a license to use a portion of our Platform, and subject to
            certain Usage Limits (as defined below), on a free, no-obligation trial
            version (“<strong>Trial Service</strong>”), until the earlier of (a) the
            end of the trial period for which you registered to use the Platform (as
            communicated to you, within the Platform or in an Order Form), or (b) the
            start date of any Subscription Plan ordered by you, or (c) termination by
            us in our sole discretion.
        </p>
        <p>
            b. We reserve the right to modify, cancel and/or limit this Trial Service at
            any time and without liability or explanation to you.
        </p>
        <h3>8. Usage Limits</h3>
        <p>
            a.
            Both the Trial Service and the Subscription Plans are subject to certain
            usage limitations or limits (including, for example, the number of Embedded
            Items you can create, amount of requests to demand partners’ tags, number
            of users you can generate, data and reports you can obtain etc.;
            collectively “<strong>Usage Limit(s)</strong>”).
        </p>
        <p>
            b.
            The Usage Limits shall be as communicated to you, within the Platform or in
            an Order Form, and strictly as measured or calculated by Pixfuture.
        </p>
        <p>
            c.
            The Customer hereby acknowledges that by exceeding a Usage Limits,
            Pixfuture may work with you to seek to reduce your usage so that it
            conforms to that limit. If, notwithstanding our efforts, you are unable or
            unwilling to abide by a contractual Usage Limit, you will execute an Order
            Form promptly upon our request, and/or pay any invoice for excess usage in
            accordance with the terms herein.
        </p>
        <p>
            d.
            In the event customer exceeds the usage limit of own demand requests in any
            subscription plan, the overage charge shall be applied and payable in
            accordance with payment schedule. Overage fees are determined by CPM and
            based on the number of requests allowed in each plan. Overage fees in each
            plan are as follows:
            <span>
                1) Monetizer plan is limited to 3,000,000 own demand tags requests (&#8220;3rd party demand&#8221;). For all publisher 3rd party demands displayed (filled impressions) on the properties, Publisher will pay Pixfuture a $0.20 CPM ad serving fee (the “Ad Serving Fee”) plus for each request to the 3rd party demand is delivered, Publisher will pay Pixfuture a $0.000007 CPM infrastructure and analytics fee (the “I&#038;A Fee”);
            </span>
            <span>
                2) Starter plan after 5 million player requests you pay 0.0922 CPM for additional player requests;
            </span>
            <span>
                3) Business plan after 10 million player requests you pay 0.0782 CPM for additional player requests;
            </span>
            <span>
                4) Enterprise plan: TBD per quote.
            </span>
        </p>
        <p>
            e. Impressions filled by the pixfuture AuctionX platform are subject to an
            offset from requests limits in each paid plan. “Monetizer” plan is not
            being charged for player requests and only billed for own demand usage in
            accordance with section 8 d. of this agreement.
        </p>
        <h3>9. Proprietary Rights</h3>
        <p>
            a.
            All Proprietary Rights, titles and interests in and to the Platform,
            including compilations, digital conversions, magnetic translations,
            software and other materials related to the Platform are and shall at all
            times remain the sole and exclusive property of Pixfuture, and are
            protected by applicable intellectual property laws and treaties.
        </p>
        <p>
            b.
            All materials and contents (other than the Customer Content) displayed,
            made available, included, incorporated, uploaded, posted, published,
            contributed, performed or used on the Platform, which were created by
            Pixfuture (together <strong>“Pixfuture Content”</strong>), are:
            <span>
                i. The sole property of Pixfuture and/or third parties, as applicable
                (including, without limitation, with respect to content from Getty Images
                as further detailed below);
            </span>
            <span>
                ii. Protected by applicable intellectual property rights, including
                copyright laws; and
            </span>
            <span>
                iii. May only be used in accordance with these Terms.
            </span>
        </p>
        <p>
            c.
            All data related to the Platform or collected through the Platform (with
            the exception of data collected through the Embedded Items) shall be the
            sole property of Pixfuture. All trademarks, service marks, trade names,
            logos, and any other proprietary designations of Pixfuture used herein, are
            trademarks or registered trademarks of Pixfuture Media Inc. Any other
            trademarks, service marks, trade names, logos and any other proprietary
            designations are the trademarks or registered trademarks of their
            respective parties. In addition, the arrangement and compilation of the
            Website (including, without limitation, the “look &amp; feel”) are the
            exclusive property of Pixfuture and are protected by copyright laws.
        </p>
        <p>
            d.
            All data related to and collected through the use of Embedded Items
            (including through the use of tags, pixels, cookies, scripts or any other
            software components) shall be the sole property of Customer. Customer shall
            grant Pixfuture an irrevocable, non-exclusive, worldwide, transferable,
            royalty-free, sub-licensable license to use the data in aggregated and
            de-identified form for Pixfuture legitimate business purposes.
        </p>
        <p>
            e.
            All rights not expressly granted to you in these Terms are reserved by
            Pixfuture.
        </p>
        <p>
            f.
            The Platform contains Customer Content and Pixfuture Content. Such content
            is protected by copyrights, trademarks, service marks, patents, trade
            secrets or other Proprietary Rights and laws. You shall abide by and
            maintain all copyright notices, information, and restrictions contained in
            any content accessed through the Platform.
        </p>
        <h3>10. Reporting Abuse</h3>
        <p>
            a.
            If you feel that any person or content is abusing our policies, or
            otherwise violating your rights, please contact us at:
            <a href="https://support.pixfuture.com/tickets/index.php?a=add">
                <strong> <u>Support</u>. </strong>
            </a>
            Be sure to identify the specific content and the reason you feel it should
            be removed.
        </p>
        <h3>11. Copyright Policy</h3>
        <p>
            a.
            In accordance with the Digital Millennium Copyright Act of 1998, we will
            respond expeditiously to claims of copyright infringement committed using
            the Platform that are reported to Pixfuture’s Designated Copyright Agent,
            identified in the sample notice below.
        </p>
        <p>
            b.
            If you are a copyright owner, or are authorized to act on behalf of one,
            and you believe in good faith that any material or content made available
            on or through the Platform has been used or exploited in a manner that
            infringes or violates any work or other intellectual property you own or
            control, please report the alleged copyright infringements taking place on
            or through the Platform by completing the following DMCA Notice of Alleged
            Infringement (the “<strong>Notice</strong>“) and delivering it to
            Pixfuture’s Designated Copyright Agent. Upon receipt of the Notice as
            described below, we will take whatever action we deem appropriate, in our
            sole discretion, including removal of the challenged material from the
            Platform.
        </p>
        <p>
            c.
            You can submit a Notice by:
            <span>
                i. Filling in and submitting our online form; or
            </span>
            <span>
                ii. Providing the following information to our Designated Copyright Agent:
            </span>
        </p>
        <p>
            a. Identify the copyrighted work that you claim has been infringed, or – if
            multiple copyrighted works are covered by this Notice – you may provide a
            representative list of the copyrighted works that you claim have been
            infringed.
        </p>
        <p>
            b. Identify:
            <span>
                i. the material that you claim is infringing (or to be the subject of
                infringing activity) and that is to be removed or access to which is to be
                disabled, and information reasonably sufficient to permit us to locate the
                material, including at a minimum, if applicable, the URL of the link(s)
                shown on the Platform where such material may be found; and
            </span>
            <span>
                ii. the reference or link to the material or activity that you claim to be
                infringing, that is to be removed or access to which is to be disabled, and
                information reasonably sufficient to permit us to locate that reference or
                link, including at a minimum, if applicable, the URL of the link(s) shown
                on the Platform where such reference or link may be found.
            </span>
        </p>
        <p>
            c.
            Provide your contact details – mailing address, telephone number, and, if
            available, email address.
        </p>
        <p>
            d.
            Include both of the following statements in the body of the Notice: “I
            hereby state that I have a good faith belief that the disputed use of the
            copyrighted material or reference or link to such material is not
            authorized by the copyright owner, its agent, or the law (e.g., as a fair
            use).” “I hereby state that the information in this Notice is accurate and,
            under penalty of perjury, that I am the owner, or authorized to act on
            behalf of the owner, of the copyright or of an exclusive right under the
            copyright that is allegedly infringed.”
        </p>
        <p>
            e.
            Provide your full legal name and your electronic or physical signature.
            Deliver this Notice, with all items completed, to Pixfuture’s Designated
            Copyright Agent:
            <span>
                i.
                Via email ticket system: 
                <a href="https://support.pixfuture.com/tickets/index.php?a=add"> <u>Support</u></a>
            </span>
            <span>
                ii.
                Via postal address:
            </span>
            <span>
                Attn: Designated Copyright Agent
            </span>
            <span>
                7191 Yonge St, Suite 812,
            </span>
            <span>
                Toronto, ON, L3T0C4
            </span>
        </p>
        <h3>12. Privacy Policy</h3>
        <p>
            To demonstrate our commitment to your privacy, we have developed our 
            <a href='/terms-and-conditions#privacy-policy' rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
                <strong> <u>Privacy Policy</u> </strong>
            </a>
            which outlines our practices with respect to the collection, use and
            disclosure of information relating to your activities on the Platform. By
            using our Platform, you acknowledge that you have reviewed our 
            <a href='/terms-and-conditions#privacy-policy' rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
                <strong> <u>Privacy Policy</u> </strong>
            </a>
            and agree to be bound by its terms and conditions.
        </p>
        <h3>13. Links or Access to Third-Party Content</h3>
        <p>
            Our Platform may contain links to third party websites, apps, services,
            offerings, materials, products, recommendations, or other events or
            activities (<strong>“Third-Party Content”</strong>) that are not owned or
            controlled by us (including, without limitation, Getty Content and any
            materials sourced from Giphy.com). We do not control, endorse or assume any
            responsibility for any such Third-Party Content. If you access any
            Third-Party Content from our Platform, you do so at your own risk and you
            agree that we will have no liability arising from your use of or access to
            any Third-Party Content (“<strong>Third-Party Disclaimer</strong>”).
        </p>
        <h3>14. Third-Party Software</h3>
        <p>
            a.
            Portions of the Platform may include software that we license from third
            parties, which may include open source software or related components ( <strong>“Third Party Software”</strong>) and is subject to the terms
            imposed by third party licensors. We do not make any warranty with respect
            to Third Party Software. You agree that your use of the Platform is subject
            to the applicable Third Party Software referred to therein, as updated from
            time to time.
        </p>
        <p>
            b.
            Pixfuture may hire other third parties to perform certain business-related
            functions such as processing payments on its behalf. Pixfuture’s payment
            processing partner may request that the Customer will provide certain
            personal data (e.g., a valid government issued ID, your legal name,
            address, and date of birth) for the purpose of making payment through its
            financial institutions and complying with any federal and state laws and
            regulations. They may also communicate directly with the Customer regarding
            any issues with a payment. Any information provided to such third party
            will be confidential and only be used for the intended purpose.
        </p>
        <h3>15. Grant of License</h3>
        <p>
            a.
            When you create, publish, post, upload or contribute Customer Content to
            our Platform, such Customer Content belongs to you, and you agree to grant
            Pixfuture a revocable, non-exclusive, worldwide, transferable,
            royalty-free, sub-licensable license to:
        </p>
        <p>
            b.
            use, copy, store, publish, display, broadcast, make publicly available,
            exploit, link, distribute, reproduce, download, translate, abridge, amend,
            modify and adapt, create derivative works of and otherwise use the Customer
            Content, and to allow Pixfuture to sub-license others to do so, solely for
            the purpose of providing the Platform to the Customer; including to
            identify and credit you as the creator of such Customer Content and use any
            information that you have provided during your registration to our Platform
            (for example your name, photograph and other information) in connection
            with the publication, distribution, advertising or promotion of the
            Customer Content, and to allow others to do so for any purpose. However,
            this does not require or obligate us to provide such credit, if any, to
            you, and we reserve the right to omit such credit or attribution at any
            time and in our sole discretion.
        </p>
        <p>
            c.
            If you choose to make your Customer Content publicly available on our
            Platform, you hereby grant any person (including non-registered Users of
            our Platform) a non-exclusive, perpetual license to view, use or interact
            with your Customer Content (for example by voting, ranking or submitting
            commentary), and to allow others to share and embed your Customer Content
        </p>
        <p>
            d.
            Notwithstanding the foregoing, nothing herein shall restrict Pixfuture,
            during and after the termination of your Pixfuture Account or your use of
            the Platform, to use aggregated and/or de-identified data derived from your
            Customer Content and any residual backup copies of your Content made in the
            ordinary course of Pixfuture’s business, for its internal business
            purposes, including without limitation product improvement and marketing
            purposes. This shall not affect or derogate from your ownership rights over
            the Customer Content, as provided in these Terms.
        </p>
        <p>
            e.
            This license also extends to any trusted third parties we work with to the
            extent necessary to provide the Platform to you.
        </p>
        <h3>16. Customer Representations</h3>
        <p>
            a.
            When you create, upload or contribute Customer Content to our Platform, you
            represent to us that:
            <span>
                i. Any information Customer provides Pixfuture (including contact
                information, payment information or any reports that were or ought to be
                provided by Customer to Pixfuture) will at all times be complete and
                accurate, and will be maintained up-to- date at all times.
            </span>
            <span>
                ii. The Customer Content does not violate these Terms or contain any
                Objectionable Content.
            </span>
            <span>
                iii. The Customer will not embed or promote the Embedded Items in
                association with any material or content (including any content displayed,
                made available on or linked from the Asset) which is, or which may be
                reasonably considered by Pixfuture (at its sole discretion) as
                Objectionable Content.
            </span>
            <span>
                iv. The Customer owns and operates the Asset, or is otherwise properly
                licensed to embed the Embedded Items and the Tag on the Asset, and the
                Customer is solely responsible to adopt any necessary measures to limit or
                restrict access to the Embedded Items, as required by applicable law and/or
                industry best practices, including with respect to the protection of minors
                or children;
            </span>
            <span>
                v. The Customer shall not, directly or indirectly, infringe upon the rights
                of any third party, including proprietary, privacy or publicity rights, or
                any applicable laws or regulations;
            </span>
            <span>
                vi. You have obtained all rights (including intellectual property rights)
                and approvals, necessary to grant Pixfuture and other Users the licenses
                provided herein in connection with the Customer Content, and that your use
                of the Customer Content doesn’t conflict with any permits or licenses you
                may have granted to others.
            </span>
            <span>
                vii. You are solely responsible for the Customer Content, and assume all
                risks associated with it, including any person’s reliance on its accuracy
                or claims that might stem from such Customer Content, as well as any
                liability, cost, expense or loss to Pixfuture or to any third party
                resulting from such Customer Content.
            </span>
            <span>
                viii. You agree to indemnify and hold Pixfuture or anyone on its behalf
                (including its and its subsidiaries’ shareholders, directors, officers,
                employees, service providers, partners, and agents) harmless from any
                liability, cost, damage, and expense (including reasonable legal fees)
                caused or resulting from the Customer Content including any claim
                concerning violation or infringement of any proprietary rights, including
                without limitation, with respect to your use of the Getty Content in breach
                of these Terms.
            </span>
            <span>
                ix. the Customer will not
            </span>
            <span>
                a. Remove, alter, disable or circumvent any Tag or otherwise interfere with
                Pixfuture’s ability to monitor User interaction in accordance with these
                Terms;
            </span>
            <span>
                b. Engage in any action or practice that disparages or devalues Pixfuture,
                the Embedded Items, the Platform, or the reliability, reputation or
                goodwill of any of the foregoing, or engage in any action or practice that
                might impede the performance reliability or quality of the Platform.
            </span>
            <span>
                c. Place Embedded Items on Assets that utilize “zombie cookies” and/or
                “super-cookies”, and/or otherwise circumvent the User’s privacy collection
                preferences.
            </span>
        </p>
        <p>
            b.
            You understand that although we exercise reasonable efforts to preserve the
            materials stored on the Platform, you are solely responsible for creating
            back-ups of your Customer Content, and we shall not be responsible or
            liable in any way for the failure to preserve your Customer Content.
        </p>
        <p>
            c.
            Without derogating from Customer’s representations and warranties under
            these Terms, Customer agrees that Pixfuture or anyone delegated on its
            behalf shall have the right, but not the obligation, to monitor Customer’s
            compliance with the Terms, in any form of technology chosen by Pixfuture in
            its sole discretion. The customer shall have no claim or demand against
            Pixfuture with respect to such monitoring.
        </p>
        <p>
            d.
            Pixfuture is not responsible for any Objectionable Content or information
            made available on or linked from any Asset or from the Embedded Items.
        </p>
        <p>
            e.
            Customer confirms that it is aware that some of the content made available
            through the Website or Platform may be user-generated content (“ <strong>UGC</strong>“) and that the sole responsibility for UGC lies with
            its various respective creators and/or uploaders. Pixfuture does not
            warrant and cannot guarantee that the UGC will not be infringing in any
            way. It is hereby clarified that in case of infringement or other content
            issues that may arise with the UGC, the Customer’s sole remedy with respect
            to such content shall be to submit a written complaint to Pixfuture, which
            will review such complaint and, if applicable, remove such UGC in
            accordance with our Terms. Pixfuture does not monitor, pre-screen or
            approve UGC (including any translations) made available on the Website, and
            Pixfuture cannot guarantee its quality, accuracy, or fitness for any
            purpose.
        </p>
        <h3>17. Getty Content</h3>
        <p>
            a.
            We may (but are not obligated to) allow you access to images, graphics,
            stills, designs, text, audios, videos and other material which was licensed
            to Pixfuture by Getty Images (<strong>“Getty Content”</strong>), in
            connection with the creation of Embedded Items. Customers agree to abide by
            any additional terms or restrictions applicable to the use of Getty
            Content, as may be conveyed to you from time to time, including the Getty
            Images Premium Access Standard Terms and Conditions. You are fully
            responsible and assume all liability in connection with your use and
            embedding of Getty Content.
        </p>
        <p>
            b.
            You agree that your use of Getty Content may give rise to third party
            rights (such as publicity or privacy rights), and that Pixfuture does not
            provide any clearances and/or release(s) that may be required as a result
            of your use of Getty Content, including from any representative guild,
            union, professional organization, or other authorized representative, and
            you acknowledge that you shall be solely responsible for obtaining any and
            all necessary licenses, releases or waivers permitting you to use the name,
            likeness or other attribute of any individual or entity appearing in the
            Getty Content. You acknowledge that nothing herein shall be construed as
            granting ownership or copyright in the Getty Content to you. Pixfuture
            reserves the right, at any time and in its sole discretion, to restrict or
            block your access to or use of Getty Content, and to remove any Getty
            Content, including from Pixfuture Content which was embedded on third party
            websites, social networking platforms or applications.
        </p>
        <p>
            c.
            When you incorporate Getty Content in an Embedded Item that you create, you
            will:
            <span>
                i. Include the following credit line adjacent to the Getty Content:
                “Contributor or Partner/Getty Images”, to the extent technically possible;
            </span>
            <span>
                ii. Not use Getty Content in connection with Embedded Items which are
                designed to induce or involve the sale, license or other distribution of
                “on demand” products (including postcards, mugs, t-shirts, calendars,
                posters, electronic greeting cards, wallpaper or ringtones for mobile
                telephones and other items);
            </span>
            <span>
                iii. Not use any Getty Content that contain NBA or WNBA images;
            </span>
            <span>
                iv. If the Getty Content features a model or property in connection with a
                subject that would be unflattering or controversial to a reasonable person,
                you must accompany such use with a statement that indicates that: (i) the
                Getty Content is being used for illustrative purposes only; and (ii) any
                person depicted in the Getty Content, if any, is a model;
            </span>
            <span>
                v. Not download, extract, republish, retransmit, reproduce or make any
                other use of Getty Content as a stand-alone file (meaning, separate of the
                Customer Content to which it is incorporated, such as for a screensaver),
                or make any derivate use of the Getty Content, except to the extent
                strictly enabled through the embedded and standard functionality of the
                Platform, or make Getty Content accessible in any other physical or
                electronic medium (such as fine art prints);
            </span>
            <span>
                vi. Use the Getty Content and/or any underlying technology other than in
                compliance with all applicable federal, state, and local laws; and
            </span>
            <span>
                vii. Cease use of Getty Content, and delete or remove any Getty Content
                from your Embedded Items and/or servers, upon notice from Pixfuture.
            </span>
        </p>
        <h3>18. Social Media</h3>
        <p>
            a.
            The Customer acknowledges and agrees that creating or submitting Embedded
            Items which include videos uploaded from or hosted on YouTube (through
            YouTube API; hereinafter “<strong>YouTube Content</strong>“) shall be
            performed solely in a manner consistent with YouTube’s <a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer"><u>Terms of Service</u></a>,
            YouTube’s <a href="https://www.youtube.com/howyoutubeworks/policies/community-guidelines/" target="_blank" rel="noreferrer"><u>Community Guidelines</u></a>,
            YouTube’s <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">
                 <u>Privacy Policy</u> 
            </a> and YouTube’s <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service?hl=en" target="_blank" rel="noreferrer">
                 <u>API Terms</u> 
            </a>
            .
        </p>
        <p>
            <strong>Instagram/Meta/Facebook</strong>
        </p>
        <p>
            b.
            Publisher acknowledges and agrees that embedding Channels Platform which
            includes videos/pictures/posts uploaded from or hosted on
            Instagram/Meta/Facebook (through Meta API) shall be performed solely in a
            manner consistent with Instagram/Meta/Facebook’s <a href="https://www.facebook.com/legal/terms"><u>Terms of Service</u></a>. <strong> </strong>
        </p>
        <h3>19. Pixfuture Analytics</h3>
        <p>
            a.
            Pixfuture may provide Customer (in certain Subscription Plans according to
            the Order Form) access to its proprietary Pixfuture Analytics platform that
            will allow the Customer to manage and view Embedded Items’ analytics and
            metrics (such as unique views, clicks, end- user engagements, etc.) (“ <strong>Pixfuture Analytics</strong>“). Notwithstanding the foregoing, you
            acknowledge that any information included in the Pixfuture Analytics is
            subject to performance and traffic quality. Such information is not
            guaranteed to be accurate and is subject to adjustments and modifications
            resulting from, among other things, a final accounting, deductions, traffic
            performance or bad referrals, all as determined by Pixfuture’s sole and
            absolute discretion. In no event and for no reason shall Pixfuture be
            liable for any unavailability or inaccuracy, temporary or otherwise, of
            Pixfuture Analytics.
        </p>
        <h3>20. Data Protection</h3>
        <p>
            a.
            Pixfuture and Customer shall comply with the Pixfuture <a href='/terms-and-conditions#dpa-terms' rel="noreferrer" onClick={() =>openTab('dpa-terms')}>
                <u>Data Protection Addendum</u>
            </a>
            (“<strong>DPA</strong>”), which governs the Processing of Personal Data (as
            both terms are defined in the DPA), on Customer’s behalf, where such
            Personal Data is subject to the General Data Protection Regulation 2016/679
            (the “<strong>GDPR</strong>”).
        </p>
        <p>
            b.
            No Sensitive Data. Customer shall not submit to the Platform any data that
            is protected under special legislation and requires unique treatment,
            including, without limitations, (i) categories of data enumerated in
            Article 9(1) of the GDPR, or any similar legislation or regulation in other
            jurisdiction; (ii) any protected health information subject to the Health
            Insurance Portability and Accountability Act, as amended and supplemented,
            or any similar legislation in other jurisdiction; and (iii) credit, debit
            or other payment card data subject to PCI DSS or any other credit card
            schemes.
        </p>
        <p>
            c.
            Customer shall provide its end users with a notice and obtain their
            consent, as required by applicable laws and regulations in connection with
            the collection, use and disclosure of Data through the use or access to the
            Embedded Items and storing and accessing of cookies or other information on
            the end users’ devices, including with respect to the use of any
            technological methods for the purpose of collecting such Data (including,
            without limitation, the Tag and/or any cookies), and including with respect
            to displaying interest-based or targeted content.
        </p>
        <p>
            d.
            Without limiting from the foregoing, Customer shall ensure that end users
            are provided with a notice that third parties may collect, use and share
            Data in accordance with their respective privacy policy through an adequate
            privacy policy which will be presented to end-users, which shall address
            the Customer’s practices with respect to the collection, use, and
            disclosure of any User Data obtained from the end-user (including through
            the Tag). Such privacy policy shall be easily accessible by the end-users
            prior to any collection, use, or disclosure of Data.
        </p>
        <p>
            e.
            Pixfuture may enable its partners (including its Customers) to use a data
            management platform that will enable them to manage their audience
            information and Data, pursuant to the terms of an Insertion Order or any
            other form of agreement entered between Customer and Pixfuture. You
            acknowledge that you are solely responsible and assume all liability in
            connection with your use of any audience information and Data (whether
            through the data management platform or otherwise), including with respect
            to compliance with any applicable data protection laws or regulations.
        </p>
        <p>
            f.
            Without limiting from the foregoing, Customer shall collect, use or
            disclose Data in accordance with any applicable laws and with its privacy
            policy, and shall allow Pixfuture to provide any link or notice it deems
            necessary for the provision of any disclosure in or around the Embedded
            Items.
        </p>
        <p>
            g.
            Anonymous Information. Notwithstanding any other provision of these Terms,
            we may collect, use and publish Anonymous Information (defined below)
            relating to your use of the Platform, and disclose it for the purpose of
            providing, improving and publicizing our products and services, including
            the Platform, and for other business purposes. “ <strong>Anonymous Information</strong>” means information which does not
            enable the identification of an individual, such as aggregated and
            analytics information. Pixfuture owns all Anonymous Information collected
            or obtained by Pixfuture.
        </p>
        <h3>21. Customer Branded Content</h3>
        <p>
            <strong>a.</strong>
            In the event that Pixfuture and the Customer have entered into a separate
            and valid Insertion Order or any other form of agreement, pursuant to
            which: (a) Pixfuture shall create Embedded Items, whether on Customer’s
            behalf, or whether promoting another third party product, brand or sponsor
            (<strong>“Branded Item”</strong>); and/or (b) Customer may create Embedded
            Items promoting a third-party product, brand or sponsor (collectively, <strong>“Branded Content”</strong>) – then in addition to the terms
            contained therein, the Customer represents that it is solely responsible to
            provide any notice or disclosure with respect to the display or promotion
            of the Branded Content, as required under applicable laws, including with
            respect to notifying the users with regards to the commercial nature of the
            Branded Content.
        </p>
        <h3>22. Reports</h3>
        <p>
            a.
            In the event of Pixfuture provides reports to the Customer, under an
            Insertion Order or any other form of the agreement entered between Customer
            and Pixfuture (<strong>“Reports”</strong>), such Reports shall be solely
            based on Pixfuture’s tracking systems and will be considered as final and
            non-appealable proof for the purpose of computing the fees due and payable
            to any party pursuant to the terms of the Insertion Order or applicable
            agreement. The customer acknowledges that no other measurements or
            statistics of any kind will be accepted by Pixfuture or have any effect on
            the fee due under that Insertion Order or applicable agreement.
        </p>
        <p>
            b.
            Notwithstanding the foregoing, Customer acknowledges that any information
            included in Pixfuture Analytics and in the Reports is subject to Customer’s
            traffic quality, performance and compliance with the terms and conditions
            of these Terms and are thereby subject to adjustments or modifications by
            Pixfuture at all times. The information included in Pixfuture Analytics and
            the Reports is not guaranteed to be accurate and is subject to adjustments
            resulting from, among other things, a final accounting, deductions, traffic
            performance or bad referrals, all as determined by Pixfuture’s sole and
            absolute discretion. In no event and for no reason shall Pixfuture be
            liable for any unavailability or inaccuracy, temporary or otherwise, of
            Pixfuture Analytics and/or of the Reports.
        </p>
        <h3>23. Confidentiality</h3>
        <p>
            a.
            During the Subscription Term, and for a period of three (3) years
            thereafter, each party agrees that it will not disclose or use the
            Confidential Information of the Disclosing Party without the Disclosing
            Party’s prior written consent. During such period, each party agrees that
            it will take reasonable steps, at least substantially equivalent to the
            steps it takes to protect its own Confidential Information, to prevent the
            disclosure of the Disclosing Party’s Confidential Information other than to
            its employees, affiliates, subsidiaries or other agents who must have
            access to such Confidential Information for such party to perform its
            obligations or exercise its rights hereunder, who will each agree to comply
            with this section.
        </p>
        <p>
            b.
            Upon termination or expiration of the subscription, Receiving Party shall,
            upon request, return to the Disclosing Party or destroy (at the Disclosing
            Party’s discretion) all materials containing Confidential Information.
        </p>
        <h3>24. Term and Termination</h3>
        <p>
            a.
            These Terms are in full force and effect, commencing upon the Effective
            Date, until the end of the Subscription Plan, either paid or unpaid, unless
            terminated otherwise in accordance with these Terms.
        </p>
        <p>
            b.
            Pixfuture may terminate or suspend the licenses provided herein at any time
            and cease providing you access to our Platform in its sole discretion (“ <strong>Termination for Convenience</strong>“) or if: (i) you violate these
            Terms; (ii) infringe or accused of infringing the intellectual property or
            other rights of others; (iii) violate any terms, restrictions or
            limitations specified in any additional agreement(s) you may enter into
            with Pixfuture or that may apply to your specific use of the Platform; or
            (iv) otherwise create risk or possible legal exposure for us(“ <strong>Termination for Cause</strong>“).
        </p>
        <p>
            c.
            Termination for Cause will be without any compensation or liability of
            Pixfuture and shall not relieve Customer of any obligations arising or
            accruing prior to such termination or limit any liability which Customer
            otherwise may have to Pixfuture, including without limitation any
            indemnification obligations contained herein.
        </p>
        <p>
            d.
            Termination for Convenience will be subject to a refund that will be
            calculated based on a prorated basis per the portion of the remaining
            Subscription Term and the Subscription Fees.
        </p>
        <p>
            e.
            Customer may terminate its subscription to the Platform by canceling the
            Subscription or deleting the Pixfuture Account. Such termination shall not
            derogate from the Customer’s obligation to pay applicable Subscription Fees
            until the end of the then-current Subscription Term without any refund for
            pre-paid Subscription Fees.
        </p>
        <p>
            f.
            The following sections will survive termination: License and Restrictions,
            Proprietary Rights, Privacy Policy, Termination, Indemnification,
            Limitations on Liabilities, Warranty Disclaimer, Disputes, and
            Miscellaneous.
        </p>
        <h3>25. Indemnification</h3>
        <p>
            a.
            If anyone brings a claim against us related to Customer’s use of Platform,
            Customer will indemnify and hold us (including our and our subsidiaries’
            shareholders, directors, officers, employees, partners and agents) harmless
            from and against all damages, losses, costs and expenses of any kind
            (including reasonable legal fees and costs) related to such claim.
        </p>
        <p>
            b.
            Customer shall indemnify and hold Pixfuture (including its and its
            subsidiaries’ shareholders, directors, officers, employees, partners,
            advertisers and agents) harmless from and against all damages, losses,
            costs and expenses of any kind (including reasonable legal fees and costs,
            collectively “<strong>Losses</strong>“) resulting from, arising out of, or
            related to any claim against Pixfuture related to: (i) the Asset or any
            content made available thereto; (ii) the Customer’s actions or omissions
            (including any claim concerning violation or infringement of any
            Proprietary Rights); (iii) Customer’s breach or alleged breach of any of
            Customer’s representations, warranties or agreements set forth herein
            (including with respect to any Customer Content uploaded by Customer); or
            (iv) the embedment of the Embedded Items on the Asset.
        </p>
        <p>
            c.
            Subject to the UGC Disclaimer and the Third Party Disclaimer, Pixfuture
            shall defend Customer from and against any and all Losses resulting from,
            arising out of or related to third party claims alleging that Customer’s
            authorized use of the Platform (in accordance with the terms herein)
            infringes or constitutes misappropriation of any third party’s copyright,
            trademark or registered US patent (“<strong>IP Claims</strong>”), and
            Pixfuture will indemnify Customer and hold Customer harmless against any
            Losses finally awarded on such IP Claim by a court of competent
            jurisdiction or agreed to via settlement we agreed upon, including
            reasonable attorneys’ fees.
        </p>
        <p>
            d.
            Pixfuture’s indemnity obligations under this Section shall not apply if:
            (i) the Platform (or any portion thereof) was modified by Customer or any
            third party, but solely to the extent the IP Claim would have been avoided
            by not doing such modification; (ii) if the Platform is used in combination
            with any other service, device, software or products, including, without
            limitation, Third Party Software , but solely to the extent that such IP
            Claim would have been avoided without such combination; and/or (ii) any IP
            Claim arising or related to, the Customer Content or to any events giving
            rise to Customer’s indemnity obligations under this Section. Without
            derogating from the foregoing defense and indemnification obligation, if
            Pixfuture believes that the Platform, or any part thereof, may so infringe,
            then Pixfuture may in its sole discretion: (i) obtain (at no additional
            cost to you) the right to continue to use the Platform; (ii) replace or
            modify the allegedly infringing part of the Platform so that it becomes
            non-infringing while giving substantially equivalent performance; or (iii)
            if Pixfuture determines that the foregoing remedies are not reasonably
            available, then Pixfuture may require that use of the (allegedly)
            infringing Platform (or part thereof) shall cease and in such an event,
            Customer shall receive a prorated refund of any Subscription Fees paid for
            the unused portion of the Subscription Term. THIS SECTION STATES
            Pixfuture’S SOLE AND ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY, FOR ANY
            INTELLECTUAL PROPERTY INFRINGEMENT OR MISAPPROPRIATION BY Pixfuture AND/OR
            ITS SERVICE AND UNDERLYING TECHNOLOGY.
        </p>
        <p>
            e.
            The parties agree that in claiming any indemnification hereunder, the party
            claiming indemnification (the <strong>“Indemnified Party”</strong>) shall
            (i) promptly notify the other party (the <strong>“Indemnifying Party”</strong>) in writing of the claim; (ii) grant
            the Indemnifying Party sole control over the defense (except that the
            Indemnified Party may, at its own expense, assist in the defense); and
            (iii) provide the Indemnifying Party, at the Indemnifying Party’s expense,
            with all assistance, information and authority reasonably required for the
            defense of the claim. In no event shall the Indemnifying Party enter into
            any settlement or agree to any disposition of the indemnified claim(s),
            without the prior written consent of the Indemnified Party.
        </p>
        <h3>26. Limitations on Liabilities</h3>
        <p> a.
            To the maximum extent permitted by law, Pixfuture (including its and its
            subsidiaries’ shareholders, directors, officers, employees, and agents)
            shall not be liable for any personal injury, indirect, incidental, special,
            exemplary, consequential or punitive damages, or any loss of profits or
            revenues (regardless of whether we were advised of the possibility of such
            damages), whether incurred directly or indirectly, or any loss of data,
            use, good-will, or other intangible losses.
        </p>
        <p>
            b.
            In no event shall Pixfuture’s (including its and its subsidiaries’
            shareholders, directors, officers, employees, and agents) aggregate
            liability for all claims relating to the Platform exceed the fees paid to
            Pixfuture by Customer for the use of the Platform in the six (6) month
            period preceding the events giving rise to the liability. Applicable laws
            may not allow the limitation or exclusion of liability or incidental or
            consequential damages, so the above limitation or exclusion may not apply
            to you. In such cases, our liability will be limited to the fullest extent
            permitted by applicable laws.
        </p>
        <h3>27. Warranty Disclaimer</h3>
        <p>
            a.
            The Platform and all included content (including any Pixfuture Content
            and/or the Embedded Items) are provided on an “AS IS” and “AS AVAILABLE”
            basis without warranty of any kind. We specifically disclaim any and all
            warranties, express or implied, and conditions of merchantability, title,
            fitness for a particular purpose, and non-infringement. We do not guarantee
            that the Platform (including the Embedded Items) and all included content
            will always be complete, accurate, safe, secure, bug-free, or error-free,
            or that the Platform and all included content will always function without
            disruptions, delays, or imperfections. We may change, limit, suspend or
            discontinue the Platform (or any part thereof, including the Pixfuture
            Content, products and/or services) at any time, including the availability
            of any feature or database, without notice or liability.
        </p>
        <p>
            b.
            We do not control or direct Users’ actions on our Platform and are not
            responsible for the content or information Users transmit or publish on or
            through our Platform.
        </p>
        <p>
            c.
            Although we reserve the right to do so, we do not regularly monitor,
            pre-screen or approve Customer Content. Pixfuture accepts no responsibility
            with regards to any Customer Content. Note that any content items on the
            Platform that include polls, voting, etc. are not intended to be of a
            scientific sample or research, and should not be relied upon as such.
            Before you act on information that you’ve found on our Platform, you should
            confirm any facts that are important to your decision
        </p>
        <p>
            d.
            To the fullest extent permitted by law, we disclaim any responsibility or
            liability for the accuracy, reliability, availability, completeness,
            operability of the Platform or to any content (including Pixfuture Content
            or Customer Content) included in the Platform.
        </p>
        <p>
            e.
            You expressly acknowledge and agree that the use of the Platform is at your
            sole risk and that the entire risk as to satisfactory quality, performance,
            accuracy and efforts is solely with you.
        </p>
        <h3>28. Disputes</h3>
        <p>
            <strong>Governing law</strong>
        </p>
        <p>
            a. These Terms and your use of the Platform shall be governed by the laws
            of the Ontario province, without respect to its conflict of laws
            principles. You and Pixfuture agree to submit to the personal jurisdiction
            of competent courts located Toronto, Ontario province, Canada, for any
            actions not subject to the Arbitration section below.
        </p>
        <p>
            <strong>Arbitration &amp; className action waiver</strong>
        </p>
        <p>
            b. For any dispute, claim or controversy arising out of or in relation to
            these Terms or to the breach, termination, enforcement, interpretation or
            validity thereof, or to your access or use of the Platform (together <strong>“Dispute”</strong>), you agree to first contact us and attempt to
            resolve the Dispute with us informally. If Pixfuture has not been able to
            resolve the Dispute with you informally, you and we agree to resolve any
            Dispute by binding individual arbitration. Any provision of applicable laws
            notwithstanding, the arbitrator will not have authority to award damages,
            remedies, or awards that conflict with these Terms. The arbitration shall
            be conducted in Toronto, Ontario province, Canada, If your claim does not
            exceed $10,000, then the arbitration will be conducted solely on the basis
            of documents you and Pixfuture submit to the arbitrator unless the
            arbitrator determines that a hearing is necessary.
        </p>
        <p>
            c. Nothing in this section shall prevent either party from seeking
            injunctive or other equitable relief from the courts to prevent the actual
            or threatened infringement, misappropriation, or violation of a party’s
            copyrights, trademarks, trade secrets, patents, or other intellectual
            property rights and for matters related to data security or unauthorized
            access to the Platform. All claims must be brought in the parties’
            individual capacity, and not as a plaintiff or className member in any
            purported className or representative proceeding, and, unless we agree
            otherwise, the arbitrator may not consolidate more than one person’s
            claims.
        </p>
        <p>
            d. You agree that, by entering into these Terms, you and Pixfuture are each
            waiving the right to a trial by jury or to participate as a className
            representative or className member on any className claim you may have against us
            including any right to className arbitration or any consolidation of individual
            arbitrations. You also agree not to participate in claims brought in a
            private attorney general or representative capacity, or consolidated claims
            involving another person, if we are a party to the proceeding.
        </p>
        <h3>29. Miscellaneous</h3>
        <p>
            <strong>Modifications</strong>
        </p>
        <p>
            a. We may revise these Terms, from time to time, in our sole discretion and
            the most current version will always be posted on our Website (as reflected
            in the “Last Revised” heading). We encourage you to review these Terms
            regularly for any changes. By continuing to access or use the Platform
            after any revisions become effective, you agree to be bound by the revised
            Terms. If you do not agree to the new Terms, please stop accessing or using
            the Platform.
        </p>
        <p>
            <strong>Publicity</strong>
        </p>
        <p>
            b. Customer agrees that Pixfuture may use the Customer’s name and logo for
            advertising and publicity purposes and may refer to the existence of their
            relationship on the Website and in press releases, advertising, or
            marketing materials distributed to prospective partners, and for that
            purpose Customer hereby grants Pixfuture an irrevocable, perpetual,
            worldwide license to use Customer’s name, logo, label or any other
            trademarks or symbols associated with the Customer
        </p>
        <p>
            <strong>Email Notifications</strong>
        </p>
        <p>
            c. We reserve the right to determine the form and means of providing
            notifications to you, and you agree to receive legal notices electronically
            if we so choose.
        </p>
        <p>
            d. If you provided Pixfuture with your contact information, Pixfuture may
            send you marketing communications, from time to time, relating to our
            business or the businesses of carefully-selected third parties which
            Pixfuture think may be of interest to you, pursuant to our
            <a href='/terms-and-conditions#privacy-policy' rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
                <strong> <u>Privacy Policy</u></strong>
            </a>
            . By providing us your contact details (whether through an online
            registration form, an Insertion Order or in any other way) you specifically
            agree to this. You may unsubscribe at any time from such marketing
            communication.
        </p>
        <p>
            <strong>Third-Party Beneficiaries</strong>
        </p>
        <p>
            e. Pixfuture’s subcontractors and third-party service providers are
            intended third-party beneficiaries of these Terms and upon your acceptance
            of these Terms pixfuture’s subcontractors and third-party service providers
            will have the right to enforce these Terms as related to the licenses
            granted herein against you as third party beneficiaries thereof.
        </p>
        <p>
            <strong>Severability and Entire Agreement</strong>
        </p>
        <p>
            f. These Terms, together with
            <a href='/terms-and-conditions#privacy-policy' rel="noreferrer" onClick={() =>openTab('privacy-policy')}>
                <strong> <u>Privacy Policy</u></strong>
            </a>
            <strong>, </strong>
            applicable DPA(s) and any amendments thereto, and any additional agreements
            you may enter into with Pixfuture or that may apply to your specific use of
            the Platform, constitute the entire agreement between you and Pixfuture
            with respect to the use of the Platform, and supersede all prior or
            contemporaneous communications and proposals, whether oral, written or
            electronic, between you and Pixfuture. If any provision of these Terms is
            found to be unenforceable or invalid, that provision will be limited or
            eliminated to the minimum extent necessary so that these Terms will
            otherwise remain in full force and effect and enforceable. The failure of
            either party to exercise in any respect any right provided for herein shall
            not be deemed a waiver of any further rights hereunder.
        </p>
        <p>
            <strong>Force Majeure</strong>
        </p>
        <p>
            g. Pixfuture shall not be liable for any failure to perform its obligations
            hereunder where such failure results from any cause beyond our reasonable
            control, including, without limitation, mechanical, electronic or
            communications failure or degradation.
        </p>
        <p>
            <strong>Assignment</strong>
        </p>
        <p>
            h. These Terms are personal to you, and are not assignable, transferable or
            sub-licensable by you except with our prior written consent. We may assign,
            transfer or delegate any of our rights and obligations hereunder without
            consent.
        </p>
        <p>
            <strong> Relationship</strong>
        </p>
        <p>
            i. No agency, partnership, joint venture, or employment relationship is
            created as a result of these Terms and neither party has any authority of
            any kind to bind the other in any respect.
        </p>
        <p>
            <strong>No Waiver</strong>
        </p>
        <p>
            j. Either party’s failure to enforce any part of these Terms shall not
            constitute a waiver of such party’s right to later enforce that or any
            other part of these Terms. Waiver of compliance in any particular instance
            does not mean that either party waives compliance in the future. In order
            for any waiver of compliance to be binding, a party must provide the other
            party with written notice of such waiver through one of its authorized
            representatives.
        </p>
        <p>
            <strong>Export Control</strong>
        </p>
        <p>
            k. You agree to comply with the export laws and regulations of Canada and
            any other country with jurisdiction over the Platform, and that you shall
            not export or re-export, directly or indirectly, the Platform or any
            technical information disclosed hereunder to any destination prohibited or
            restricted by applicable export control regulations.
        </p>
        <p>
            <strong>Headings</strong>
        </p>
        <p>
            l. The section and paragraph headings in these Terms are for convenience
            only and shall not affect their interpretation.
        </p>
        <p>
            <strong>Contact Us</strong>
        </p>
        <p>
            m. We value hearing from our users and welcome your feedback. You may
            contact us anytime at:
            <a href="https://support.pixfuture.com/tickets/index.php?a=add">
                <strong> <u>Support</u>.</strong>
            </a>
        </p>
    </div>
    );
}

export default PlatformTerms;