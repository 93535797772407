import React,{ useState,useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;
        max-width:100%;

        & .gostory-title {
            display: flex;
            flex-direction: row;
            color:black;
            text-align:left;
            line-height:1.5;
            white-space: pre-line;
        }

        & .gostory-line {
            padding: 0 12px;
            border-left:2px solid var(--pxft-primary);
        }

        & .gostory-content {
            padding:20px 0;
            line-height:1.3;
            white-space: pre-line;
        }
`;

const GostorCard = ({title='',content=''}) =>{
    const [lineHeight, setLineHeight] = useState('130px');
    const getHeightofTitle =(element) =>{
        let h1height = element.getAttribute('height');
        setLineHeight(h1height);
    }

    useEffect(()=>{
        let element = document.getElementsByTagName("h1")[0];
        getHeightofTitle(element);
    },[])
    
    return (
        <ArticleBox sx={{py:6,px:3,maxWidth:'350px'}}>
            <Box className='gostory-title'>
                <div className='gostory-line' style={{'height':lineHeight}}></div>
                <h1>{title}</h1>
            </Box>
            <p className='gostory-content'>{content}</p>
        </ArticleBox>
    );
}

export default GostorCard;