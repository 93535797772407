import React, { Component } from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import BuyersHeader from 'components/buyers/header';
import BuyersArticle from 'components/buyers/buyersArticle';

class Buyers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return (
            <WideContainer>
                <BuyersHeader/>
                <BuyersArticle/>
            </WideContainer>
        );
    }

}

export default Buyers;