import React from 'react';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import GostorCard from 'components/mui/cards/gostoryCard';
import MegaButton from 'components/mui/buttons/magaButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PublicIcon from '@mui/icons-material/Public';


const GostoryMonetize=()=> {
    const features = [
        {
            title:'Outstanding\nMonetization\nResults',
            article:'By analyzing billions of requests daily, the AuctionX platform algorithm capable to fill maximum inventory with minimal manual work for a publisher.',
            picsrc:'/images/publishers/gostory/feature-4.png',
        },
        {
            title:'Create Stories\nYou Want to Tell\nand The Way\nYou Want\nto Tell Them.',
            article:'',
            picsrc:'/images/publishers/gostory/feature-5.png',
        },
]
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });

    return (
        <div style={{padding:'100px 0'}}>
            <WideContainer>
            <div ref={inViewRef3}></div>
            <div className='center-align gostory-content gostory-monetize-container'>
                <h1>
                    Monetize with Ads.
                </h1>
                <p>Improved monetization algorithm delivering up to 10 times better results than traditional advertising placements.</p>
                <div>
                    <NarrowContainer>
                        <Grid item xs={12} md={4} className='gostory-monetize-column'>
                            <div className='item'>
                                <StarBorderIcon sx={{height:'100px'}}/>
                                <p>New Revenue Stream</p>
                            </div>
                            <div className='item'>
                                <PlayCircleOutlineIcon/>
                                <p>Non-disruptive Advertising</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <video preload='none' autoPlay webkit-playsinline="true" width='100%' muted playsInline loop src='https://cdn.pixfuture.com/cw/videos/monetize_with_ads.mp4'/>
                        </Grid>
                        <Grid item xs={12} md={4} className='gostory-monetize-column'>
                            <div className='item'>
                            <EmojiEventsIcon/>
                            <p>Premium Advertisers</p>
                            </div>
                            <div className='item'>
                            <PublicIcon/>
                            <p>Intelligent Advertising Algorithms</p>
                            </div>
                        </Grid>
                    </NarrowContainer>             
                </div>
            </div>
            <div className='center-align gostory-content'>
                <NarrowContainer className='mobile-reverse'>
                    <Grow timeout={{enter:1200}} in={inView1}>
                        <Grid item xs={12} sm={6} md={6} className="center-align">
                              <img loading='lazy' className='contentPic' src={features[0].picsrc} alt=''/>
                        </Grid>
                    </Grow>
                    <Slide timeout={{enter:800}} in={inView1} direction="left">
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title={features[0].title} content={features[0].article}/>
                        </Grid>
                    </Slide>
                </NarrowContainer>
                <div className='observer' ref={inViewRef1}></div>
            </div>
            <div>
                <div className='center-align gostory-content'id='gostory-monetize'>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView2} direction="right">
                        <Grid item xs={12} sm={5} md={5} style={{flexDirection:'column'}}>
                            <GostorCard title={features[1].title} content={features[1].article}/>
                            <div style={{padding:'0px 20px'}}>
                                <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                                    <MegaButton width='200px' height='50px' content='get started'/>
                                </a>
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView2}>
                        <Grid item xs={12} sm={7} md={7} className="center-align" pt={{xs:5,lg:0}}>
                              <img loading='lazy' className='contentPic' src={features[1].picsrc} alt=''/>
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef2}></div>
                </div>
            </div>
            </WideContainer>
        </div>
    );
}

export default GostoryMonetize;