import React from 'react';
import styled from '@emotion/styled'

const MegaBtn = styled.button`
        background: linear-gradient(to right, white 50%, var(--pxft-primary) 50%);
        background-size: 200% 100%;
        border: 2px solid var(--pxft-primary);
        color: var(--pxft-primary);
        text-transform: uppercase;
        font-size: var(--pxft-font-normal);
        font-weight: var(--pxft-font-bold);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-position 0.6s;
        cursor: pointer;


        &:hover {
            background-position:-100% 0;
            color:white;
        }
        &.active {
            background-color: var(--pxft-primary);
        }
        &.focusVisible {
            box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
            outline: none;
        },
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    `;

const MegaButton=({width='120px', height='40px',content='button'}) =>{
    return (
        <MegaBtn style={{'height':height,'width':width}}>
            {content}
        </MegaBtn>
    );
}

export default MegaButton;