import React, { useState,useEffect}  from 'react';
import HomeHeader from 'components/home/header';
import 'assets/styles/home.css';
import HomeArticle from 'components/home/homeArticle';
import axios from 'axios.js';
//import BlogsData from 'data/blogs/blogItem.json';

const Home = ()=>{
      const[blogs,setBlogs]=useState([])
      const[latestblogs,setLatestBlogs]=useState([])
      const[isDataLoaded,setDataLoaded]=useState(false)

      const getBlogs = async () => {
        await axios.get("/api/content/blogs/all")
          .then(
            (res) => {
              setBlogs(res.data.blogs || { blogs: [] })
              setDataLoaded(true)
            }
          )
          .catch((error) => {
            setBlogs({ blogs: [] })
            setDataLoaded(true)
          });
      }
    
    
      useEffect(() => {
        getBlogs();
      }, []);

      useEffect(()=>{
        isDataLoaded ? setLatestBlogs([blogs[0],blogs[1],blogs[2]]) : setLatestBlogs([])
      },[blogs,isDataLoaded])

      useEffect(()=>{
        window.scrollTo(0, 0);
      })
      
      return (
        <div>
          <HomeHeader/>
          <HomeArticle blogs={latestblogs}/>
        </div>
      );
}

export default Home;