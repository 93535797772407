import React,{ useState,useEffect } from 'react';
import { Box } from '@mui/material';
import BasicButton from '../buttons/basicButton';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;

        & .title {
            display: flex;
            flex-direction: row;
            color:black;
            text-align:left;
            white-space: pre-line;
        }

        & .line {
            padding: 0 12px;
            border-left:2px solid var(--pxft-primary);
        }

        & .content {
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
        }

        & .btn-container {
            position: absolute;
            bottom: 0;
            right:0;
            width: 130px;
            height:40;
        }
`;

const ArticleCardA = ({long=true,title='',content='',button=true,btnText='Learn More',link=''}) =>{
    const [lineHeight, setLineHeight] = useState('130px');
    const getHeightofTitle =(element) =>{
        let h1height = element.getAttribute('height');
        setLineHeight(h1height);
    }

    useEffect(()=>{
        let element = document.getElementsByTagName("h1")[0];
        getHeightofTitle(element);
    },[])
    
    return (
        <ArticleBox sx={{py:{lg:6,xs:2},px:{lg:3,xs:1},width:'250px',height:long?{xs:'350px',sm:'400px',md:'450px'}:{}}}>
            <Box className='title'>
                <div className='line' style={{'height':lineHeight}}></div>
                <h1>{title}</h1>
            </Box>
            <p className='content'>{content}</p>
            {button? 
            <div className='btn-container'>
                <Link to={'/'+link}><BasicButton content={btnText}/></Link>
            </div>:''}
        </ArticleBox>
    );
}

export default ArticleCardA;