import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import ArticleCardA from '../cards/articleCardA';

const ContentRowC = ({title='',contents='',picSrc='',button=true,long=true,btnText='Learn More'}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        padding:'50px 0',
        background:`url('/images/column-back-b.png') no-repeat bottom center`,
        overflow:'hidden'
    }
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align' style={containerStyle}>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            <ArticleCardA width='300px' height='500px' title={title} content={contents} long={long} button={button} btnText={btnText}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                              <img loading='lazy' className='contentPic' src={'/' + picSrc} alt=''/>
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default ContentRowC;