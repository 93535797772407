import React, { Component } from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import VideoArticle from 'components/video/videoArticle';
import VideoHeader from 'components/video/header';
class Video extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return (
                <WideContainer>
                    <VideoHeader/>
                    <VideoArticle/>
                </WideContainer>
        );
    }
}

export default Video;