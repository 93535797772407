import React from 'react';
import IconCardA from 'components/mui/cards/iconCardA';
import {YellowDivider } from 'components/mui/divider/divider';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'

const Monetization = () => {
    const moneList = [
        {
            id:1,
            icon:'demand',
            title:'More Demands for Great Publishers',
            contents:'Our team is working closely with the largest marketers in the world, partnering with demand platforms.',
            iconWidth:'65',
            iconHeight:'65',
            picsrc:'images/home/demand-pic.png'
        },
        {
            id:2,
            icon:'revenue',
            title:'Managing Deals with Agencies & Brands ',
            contents:'Driven by revenue, we work with buyers to sell programmatic deals at maximum scale and exceptional safety.',
            iconWidth:'65',
            iconHeight:'65',
            picsrc:'images/home/intellegent-pic.png'
        },
        {
            id:3,
            icon:'monetize',
            title:'Intelligent Technology to Maximize Monetization',
            contents:'We know the right answers, and we are better prepared to help you monetize your goals by using our technology.',
            iconWidth:'65',
            iconHeight:'60',
            picsrc:'images/home/monetize-pic.png'
        },
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    const containerRef = React.useRef(null);

    const [inViewRef0, inView0] = useInView({
    });
    const [inViewRef1, inView1] = useInView({
    });
    const [inViewRef2, inView2] = useInView({
    });
    let inViews=[inView0,inView1,inView2]
    let refs = [inViewRef0,inViewRef1,inViewRef2]
    let views = document.getElementsByClassName('monetize-tab-pic');

    return (
        <WideContainer className='home-monetization-container'>
            <YellowDivider width='50px' height='120px' marginTop='0' marginRight='20%'/>
            <div className='center-align'>
                <Grid container className='monetization-content'>
                <Slide timeout={{enter:1000}} in={inView} direction="right" container={containerRef.current}>
                    <Grid item xs={12} sm={12} md={4.5} px={6} py={3} flexDirection="column">
                        <div className='monetize-tabs'>
                            {
                                moneList.map((listItem,i) =>(
                                    <div key={i} onClick={()=>views[i].scrollIntoView({behavior: "smooth", inline: "nearest"})}>
                                        <IconCardA  
                                        IconCard={listItem} inview={inViews[i]}/>  
                                    </div>
                                ))
                            }
                        </div>

                    </Grid>
                </Slide>
                <Grow timeout={{enter:800}} in={inView}>
                    <Grid item xs={12} sm={12} md={7.5} px={2}>
                        {
                            moneList.map((listItem,i) =>(
                                <div className='monetize-tab-pic' key={i}>
                                    <div className='tabs'>
                                        <h4>{listItem.title}</h4>
                                        <p>{listItem.contents}</p>
                                    </div>
                                      <img loading='lazy' className='contentPic' src={'/' + listItem.picsrc} alt='tab-pic' loading="lazy"/>
                                    <div className='observer' ref={refs[i]}></div>
                                </div>
                            ))
                        }   
                    </Grid>
                </Grow>
                </Grid>
                <div className='long-observer' ref={inViewRef}></div>
            </div>     
        </WideContainer>
    );
}

export default Monetization;