import React, { Component } from 'react';
import 'assets/styles/company.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import AboutusHeader from 'components/company/aboutus/header';
import AboutusArticle from 'components/company/aboutus/aboutusArticle';

class AboutUs extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return (
            <WideContainer>
                <AboutusHeader/>
                <AboutusArticle/>
            </WideContainer>  
        );
    }
}

export default AboutUs;