import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import QuoteCard from 'components/mui/cards/quoteCard';
import VideoScreenCard from './videoScreenCard';
import NumberCard from './numberCard';
import MegaButton from 'components/mui/buttons/magaButton';
import ContentRowB from 'components/mui/containers/contentRowB';
import ContentRowA from 'components/mui/containers/contentRowA';
import { YellowDivider } from 'components/mui/divider/divider';
import VideoFeatures from './screens/features';


const VideoArticle=()=> {
    const row = [
        {
            title:'Desktop/Mobile Video Ads',
            article:'Grow your website’s revenue while maximizing customer engagement through our effective and scalable video solutions.',
            videosrc:'https://cdn.pixfuture.com/corporate/home/video-demo.mp4'
        },
        {
            title:'Access Direct Campaigns',
            article:'We allow you to easily get access to the largest marketplace AuctionX and acquire direct campaigns flow to monetize traffic.',
            videosrc:'https://cdn.pixfuture.com/corporate/publishers/publisher-feature1.mp4'
        },
        {
            title:'Multi-screen Support',
            article:'Accelerate advertising sales through our unique proprietary platform and highly efficient customer support, we aim to provide the best value and results for both advertisers and publishers. \n\nOur monetization network is a gateway to the world of RTB, and we have partnered up with all the major ad exchanges, demand partners, and advertisers worldwide to create an optimal platform.',
        },
        {
            title:'Earn More from Your Content',
            article:'We understand it‘s hard to make valuable content. \n\nThat’s why we created a platform to make it easy to do business and monetize content efficiently. \n\nWe want to be your partner who knows how to monetize content.',
        },
        {
            title:'By the Numbers',
            article:'',
        }
    ]
    // const screens=[
    //     {
    //         title:'MOBILE APPS',
    //         article:'Simplified monetization solutions for mobile devices.',
    //         picsrc:'images/ads/video/feature1.png',
    //         link:'/mobile-video'
    //     },
    //     {
    //         title:'DESKTOP & MOBILE WEB',
    //         article:'Simplified monetization solutions for mobile devices.',
    //         picsrc:'images/ads/video/feature2.png',
    //         link:'/desktop-video'
    //     },
    //     {
    //         title:'CONNECTED TV',
    //         article:'Simplified monetization solutions for mobile devices.',
    //         picsrc:'images/ads/video/feature3.png',
    //         link:'/ctv'
    //     }
    // ]
    const numbers=[
        {
            title:'60%+',
            article:'better results using AuctionX than other video platforms.',
        },
        {
            title:'300b+',
            article:'monthly bids optimized from top websites, mobile apps, and CTV.',
        },
        {
            title:'10k+',
            article:'top publishers using pixfuture to monetize the content Worldwide.',
        },
        {
            title:'50k+',
            article:'advertisers programmatically buying traffic from pixfuture publishers.',
        }
    ]
    // const containerStyle1={
    //     padding:'50px 0',
    //     background:`url('/images/column-back-a.png') no-repeat top center`,
    // }
    const containerStyle2={
        padding:'50px 0',
        background:`url('/images/column-back-b.png') no-repeat top center`,
    }
    // const [inViewRef1, inView1] = useInView({
    //     triggerOnce: true,
    // });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='video-content'>
            <Grid sx={{position:'relative'}} pt={{xs:5,lg:0}}>
            <YellowDivider width='20%' height='15px' marginTop='40px' marginLeft='0' className='left-align'/>
            <ContentRowA title={row[0].title} contents={row[0].article} long={false} button={false} video={true} src={row[0].videosrc}/>
            </Grid>
            <Grid sx={{position:'relative'}}>
            <ContentRowB title={row[1].title} contents={row[1].article} long={false} button={false} video={true} src={row[1].videosrc}/>
            </Grid>
            {/* <div className='center-align video-container' style={containerStyle1}>
                    <NarrowContainer>
                    <div className='column-title'>
                            <h1>{row[2].title}</h1>
                    </div>
                    <div style={{position:'relative',width:'100%'}} className='center-align'>
                        <Slide timeout={{enter:1000}} in={inView1} direction="up" container={containerRef.current}>
                            <Grid container>
                                {screens.map((screen,i)=>
                                    <VideoScreenCard key={i} card={screen}/>
                                )}
                            </Grid>
                        </Slide>
                    </div>
                    <div className='observer' ref={inViewRef1}></div>
                    </NarrowContainer>
            </div> */}
            <Grid className='center-align' mb={6}>
                <VideoFeatures/>
            </Grid>
            <div className='center-align row-quotation'>
                <NarrowContainer>
                    <div className='column-title'>
                            <h1>{row[3].title}</h1>
                    </div>
                    <Grow timeout={{enter:1200}} in={inView2}>
                        <div className='card-container'>
                            <QuoteCard content={row[3].article}/>
                        </div>
                    </Grow>
                    <div className='observer' ref={inViewRef2}></div>
                </NarrowContainer>
            </div>
            <div className='center-align video-container' style={containerStyle2}>
                <NarrowContainer>
                    <div className='column-title'>
                            <h1>{row[4].title}</h1>
                    </div>
                    <div style={{position:'relative',width:'100%'}} className='center-align'>
                        <Grow timeout={{enter:1000}} in={inView3} style={{width:'80%'}}>
                            <Grid container className='center-align'>
                                {numbers.map((number,i)=>
                                    <NumberCard key={i} card={number}/>
                                )}
                            </Grid>
                        </Grow>
                    </div>
                    <div className='observer' ref={inViewRef3}></div>
                </NarrowContainer>
            </div>
            <div className='center-align get-started'>
            <NarrowContainer className='center-align'>
                <Grid item xs={12} md={7} className='title'>
                    <h1>Getting Started Is Easy</h1>
                    <p>Apply today for pixfuture and start monetizing your content better than ever.</p>
                </Grid>
                <Grid item xs={12} md={5}>
                    <div className='button'>
                    <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                        <MegaButton width='200px' height='50px' content='get started'/>
                    </a>
                    </div>
                </Grid>
            </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default VideoArticle;