import React from 'react';

const Header=()=> {
    return (
        <div className='center-align' style={{padding:'30px 0'}}>
            <h1>Support</h1>
        </div>
    );
}

export default Header;