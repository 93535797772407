import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import MegaButton from 'components/mui/buttons/magaButton';
import ContentRowA from 'components/mui/containers/contentRowA';
import ContentRowB from 'components/mui/containers/contentRowB';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';

const Article=()=> {
    const row = [
        {
            title:'Monetize Right!',
            article:'Maximize your content‘s potential by utilizing an advanced PixFuture player for video and articles on every page to ensure top performance on all web vitals metrics and boost your video revenue.',
            picsrc:'images/ads/instream-video/feature1.png',
        },
        {
            title:'A Three-in-One Solution for Ultimate Efficiency',
            article:'Experience the power of our integrated platform, combining cutting-edge video player technology, world-class programmatic ad selling, and ready-to-use content—all designed to scale with your needs FAST.\n\nThat kind of FAST!',
            picsrc:'images/ads/instream-video/feature2.png',
        },
        {
            title:'Monetization-First Approach',
            article:'Our proprietary ad server, powered by AuctionX, puts control in the hands of publishers. \nFully demand-agnostic and driven by machine learning, we optimize yield and delivery for both direct and programmatic demand in real time.\n\nDeveloped by PixFuture, pioneers in adtech for over a decade.',
            picsrc:'images/ads/instream-video/feature3.png',
        },
        {
            title:'Optimized for Performance',
            article:'Our fast-loading video player is fine-tuned for Google Web Vitals, supporting desktop, mobile, and native apps for some of the world‘s largest publishers. Whether your content is 16:9 or vertical, we’ve got you covered.',
            picsrc:'images/ads/instream-video/feature4.png',
        },
        {   title:'Scalable Content Solutions',
            article:'Content-ready video player solution with pixfuture’s dynamic playlist technology. \nOur contextual recommendation engine ensures the most relevant videos match each content category.',
            picsrc:'images/ads/instream-video/feature5.png',
        } 
    ]
    // const containerStyle1={
    //     padding:'20px 0',
    //     background:`url('/images/column-back-a.png') no-repeat top center`,
    // }
    // const containerStyle2={
    //     padding:'20px 0',
    //     background:`url('/images/column-back-b.png') no-repeat top center`,
    // }
    // const [inViewRef1, inView1] = useInView({
    //     triggerOnce: true,
    // });
    // const [inViewRef2, inView2] = useInView({
    //     triggerOnce: true,
    // });
    // const [inViewRef3, inView3] = useInView({
    //     triggerOnce: true,
    // });
    // const containerRef = React.useRef(null);
    return (
        <WideContainer className='video-content'>
            <Grid style={{position:'relative'}}>
                <ContentRowA title={row[0].title} contents={row[0].article} picSrc={row[0].picsrc} long={false} button={false}/>
                <BlueDivider width='10%' height='25px' marginBottom='20px' marginLeft='0' className='left-align'/>
            </Grid>
            <Grid style={{position:'relative'}}>
                <YellowDivider width='40%' height='15px' marginTop='40px' marginLeft='0' className='left-align'/>
                <Grid pt={{lg:0,xs:5}}>
                <ContentRowB title={row[1].title} contents={row[1].article} picSrc={row[1].picsrc} long={false} button={false}/>
                </Grid>
            </Grid>
            <Grid style={{position:'relative'}}>
                <ContentRowA title={row[2].title} contents={row[2].article} picSrc={row[2].picsrc} long={false} button={false}/>
                <BlueDivider width='10%' height='25px' marginBottom='20px' marginLeft='0' className='left-align'/>
            </Grid>
            <Grid style={{position:'relative'}}>
                <YellowDivider width='40%' height='15px' marginTop='40px' marginLeft='0' className='left-align'/>
                <Grid pt={{lg:0,xs:5}}>
                <ContentRowB title={row[3].title} contents={row[3].article} picSrc={row[3].picsrc} long={false} button={false}/>
                </Grid>
            </Grid>
            <Grid style={{position:'relative'}}>
                <ContentRowA title={row[4].title} contents={row[4].article} picSrc={row[4].picsrc} long={false} button={false}/>
                <BlueDivider width='10%' height='25px' marginBottom='20px' marginLeft='0' className='left-align'/>
            </Grid>
            <Grid className='center-align get-started'>
            <NarrowContainer className='center-align'>
                <Grid item xs={12} md={7} className='title'>
                    <h1>Getting Started Is Easy</h1>
                    <p>Apply today for pixfuture and start monetizing your content better than ever.</p>
                </Grid>
                <Grid item xs={12} md={5}>
                    <div className='button'>
                    <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                        <MegaButton width='200px' height='50px' content='get started'/>
                    </a>
                    </div>
                </Grid>
            </NarrowContainer>
            </Grid>
        </WideContainer>
    );
}

export default Article;