import React from 'react';
import { Grid,Paper,Chip} from '@mui/material';
import ShareButton from 'components/mui/buttons/shareButton';
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const CardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    max-width:300px;
    height:550px;
    margin:10px 5px;

    & .cardpaper-pic {
        cursor: pointer;
        overflow: hidden;
    }
    & .cardpaper-pic img{
        border-radius:5px;
        width:100%;
        height:220px;
        object-fit: cover;
        -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1s cubic-bezier(0, 0, 0.2, 1); };
    }

    & .cardpaper-pic:hover img{
        width:100%;
        height:220px;
        object-fit: cover;
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    & .cardpaper-title {
        color: black;
        white-space: pre-line;
        justify-content:center;
        padding:10px 0;
        border-bottom:2px solid var(--pxft-primary);
        cursor: pointer;
    }
    & .cardpaper-time {
        padding:10px 0;
        color: var(--pxft-midgrey);
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    & .cardpaper-content {
        padding:20px 0;
        color: var(--pxft-midgrey);
        white-space: pre-line;
        cursor: pointer;
    }
`

const BlogCard =({card})=> {
    const chipClick =(value)=>{
        value = value.toLowerCase();
        value = value.replace(/\s+/g, "-");

        window.location.href = `${window.location.origin}/blogs/${value}`;
    }
    const blogLink ='/blogs/blog/'+card.url
    const blogCategory = card.categories
    
    const zipCategory = blogCategory.split(",") || []
    return (
        <div>
            <CardPaper elevation={3}>
                <div className='cardpaper-pic'>
                    <a href={'/blogs/blog/'+card.url}>
                    <img loading='lazy' src={card.img_src} alt=''/>
                    </a>
                </div>
                <Grid container sx={{p:2}}>
                    <Grid item xs={10}>
                        <a href={'/blogs/blog/'+card.url}><h2 className='cardpaper-title'>{card.title}</h2></a>
                        <div className='cardpaper-time'>
                            <CalendarMonthIcon/>
                            <p style={{padding:'0 5px'}}>{card.time}</p>
                        </div>
                        <a href={'/blogs/blog/'+card.url} >
                            <p className='cardpaper-content'>
                                {card.text}
                            </p>
                        </a>
                        {
                            zipCategory.map((category,i)=>
                            <Chip sx={{my:0.5}} key={i} label={category} variant="outlined" onClick={()=>chipClick(category)} />
                            )
                        }
                    </Grid>
                    <Grid item xs={2} style={{position:'relative'}}>
                        <div style={{position:'absolute',bottom:0}}>
                        <ShareButton url={blogLink} title={card.title}/>
                        </div>
                    </Grid>
                </Grid>
            </CardPaper>
        </div>
    );
}

export default BlogCard;