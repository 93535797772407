import React from 'react';
import Monetization from './monetization';
import Overview from './overview';
import OurPlatform from './ourPlatform';
import ProductsDemo from './productsDemo';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import BasicRowC from 'components/mui/containers/basicRowC';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';
import LatestPosts from './latestPosts';
import { Grid } from '@mui/material';

const HomeArticle = ({blogs}) => {
    const adDes = {
        title:'Robust \n Analytics & \nPredictions',
        content:'With our behavioral algorithms and predictive analytic tools, we engage the right audience at the right time. Platform algorithms are helping to evaluate performance to achieve maximum revenue results.',
        src:'images/home/ad-monestatics.png',
    }
    const inventoryDes = {
        title:'Inventory Tools Availability',
        content:'We understand the importance of your brand impact. That’s why we focus on ensuring our publishers deliver the most viewable impressions.',
        src:'images/home/inventory-pic.png',
    }
    const advsDes = {
        title:'We Power the World’s Biggest Brands',
        src:'images/home/topadvs.png',
    }
    return (
        <WideContainer className='home-article-container'>
            <LatestPosts posts={blogs}/>
            <Overview/>
            <OurPlatform/>
            <Monetization/>
            <ProductsDemo/>
            <div style={{position:'relative'}} className='ad-container' >
            <YellowDivider width='60%' height='20px' marginTop='-30px' marginRight='0'/>
            <BlueDivider width='40%' height='20px' marginTop='30px' marginRight='0'/>
            <Grid pt={{lg:0,xs:5}}>
                <BasicRowA title={adDes.title} contents={adDes.content} picSrc={adDes.src} button={false} long={false}/>
            </Grid>
            </div>
            <div style={{position:'relative'}} className='inventory-container'>
            <YellowDivider width='40%' height='20px' marginLeft='0' className='left-align'/>
            <Grid pt={{lg:0,xs:5}}>
                <BasicRowB title={inventoryDes.title} contents={inventoryDes.content} picSrc={inventoryDes.src} button={false} long={false}/>
            </Grid>
            </div>
            <div style={{position:'relative'}}>
            <BlueDivider width='55%' height='30px' marginTop='0' marginRight='0'/>
            <BasicRowC title={advsDes.title} picSrc={advsDes.src}/>
            </div>
        </WideContainer>
    );
}

export default HomeArticle;