import React from 'react';
import 'assets/styles/ads.css'
import {WideContainer} from 'components/mui/containers/PxftContainer';
import { BlueDivider} from 'components/mui/divider/divider';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import HeaderCard from 'components/mui/cards/headerCard';

const Header = () => {
    const header = {
        title:'Outstream Video Ads',
        article:'Premium campaigns deliver brand messages to site visitors worldwide in native video format for the modern web.',
        src:'',
        iconsrc:'images/buyers/buyer-icon.png',
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='video-header-container max-height'>
            <div ref={inViewRef1}></div>
            <BlueDivider marginBottom='15%' width='100%' marginLeft='0' height='15px' className='left-align'/>
            <div className='center-align'>
                <Grid container className='header-content'>
                    <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={6} md={5.5} lg={4.5} className='center-align'>
                            <HeaderCard title={header.title} content={header.article} iconsrc={header.iconsrc} long={false} button={false}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align' style={{zIndex:'2'}}>
                        <video preload='none' autoPlay style={{width:'80%'}} webkit-playsinline="true" muted playsInline loop src='https://cdn.pixfuture.com/corporate/videos/video-phone.mp4' />
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </div>
        </WideContainer>
    );
}

export default Header;