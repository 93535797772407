import React from 'react';
import ContentRowA from 'components/mui/containers/contentRowA';
import ContentRowB from 'components/mui/containers/contentRowB';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';
import TestimonyContainer from 'components/testimony/testimonyContainer';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import ContactInfo from 'components/contactInfo/contactInfo';
import { useInView } from 'react-intersection-observer'

const PublisherArticle=()=> {
    const row = [
        {
            title:'Results\n Focused Monetization',
            article:'AuctionX™ programmatic platform is running a bidding auction among the largest DSPs, SSPs, and Direct Campaigns to provide the best possible result to the publisher.',
            picsrc:'images/buyers/buyer-feature1.png',
        },
        {
            title:'Privacy\nCompliance Watchdog',
            article:'Over 90% of publishers make mistakes in their Prebid.js setup. By joining pixfuture, publishers get instant access to professionally managed user IDs and policy regulations that are constantly being updated by our team.',
            picsrc:'images/publishers/publisher-feature2.png',
        },
        {
            title:'Robust Analytics',
            article:'With our detailed statistics reports that consist of viewability, page views, CTR, CPM, and much more, you may see what helps to grow revenue. AuctionX™ will highlight a pain-point in your stats to show you what you may do to reach maximum revenue per placement.',
            picsrc:'images/publishers/publisher-feature3.png',
        },
        {
            title:'Display',
            article:'',
            list:['Worldwide Coverage with Maximum Monetization','Targeted Ads by GEO, Audience, Content, and etc...','Active Block List Management','Pre-approved Creatives','COPPA compliant'],
            picsrc:'images/buyers/buyer-ads1.png',
        },
        {   title:'Outstream Video',
            article:'',
            list:['Campaigns From The World’s Leading Brands','High Fill-Rates At Maximum CPMs','Delivery Across Every Device','Single-Step Integration','Robust Analytics'],
            picsrc:'images/buyers/buyer-ads2.png',
        },
        {   title:'Instream Video',
            article:'',
            list:['Monetization-First Approach','Optimized for Performance','Scalable Content Solutions'],
            picsrc:'images/buyers/buyer-ads3.png',
        },
        {
            title:'GoStory',
            article:'',
            list:['Premium Distribution','Engaging & Relevant','Brand Protection','High CTR rate','Any Device'],
            picsrc:'images/publishers/publisher-ads3.png',
        }   
    ]
    const containerStyle={
        display:'flex',
        flexDirection:'column',
        padding:'50px 0 0px 0',
        background:`url('/images/column-back-a.png') no-repeat top center`,
    }
    const containerStyle2={
        display:'flex',
        flexDirection:'column',
        padding:'50px 0',
        background:`url('/images/column-back-b.png') no-repeat top center`,
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <div>
            <div style={{position:'relative'}}>
            <YellowDivider width='60%' height='15px' marginTop='15px' marginRight='0' className='right-align'/>
            <Grid pt={{lg:0,xs:5}}>
            <ContentRowA title={row[0].title} contents={row[0].article} picSrc={row[0].picsrc} long={false} button={false}/>
            </Grid>
            <BlueDivider width='10%' height='25px' marginBottom='20px' marginLeft='0' className='left-align'/>
            </div>
            <div style={{position:'relative'}}>
            <YellowDivider width='40%' height='15px' marginTop='40px' marginLeft='0' className='left-align'/>
            <Grid pt={{lg:0,xs:5}}>
            <ContentRowB title={row[1].title} contents={row[1].article} picSrc={row[1].picsrc} long={false} button={false}/>
            </Grid>
            </div>
            <WideContainer>
                <div className='center-align gostory-content' style={containerStyle2}>
                    <NarrowContainer>
                        <BlueDivider width='57%' height='20px' marginTop='30px' marginRight='0'/>
                        <Slide timeout={{enter:1200}} in={inView} direction="right">
                            <Grid item xs={12} sm={6} md={6} className='center-align' display='flex' flexDirection='column'>
                                <Grid px={2} py={4} maxWidth='350px'>
                                    <div className='gostory-title'>
                                        <div className='gostory-line' style={{height:'150px'}}></div>
                                        <h1 style={{width:'200px'}}>First-Party Data Support</h1>
                                    </div>
                                    <p className='gostory-txt'>Unlock new revenue streams with the support of first-party data monetization and most user IDs in a cookie-less environment. CPMs for identified users are up to 5X higher.<br/><br/>
                                    <b><a href='https://portal.pixfuture.com/registration' target='_blank' rel='noopener noreferrer'>Get Started Now</a></b> to benefit from this unique solution for publishers.
                                    </p>
                                </Grid>
                            </Grid>
                        </Slide>
                        <Grow timeout={{enter:1200}} in={inView}>
                            <Grid item xs={12} sm={6} md={6} py={{lg:7,xs:0}} className='center-align'>
                                <img loading='lazy' className='contentPic' src='images/publishers/publisher-feature4.png' alt=''/>
                            </Grid>
                        </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer>
            <div style={{position:'relative'}}>
            <YellowDivider width='20px' height='100px' marginTop='0' marginRight='15%'/>
            <ContentRowB title={row[2].title} contents={row[2].article} picSrc={row[2].picsrc} long={false} button={false}/>
            </div>

            <WideContainer>
                <div className='center-align' style={containerStyle}>
                    <NarrowContainer>
                        <div style={{color:'black',width:'100%',textAlign:'center',padding:'50px 0'}}>
                            <h1>We Support Different Formats of Ads</h1>
                        </div>
                        <BasicRowB title={row[3].title} contents={row[3].article} list={row[3].list} picSrc={row[3].picsrc} long={false} button={false}/>
                        <BasicRowA title={row[4].title} contents={row[4].article} list={row[4].list} picSrc={row[4].picsrc} long={false} link='video'/>
                        <BasicRowB title={row[5].title} contents={row[5].article} list={row[5].list} picSrc={row[5].picsrc} long={false} link='instream-video'/>
                        <BasicRowA title={row[6].title} contents={row[6].article} list={row[6].list} picSrc={row[6].picsrc} long={false} link='gostory'/>
                    </NarrowContainer>
                </div>
                {/* <div className='center-align buyer-container'>
                    <TestimonyContainer/>
                </div> */}
                <div className='center-align'>
                    <ContactInfo/>
                </div>
            </WideContainer>
        </div>
    );
}

export default PublisherArticle;