import { React } from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ContactFormContent from './contactFormContent';
import {GOOGLE_RECAPTCHA_KEY} from 'helper'

const ContactForm=()=>{
    return(
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
            <ContactFormContent/>
        </GoogleReCaptchaProvider>
    )
}

export default ContactForm;