import React from 'react';
import {NarrowContainer, WideContainer} from 'components/mui/containers/PxftContainer';
import { Grid, IconButton} from '@mui/material';
import Slide from '@mui/material/Slide';
import Carousel from 'react-material-ui-carousel';
import { useInView } from 'react-intersection-observer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const GostoryHeader = () => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);

    return (
            <WideContainer className='buyer-gostory-header-container'>
                    <div className='buyer-gostory-header'>
                        <div className='background'>
                        <Carousel
                        sx={{height:{xs:'100vh',md:'100vh',lg:'auto'},width:{md:'auto',lg:'100%'},display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <div className='center-align'>
                                <img loading='lazy' className='bg-pic' src='/images/buyers/buyer-gostory/bg-1.png' alt=''/>
                            </div>
                            <div className='center-align'>
                                <img loading='lazy' className='bg-pic' src='/images/buyers/buyer-gostory/bg-2.png' alt=''/>
                            </div>
                            <div className='center-align'>
                                <img loading='lazy' className='bg-pic' src='/images/buyers/buyer-gostory/bg-3.png' alt=''/>
                            </div>
                        </Carousel>
                        </div>
                        <Slide timeout={{enter:1200}} in={inView} direction="up" container={containerRef.current}>
                            <div className='center-align title'>
                                <h1>We Share Stories,</h1>
                                <h1>You Generate Clients.</h1>
                            </div>
                        </Slide>
                        <div className='center-align label'>
                            <p>See How It Works</p>
                            <ExpandMoreIcon fontSize='large'/>
                        </div>
                        <div className='observer' ref={inViewRef}></div>
                    </div>
            </WideContainer>
    );
}

export default GostoryHeader;