/* eslint-disable import/no-anonymous-default-export */
export default {
    h1: {
        fontWeight: 600,
        fontSize: 38,
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: 600,
        fontSize: 28,
        letterSpacing: '-0.24px'
    },
    h3: {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.24px'
    },
    h4: {
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: '-0.24px'
    },
    h5: {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '-0.24px'
    },
    h6: {
        fontWeight: 600,
        fontSize: 13,
        letterSpacing: '-0.24px'
    },
    subTitle:{
        fontWeight: 600,
    },
    body: {
        fontSize: 13
    },
    overLine:{
        fontSize: 10
    }
}