import React from 'react';
import styled from '@emotion/styled'

const BasicBtn = styled.button`
        background-color: var(--pxft-transparent);
        border-radius: 5px;
        border: 1px solid var(--pxft-mildgrey);
        color: var(--pxft-primary);
        font-size: var(--pxft-font-normal);

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms ease;
        cursor: pointer;

        &:hover {
            background-color: var(--pxft-primary);
            color:white;
        }
        & .active {
            backgroundColor: var(--pxft-primary);
        }
        & .focusVisible {
            boxShadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
            outline: none;
        }
        & .disabled {
            opacity: 0.5;
            cursor: not-allowed;
 `;

const BasicButton=({width='120px', height='40px',content='button'}) =>{
    return (
        <BasicBtn style={{'height':height,'width':width}} >
            {content}
        </BasicBtn>
    );
}

export default BasicButton;