import VideoFeatures from 'components/video/screens/features';
import React from 'react';

const CMPArticle = () => {
    return (
        <div className='cmp-container center-align'>
            <VideoFeatures/>
        </div>
    );
}

export default CMPArticle;