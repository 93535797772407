import React from 'react';
import {NarrowContainer} from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import IconCardB from 'components/mui/cards/iconCardB';
import Slide from '@mui/material/Slide';
import { useInView } from 'react-intersection-observer'

const VideoFeatures=()=> {
    const iconcardList=[
        {
            id:1,
            icon:'demand',
            title:'Access to World’s Top Brands',
            contents:'With PixFuture’s mobile monetization program, you gain access to all the premium advertisers around the globe as well as real-time bidding (RTB) buyers from PixFuture SSP.',
            iconWidth:'50',
            iconHeight:'50',
            fill:'#0077D3',
            height:'250px'
        },
        {
            id:2,
            icon:'revenue',
            title:'Earn Higher CPMs',
            contents:'When advertisers compete to show their ads on your app, you get more revenue as they pay higher CPMs with a specific goal. This implies you acquire more income.',
            iconWidth:'50',
            iconHeight:'50',
            fill:'#0077D3',
            height:'250px'
        },
        {
            id:3,
            icon:'monetize',
            title:'Worldwide Fill',
            contents:'With more advertisers interested to promote their content in your application, you get the one of the best fill rate worldwide from PixFuture.',
            iconWidth:'50',
            iconHeight:'50',
            fill:'#0077D3',
            height:'250px'
        }
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    const containerRef = React.useRef(null);
    return (
        <NarrowContainer>
                <Slide timeout={{enter:1800}} in={inView} direction="up" container={containerRef.current}>
                    <Grid container pt={6} className='center-align'>
                            {
                                iconcardList.map(listItem =>(
                                    <IconCardB key={listItem.id} IconCard={listItem}/>
                                ))
                            }
                    </Grid>
                </Slide>
                <div className='observer' ref={inViewRef}></div>
        </NarrowContainer>
    );
}

export default VideoFeatures;