import React,{forwardRef, useImperativeHandle} from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';

const FacebookButton = forwardRef(({url,quote,hashtag},ref)=> {
    const mainurl='https://www.facebook.com/dialog/share?'
    const app_id='app_id=1025167604933568'
    const urlString=mainurl+app_id+'&href='+ encodeURIComponent(url)+'&hashtag='+encodeURIComponent(hashtag)+'&quote='+encodeURIComponent(quote)
    const windowHeight=400
    const windowWidth=550
    const topPosition=window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - windowHeight / 2
    const leftPosition=window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - windowWidth / 2
    useImperativeHandle(ref, () => ({
        openDialog(){
            const style = `top=${topPosition},left=${leftPosition},width=${windowWidth},height=${windowHeight}`
            window.open(urlString,'',style)
        }
      }));
    return (
        <div className='center-align'>
            <FacebookIcon/>
        </div>
    );
});

export default FacebookButton;