import React,{useEffect,useState} from "react"
import NavigationBar from "components/base/navi/navbar"
import Footer from "components/base/footer/footer"
import { Outlet } from "react-router-dom";
import BackToTop from 'components/mui/backToTop/backToTop';
import 'assets/styles/base/layout.css';
import CookieConsent from "components/base/cookieConsent";
import MetaTags from "components/base/metaTags";
import MetaTagData from 'data/metaTags/metaTags.json';
import { useLocation } from 'react-router-dom';

const Layout = () => {
  const defalut_settting = {
    webTitle:'Programmatic Ad Management | pixfuture™',
    webSiteName:"PixFuture Media Inc.",
    webImage:'/images/logo.png',
    webDescription:'PixFuture provides premium online publishers with efficient technology to sell their ad inventory by connecting to the wide range of campaigns and demand partners.',
    webUrl:window.location.href,
    locale:'en_US',
    type:'article'
}
  const [tag,setTag] = useState(defalut_settting)
  let location = useLocation();

  useEffect(()=>{
    const path = location.pathname
    if(path.length>0){
      const tempTag = MetaTagData.filter(a => a.path === path)[0]
      setTag(tempTag)
    }
  },[location])
  return (
    <>
      <MetaTags tag={tag}/>
      <NavigationBar />
      <BackToTop />
      <Outlet />
      <Footer />
      <CookieConsent />
    </>
  )
};

export default Layout;