import React from 'react';
import CenterTitle from 'components/mui/containers/centerTitle';
import MegaButton from 'components/mui/buttons/magaButton';

const ContactInfo=()=> {
    return (
        <div className="contact-info-container">
            <div className="container text-center icon-up-down-animation">
                <div className='center-align' style={{width:'75%',margin:'auto'}}>
                    <CenterTitle
                        title="Setup is easy and safe. Register now and see the result!"
                        article=""
                    />
                </div>
                <div className='center-align'>
                    <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                        <MegaButton width='200px' height='50px' content='get started'/>
                    </a>
                </div>
                <div className="shape shape-1">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-1.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-2.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-3.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-4.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-5.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-6.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-7.png" alt="" />
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                          <img loading='lazy' src="/images/icon-animation/icon-1.png" alt="" />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;