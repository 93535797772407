import Article from 'components/resources/support/article';
import Header from 'components/resources/support/header';
import React,{ useEffect} from 'react';

const Support=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <>
            <div className='support-container'>
            <Header/>
            <Article/>
        </div>
        </>

    );
}

export default Support;