import React,{useEffect} from 'react';

const GostoryPlayer=()=> {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/cw/buyerPlayer-widget.js";
        script.setAttribute('init',"pxft-main");
        script.setAttribute('pixId',"5351x801");
        script.async = true;
        const elem = document.getElementById('buyers-gostory-player');
        elem.appendChild(script);
        return () => {
            elem.removeChild(script);
        }
    }, []);
    return (
        <div id='buyers-gostory-player' className=''></div>
    );
}

export default GostoryPlayer;