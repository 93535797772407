import React,{ useState,useEffect } from 'react';
import { Box } from '@mui/material';
import BasicButton from '../buttons/basicButton';
import { styled } from '@mui/material/styles';

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;
        max-width:300px;

        & .title {
            display: flex;
            flex-direction: column;
            color:black;
            text-align:left;
            white-space: pre-line;
        }

        & .line {
            padding: 12px 0;
            border-top:2px solid var(--pxft-blue);
        }

        & .content {
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
        }

        & .btn-container {
            position: absolute;
            bottom: 0;
            right:0;
            width: 130px;
            height:40;
        }
`;

const HeaderCard = ({long=true,title='',content='',iconsrc,button=true,btnText='Learn More',link=''}) =>{
    const [lineWidth, setLineWidth] = useState('130px');
    const getWidthofDiv =(element) =>{
        let divWidth = element.getAttribute('width');
        setLineWidth(divWidth);
    }

    useEffect(()=>{
        let element = document.getElementsByClassName("headerBox")[0];
        getWidthofDiv(element);
    },[])
    
    return (
        <ArticleBox className='headerBox' sx={{py:6,px:3,width:'300px',height:long?{xs:'350px',sm:'400px',md:'450px'}:{}}}>
            <Box className='title'>
                <div style={{width:'40px',padding:'8px 0'}}>
                    <img style={{width:'100%'}} src={'/' + iconsrc} alt=''/>
                </div>
                <div className='line' style={{'width':lineWidth}}></div>
                <h1>{title}</h1>
            </Box>
            <p className='content'>{content}</p>
            {button? 
            <div>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <BasicButton content={btnText}/>
                </a>
            </div>:''}
        </ArticleBox>
    );
}

export default HeaderCard;