import React from 'react';
import {Box} from '@mui/material';

export const BlueDivider = ({width='50',height='50',marginTop='auto',marginBottom='auto',marginLeft='auto',marginRight='auto',className=''}) => {
    return (
        <Box className={className} style={{
            width:width,
            height:height,
            top:marginTop,
            bottom:marginBottom,
            left:marginLeft,
            right:marginRight,
            position:'absolute',
            backgroundColor:'var(--pxft-blue)',
            zIndex:1,
        }}/> 
    );
}

export const YellowDivider = ({width='50',height='50',marginTop='auto',marginBottom='auto',marginLeft='auto',marginRight='auto',className=''}) => {
    return (
        <Box className={className} style={{
            width:width,
            height:height,
            top:marginTop,
            bottom:marginBottom,
            left:marginLeft,
            right:marginRight,
            position:'absolute',
            backgroundColor:'var(--pxft-yellow)',
            zIndex:1,
        }}/> 
    );
}
