import React from 'react';
import GostoryGallary from '../gostory/gostoryGallary'

const DemoViews = () => {
    const picsLine1 = [
        {
            img:true,
            src:'images/publishers/gostory/demo1.png',
            marginTop:'0px',
        },
        {
            img:false,
            src:'https://cdn.pixfuture.com/corporate/publishers/gostory/gostory-video1.mp4',
            marginTop:'100px',
        },
        {
            img:true,
            src:'images/publishers/gostory/demo2.png',
            marginTop:'50px',
        },
        {
            img:false,
            src:'https://cdn.pixfuture.com/corporate/publishers/gostory/gostory-video2.mp4',
            marginTop:'0px',
        },
    ]
    const picsLine2 = [
        {
            img:true,
            src:'images/publishers/gostory/demo4.png',
            marginTop:'0px',
        },
        {
            img:true,
            src:'images/publishers/gostory/demo3.png',
            marginTop:'100px',

        },
        {
            img:true,
            src:'images/publishers/gostory/demo5.png',
            marginTop:'50px',
        },
        {
            img:false,
            src:'https://cdn.pixfuture.com/corporate/publishers/gostory/gostory-video3.mp4',
            marginTop:'0px',
        },
    ]
    
    return (
        <div className='gostory-demo center-align'>
            <GostoryGallary items={picsLine1}/>
            <GostoryGallary items={picsLine2}/>
        </div>
    );
}

export default DemoViews;