import BlogCardSmall from 'components/mui/cards/blogCardSmall';
import { BlueDivider, YellowDivider } from 'components/mui/divider/divider';
import React from 'react';
import { Grid,Divider,Chip } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useInView } from 'react-intersection-observer'
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';

const LatestPosts = ({posts}) =>{
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    const containerRef = React.useRef(null);

    return (
        <WideContainer className='center-align home-latest-news-container'>
            <YellowDivider marginTop='0px' width='60%' height='15px' marginLeft='0' className='left-align'/>
            <BlueDivider marginTop='50px' width='40%' marginLeft='0' height='30px' className='left-align'/>
            <div className='center-align'>
                <Slide timeout={{enter:1500}} in={inView} direction="up" container={containerRef.current}>
                    <div>
                    <NarrowContainer className='center-align'>
                        <h2 style={{textAlign:'center',color:'black'}}>Discover Our Latest News, Insights, and Events</h2>
                        <Grid container className='center-align' style={{padding:'40px 0 30px'}}>
                        {
                            posts.map((p,i)=>(
                                <Grid key={i} item md={4} xs={12} className='center-align'>
                                    <BlogCardSmall post={p}/>
                                </Grid>
                            ))
                        }
                        </Grid>
                    <Divider sx={{width:'100%'}}>
                        <a href='/blogs'>
                        <Chip label="Learn More Updates" color="primary" onClick={()=>{}}/>
                        </a>
                    </Divider>
                    </NarrowContainer>
                    </div>
                </Slide>
                <div className='observer' ref={inViewRef}></div>
            </div>
        </WideContainer>
    );
}

export default LatestPosts;