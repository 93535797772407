import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShareButtonDetails from 'components/mui/buttons/shareButtonDetails';

const Header = ({blog}) => {
    const blogCategory = blog.categories
    const zipCategory = blogCategory?.split(",") 
    const blogLink =window.location.href

    const getHref =(value)=>{
        value = value.toLowerCase();
        value = value.replace(/\s+/g, "-");

        return `${window.location.origin}/blogs/${value}`;
    }
    return (
        <>
        <WideContainer>
        <div className='blog-header-container center-align'>
            <NarrowContainer>
                <h1 className='title'>{blog.title}</h1>
                <div className='divider'></div>
                <Grid container>
                    <Grid item xs={10}>
                        <div className='time'>
                            <CalendarMonthIcon/>
                            <p style={{padding:'0 5px'}}>{blog.time}</p>
                            {
                                zipCategory?.map((category,i)=>
                                <div key={i}><a href={getHref(category)} className='category'>{category}</a></div>
                                )
                            }
                        </div>
                    </Grid>
                    <Grid item xs={2} >
                        <div className='sharebtn-container'>
                            <ShareButtonDetails url={blogLink} title={blog.title}/>
                        </div>
                    </Grid>
                </Grid>
            </NarrowContainer>
        </div>
    </WideContainer>
        </>
        
    );
}

export default Header;