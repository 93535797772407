import React,{forwardRef, useImperativeHandle} from 'react';
import LinkedinIcon from '@mui/icons-material/LinkedIn';

const LinkedinButton = forwardRef(({url},ref)=> {
    const mainurl='https://www.linkedin.com/shareArticle/?'
    const configs='&mini=true'
    const urlString=mainurl+'url='+ encodeURIComponent(url)+configs
    const windowHeight=600
    const windowWidth=750
    const topPosition=window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - windowHeight / 2
    const leftPosition=window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - windowWidth / 2
    useImperativeHandle(ref, () => ({
        openDialog(){
            const style = `top=${topPosition},left=${leftPosition},width=${windowWidth},height=${windowHeight}`
            window.open(urlString,'',style)
        }
      }));
    return (
        <div className='center-align'>
            <LinkedinIcon/>
        </div>
    );
});

export default LinkedinButton;