import React from 'react';
import {Grid} from '@mui/material';
import { useInView } from 'react-intersection-observer'

const GostorySteps =()=> {
    const tabs=[
        {
            title:'Add Story',
            content:'Start adding content URLs of your website to create a story or simply add your website’s RSS feed.',
            src:'https://cdn.pixfuture.com/cw/videos/first_integration_part.mov'
        },
        {
            title:'Player Settings',
            content:'Customize Player Size, Font, or Colour to match your website vibe!',
            src:'https://cdn.pixfuture.com/cw/videos/second_integration_part.mp4'
        },
        {
            title:'Support Multi-language',
            content:'Start the story description in your preferred language.',
            src:'https://cdn.pixfuture.com/cw/videos/multi_language.mp4'
        },
        {
            title:'Simple Integration',
            content:'Copy a JavaScript snippet and paste it to your website.',
            src:'https://cdn.pixfuture.com/cw/videos/third_integration_part.gif'
        }
    ]

    const [inViewRef1, inView1] = useInView({
    });
    const [inViewRef2, inView2] = useInView({
    });
    const [inViewRef3, inView3] = useInView({
    });
    const [inViewRef4, inView4] = useInView({
    });
    const [inViewRef5, inView5] = useInView({
        triggerOnce: true,
    });

    return (
        <div className='gostory-stepper-container'>
            <div ref={inViewRef5}></div>
            <Grid container>
                <Grid item xs={0} md={4} className='steppers'>
                <div className='tabList'>
                    <div className={`tab ${inView1? 'tab-active':''}`}>
                        <h4>{tabs[0].title}</h4>
                        <p>{tabs[0].content}</p>
                    </div>
                    <div className={`tab ${inView2? 'tab-active':''}`}>
                        <h4>{tabs[1].title}</h4>
                        <p>{tabs[1].content}</p>
                    </div>
                    <div className={`tab ${inView3? 'tab-active':''}`}>
                        <h4>{tabs[2].title}</h4>
                        <p>{tabs[2].content}</p>
                    </div>
                    <div className={`tab ${inView4? 'tab-active':''}`}>
                        <h4>{tabs[3].title}</h4>
                        <p>{tabs[3].content}</p>
                    </div>
                </div>
                
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className='stepper-videos'>
                        <div className='stepper-video'>
                            <div className='tabs'>
                                <h4>{tabs[0].title}</h4>
                                <p>{tabs[0].content}</p>
                            </div>
                            <video preload='none' autoPlay webkit-playsinline="true" muted playsInline loop src={tabs[0].src} alt='' />
                            <div className='observer' ref={inViewRef1}></div>
                        </div>
                    </div>
                    <div className='stepper-videos'>
                        <div className='stepper-video'>
                            <div className='tabs'>
                                <h4>{tabs[1].title}</h4>
                                <p>{tabs[1].content}</p>
                            </div>
                            <video preload='none' autoPlay webkit-playsinline="true" muted playsInline loop src={tabs[1].src} alt='' />
                            <div className='observer' ref={inViewRef2}></div>
                        </div>
                    </div>
                    <div className='stepper-videos'>
                        <div className='stepper-video'>
                            <div className='tabs'>
                                <h4>{tabs[2].title}</h4>
                                <p>{tabs[2].content}</p>
                            </div>
                            <video preload='none' autoPlay webkit-playsinline="true" muted playsInline loop src={tabs[2].src} alt='' />
                            <div  className='observer' ref={inViewRef3}></div>
                        </div>
                    </div>
                    <div className='stepper-videos'>
                        <div className='stepper-video'>
                            <div className='tabs'>
                                <h4>{tabs[3].title}</h4>
                                <p>{tabs[3].content}</p>
                            </div>
                              <img loading='lazy' src={tabs[3].src} alt='' />
                            <div  className='observer' ref={inViewRef4}></div>
                        </div>
                    </div>

                </Grid>

            </Grid>
        </div>
    );
}

export default GostorySteps;