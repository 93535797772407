import { React } from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import SupportFormContent from './supportFormContent';
import {GOOGLE_RECAPTCHA_KEY} from 'helper'

const SupportForm=()=>{
    return(
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
            <SupportFormContent/>
        </GoogleReCaptchaProvider>
    )
}

export default SupportForm;