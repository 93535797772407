import CMPArticle from 'components/resources/cmp/article';
import CMPHeader from 'components/resources/cmp/header';
import React,{ useEffect} from 'react';
import 'assets/styles/resource.css'

const CMP =() => {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div>
            <CMPHeader/>
            <CMPArticle/>
        </div>
    );
}

export default CMP;