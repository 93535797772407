import PropTypes from "prop-types";
import React from 'react';

const FooterLinkItem = ({data}) => {
    return (
        <div className="footer-widget">
            <h4 className="footer-widget-title">{data.title}</h4>
            <div className="footer-widget-content">
                <ul>
                    {data.list.map((item,i) =>(
                        <li key={i}>
                            <a href={item.url} target={item.outSource? "_blank":''} rel="noopener noreferrer">{item.text}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

FooterLinkItem.propTypes = {
    data: PropTypes.object
};

export default FooterLinkItem;
