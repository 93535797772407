import React from 'react';
import {WideContainer} from 'components/mui/containers/PxftContainer';
import { Grid} from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import HeaderCard from 'components/mui/cards/headerCard';
import ContactForm from './contactForm';

const Header =() => {
    const header = {
        title:'Contact Us',
        article:'',
        picsrc:'',
        iconsrc:'images/buyers/buyer-icon.png',
    };
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer className='contact-header-container'>
                    <div className='center-align'>
                        <Grid container className='header-content'>
                            <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                                <Grid item xs={12} sm={6} md={5.5} lg={4.5} className='center-align'>
                                    <HeaderCard title={header.title} content={header.article} iconsrc={header.iconsrc} long={false} button={false}/>
                                </Grid>
                            </Slide>
                            <Grow timeout={{enter:1200}} in={inView}>
                                <Grid item xs={12} sm={6} md={6.5} lg={7.5} className='center-align'>
                                    <ContactForm/>
                                </Grid>
                            </Grow>
                            <div className='long-observer' ref={inViewRef}></div>
                        </Grid>
                    </div>
            </WideContainer>
    );
}

export default Header;