import React from 'react';
import GostoryFeatures from './gostoryFeatures';
import GostorySocial from './gostorySocial';
import GostorySteps from './gostorySteps';
import GostoryMonetize from './gostoryMonetize';

const GostoryArticle=()=> {
    return (
        <div>
            <GostoryFeatures/>
            <GostorySocial/>
            <GostorySteps/>
            <GostoryMonetize/>
        </div>
    );
}

export default GostoryArticle;