import React from 'react';

function SupplyPolicy() {
    return (
        <div id="supply-policy-content" className="tabcontent">
        <div className="updated-time">Last Revised: March 20, 2013</div>
        <h3 className="policy-title">Supply Policy</h3>
        <p>PixFuture is committed to providing buyers and sellers with a safe, transparent, and fair marketplace. These guidelines describe the basic standards for publishers wishing to participate in PixFuture. PixFuture may update these guidelines at any time, and by using the PixFuture you are agreeing to these guidelines and any updated version of these guidelines. PixFuture may accept, decline or take down sites at its sole discretion.</p>
        <p> </p>
        <p><strong>Banned categories</strong><br />Sites featuring the following content may not participate in PixFuture:<br />&#8211; Pornographic or highly suggestive content or images;<br />&#8211; Online gambling;<br />&#8211; Sales of weapons or ammunition;<br />&#8211; Sale of tobacco;<br />&#8211; P2P file-sharing, torrent, or anything that facilitates or promotes copyright infringement;<br />&#8211; Extreme violence;<br />&#8211; Hate content, including sites that advocate the superiority of a specific race or ethnic group;<br />&#8211; Excessive profanity;<br />&#8211; Promotion of illegal drugs or drug paraphernalia;<br />&#8211; Promotion of black-hat hacking, cracking, or warez;<br />&#8211; Any other content that is illegal, promotes illicit or harmful activity, or infringes on the rights of others. This includes sites that provide “how-to” information on bomb-making, lock-picking, and similar topics;<br />&#8211; Defamatory or libelous content;<br />&#8211; Distribution of viruses, malware;<br />&#8211; Pay per click or pay per search programs;<br />&#8211; Chat/Instant Messaging;<br />&#8211; Video chat and live streaming;<br />&#8211; Proxy sites; and/or<br />&#8211; Significant unmoderated UGC (user-generated content).</p>
        <p> </p>
        <p><strong>Restricted categories</strong><br />Please notify your Account Manager if your site contains any of the following content, as special restrictions may apply:</p>
        <p>&#8211; Adult humor;<br />&#8211; Suggestive content;<br />&#8211; Sexual health;<br />&#8211; Significant user-generated content;<br />&#8211; Sale of alcohol; and/or<br />&#8211; Sale of prescription drugs.</p>
        <p> </p>
        <p><strong>Minimum content requirements</strong> in order to participate in PixFuture, sites must contain substantive, original content and demonstrate signs of user engagement. PixFuture does not want sites that:</p>
        <p> </p>
        <p>&#8211; Contain content of little to no value to the user (i.e. boilerplate text, text from Wikipedia, stale news stories, etc.);<br />&#8211; Feature mainly links or content taken from other sites without meaningful commentary or curation;<br />&#8211; Have little content other than ads;<br />&#8211; Have little to no evidence of an engaged user base;<br />&#8211; Appear designed primarily to display ads; and/or<br />&#8211; Are parked or undeveloped domains.</p>
        <p> </p>
        <p><strong>Basic guidelines</strong></p>
        <p>&#8211; The publisher site may not initiate a download or change settings without the user’s knowledge and consent.<br />&#8211; The publisher may not mask or cloak the site’s URL, or employ any means to obscure the true source of traffic.<br />&#8211; The publisher may not artificially inflate impressions, clicks, or requests.<br />&#8211; The publisher site may not install or distribute malware or viruses.<br />&#8211; The publisher may not include or launch fake error messages or system flags.<br />&#8211; A substantial portion of the publisher’s site must be accessible without a login.<br />&#8211; The publisher may not employ any means to induce or trick the user into clicking on an ad. Placing arrows or text such as “Click here” next to an ad is strictly prohibited.<br />&#8211; Publisher sites deemed unsafe by reputable 3rd party malware-detection services may be suspended from PixFuture Market until all user safety issues are addressed.<br />&#8211; Publisher sites must include a privacy policy.</p>
        <p> </p>
        <p><strong>Ad number and position</strong></p>
        <p>&#8211; Ads must be clearly separated from other page content. It must always be clear to the user what is an ad and what is site content.<br />&#8211; Ads may not be obscured or altered in any way. For ads shown in iframes, the iframe must allow the entire creative to be fully visible to the user.<br />&#8211; Ads may not appear in pop-up or pop-under windows.<br />&#8211; Ads may not appear in the body of emails.<br />&#8211; Ads must appear within standard web pages or mobile apps. Ads may not appear in other downloadable applications.<br />&#8211; The page may not contain an excessive number or density of ads. For most pages, no more than 5 ads is appropriate.<br />&#8211; Publishers may load new ads only when:<br />the user navigates to a new page;<br />the current page is substantially updated with new content AND at least 1 minute has passed since the last refresh.</p>
        <p> </p>
        <p><strong>Traffic acquisition</strong></p>
        <p>The publisher must acquire traffic primarily from organic sources.</p>
        <p> </p>
        <p><strong>Site ownership</strong></p>
        <p>Inventory from applications, including browser plug-ins and toolbars, that insert advertisements into the body of 3rd party web pages or other online properties without the website&#8217;s permission is not acceptable.</p>
    </div>
    );
}

export default SupplyPolicy;