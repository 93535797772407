import React from 'react';
import { Grid } from '@mui/material';

function DpaTerms(props) {

    return (
        <div id="dpa-terms-content" className="tabcontent">
        <div className="updated-time">Last Revised: Jan 1, 2023</div>
        <h3 className="policy-title">Data Processing Agreement</h3>
        <p>DATA PRIVACY ADDENDUM – INDEPENDENT CONTROLLERS</p>
    <p>This Data Privacy Addendum (this “<b>Addendum</b>”) is incorporated by reference into any and all operative agreements (each, an “<b>Agreement</b>”),  between  the  company identified below (“<b>Company</b>”) and Pixfuture Media Inc.(“<b>Pixfuture</b>”), and is effective as of the latter date in the signature block contained herein. This Addendum <span></span>(a) establishes the Parties’ relationship and obligations with respect to personal data and/or personal information accessed in accordance with the services provided to Company under the Agreement (the “<b>Services</b>”); and
    <span>(b) replaces and supersedes any existing data processing addendum, attachment, exhibit, or standard contractual clauses that Company and Pixfuture may have entered into previously in connection with the Agreement. Pixfuture and Company are each from time to time referred to herein as a “<b>Party</b>” and collectively as the “<b>Parties</b>”. Capitalized terms used but not defined herein have the meanings given in the Agreement.</span></p>
    <br/>
    <h3>SECTION 1   Definitions.</h3>
    <ol>
        <li data-list-text="a.">
        <p>“<b>Applicable Data Law</b>” means all data protection and privacy laws, regulations and self-regulatory codes applicable to the personal data in question, including, where applicable, the CCPA, the CPA, the CTDPA, the UCPA, the VCDPA, European Data Law, the LGPD, and all FTC guidelines and any other applicable laws, rules and regulations with respect to data privacy.</p>
        </li>
        <li data-list-text="b.">
        <p>The “<b>CCPA</b>” means the California Consumer Privacy Act, Cal. Civ. Code §§ 1798.100 et seq., as amended, including without limitation any and all applicable implementing regulations. The “<b>CPA</b>” means the Colorado Privacy Act, Senate Bill 21-190 (2021), as amended, including without limitation any and all applicable implementing regulations. The “<b>CTDPA</b>” means the Connecticut Data Protection Act, Senate Bill 6 (2022), as amended, including without limitation any and all applicable implementing regulations. The “<b>UCPA</b>” means the Utah Consumer Privacy Act, Senate Bill 227 (2022), as amended, including without limitation any and all applicable implementing regulations. The “<b>VCDPA</b>” means Virginia Consumer Data Protection Act, Va. Code §§ 59.1-575 et seq., as amended, including without limitation any and all applicable implementing regulations.</p>
        </li>
        <li data-list-text="c."><p>
            “<b>European Data Law</b>” means (i) the EU General Data Protection Regulation 2016/679 (“<b>EU GDPR</b>”); (ii) the EU e-Privacy Directive (Directive 2002/58/EC); (iii) the EU GDPR as saved into United Kingdom law by virtue of section 3 of the United Kingdom’s (“<b>UK</b>”) European Union (Withdrawal) Act 2018 (the “<b>UK GDPR</b>”);(iv) the Swiss Federal Act on Data Protection 1992 (“<b>Swiss DPA</b>”); and (v) any and all applicable national laws made under or pursuant to (i), (ii), (iii) and (iv); in each case as may be amended or superseded from time to time.</p>
        </li>
        <li data-list-text="d.">
            <p>The “<b>LGPD</b>” means the Lei Geral de Proteção de Dados (Law No. 13.709/2018), as amended, including without limitation any and all applicable implementing regulations.</p></li>
        <li data-list-text="e."><p>“<b>Restricted Transfer</b>” means (i) where the EU GDPR applies, a transfer of personal data from the European Economic Area to a country outside of the European Economic Area which is not subject to an adequacy determination by the European Commission; (ii) where the UK GDPR applies, a transfer of personal data from the United Kingdom to any other country which is not based on adequacy regulations pursuant to Section 17A of the United Kingdom Data Protection Act 2018;
        (iii) where the Swiss DPA applies, a transfer of personal data from Switzerland to any other country which is not determined to provide adequate protection for personal data by the Federal Data Protection and Information Commission or Federal Council (as applicable); and (iv) where another Applicable Data Law applies, a cross-border transfer of personal data from that jurisdiction to any other country which is not based on adequacy regulations pursuant to that Applicable Data Law.</p></li>
        <li data-list-text="f.">
            <p>“<b>SCCs</b>” means the standard contractual clauses (i) where the EU GDPR or Swiss DPA applies, the contractual clauses annexed to the European Commission’s Implementing Decision 2021/914 of 4 June 2021 on standard contractual clauses for the transfer of personal data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council (“<b>EU SCCs</b>”); (ii) where the UK GDPR applies, standard data protection clauses adopted pursuant to or permitted under Article 46 of the UK GDPR (“<b>UK SCCs</b>”); and (iii) where another Applicable Data Law applies, the standard contractual clauses or other appropriate cross-border transfer mechanisms approved by an appropriate data protection authority or similar body that is adopted or permitted under that Applicable Data Law.</p></li>
        <li data-list-text="g.">
                <p>“<b>business</b>”, “<b>consumer</b>”, “<b>controller</b>”, “<b>processor</b>”, “<b>data subject</b>”, “<b>personal data</b>”, “<b>personal information</b>”, “<b>processing</b>” (and “<b>process</b>”), “<b>recipient</b>”, “<b>sale</b>”, “<b>sensitive data</b>”, “<b>service provider</b>”, “<b>sharing</b>” (and “<b>share(s)</b>”), and “<b>third party</b>” shall have the meanings given in Applicable Data Law.</p></li>
    </ol>
    <br/>
    <h3>SECTION 2   Terms.</h3>
        <ol id="l2">
            <li data-list-text="a."><p>Each Party shall disclose or make available personal data to the other Party for the sole purpose provided in Schedule 1 to this Addendum (the “<b>Purpose</b>”). The Parties shall be treated as separate data controllers and not as joint controllers or as a data controller and data processor.</p>
            </li>
            <li data-list-text="b.">
            <p>Each Party shall be individually and separately responsible for complying with the obligations that apply to it under Applicable Data Law. Without limiting the foregoing, each Party shall (i) maintain a publicly accessible privacy policy on its website that satisfies the requirements of Applicable Data Law, and in particular advises data subjects of their rights and remedies under Applicable Data Law; (ii) conduct and document a data protection assessment that satisfies the requirement of Applicable Data Law; and (iii) implement and maintain appropriate technical and organizational measures for processing of personal data appropriate to the risk and designed to be adequate under Applicable Data Law.</p>
            </li>
            <li data-list-text="c.">
                <p>Neither Party shall sell the personal information of a consumer if such Party has actual knowledge the consumer is less than 16, unless the consumer, in the case of consumers at least 13 years of age, or the consumer’s parent or guardian, in the case of consumers who are less than 13 years of age, has affirmatively authorized the sale of that consumer’s personal information.</p>
            </li>
            <li data-list-text="d.">
                <p>Company shall ensure that appropriate notice and consent mechanisms as may be required by Applicable Data Law are displayed to all data subjects such that Pixfuture can serve cookies lawfully. Pixfuture shall provide Company with such information as Company may reasonably require about Pixfuture’s cookies so that Company can ensure that such notice is provided. Pixfuture shall not use cookies to collect personal data from a data subject that has opted not to receive Pixfuture’s cookies.</p>
            </li>
        </ol>
        <h3>SECTION 3   Restricted Transfers.</h3>
            <p>The Parties agree that when the transfer of personal data under the Agreement is a Restricted Transfer, the SCCs shall be incorporated into this Addendum by this reference, with each Party being deemed to have entered into the SCCs in its own name and on its own behalf as follows:</p>
            <ol id="l3">
                <li data-list-text="a.">
                <p><strong>EU SCCs.</strong></p>
                <p>In relation to personal data that is protected by the EU GDPR, the EU SCCs shall apply completed as follows:</p>
                <p>(i) Module One shall apply; (ii) Company shall ensure that the information called for by Section II, Clause 8.2(a) of the EU SCCs, as well as a copy of the EU SCCs, are supplied free of charge to all data subjects; (iii) in Clause 7, the optional docking clause shall <u>not</u> apply; (iv) in Clause 11, the optional language shall <u>not</u> apply;
                (v) in Clause 17, Option 1 shall apply, and the EU SCCs shall be governed by Irish law; (vi) in Clause 18(b), disputes shall be resolved before the courts of Ireland; (vii) Annex I of the EU SCCs shall be deemed completed with the information set out in Schedule 1 to this Addendum; and (viii) Annex II of the EU SCCs shall be deemed completed with the information set out in Schedule 2 to this Addendum<i>.</i></p></li>
                <li data-list-text="b.">
                <p><strong>UK SCCs. </strong></p>
                <p>In relation to personal data that is protected by the UK GDPR, the UK SCCs will apply completed as follows: (i) as set out above in Section 3.a of this Addendum and the EU SCCs shall be deemed amended as specified by Part 2 of the International Data Transfer Addendum to the EU Commission Standard Contractual Clauses issued by the Information Commissioner’s Office under s.119A(1) of the UK Data Protection Act 2018 (“UK Addendum”) in respect of the transfer of such personal data; and (ii) tables 1 to 3 in Part 1 of the UK Addendum shall be deemed completed with the information set out above at Section 3.a (as applicable), in Schedule 1 and Schedule 2 of this Addendum and table 4 in Part 1 shall be deemed completed by selecting “neither party”.</p></li>
                <li data-list-text="c.">
                <p><strong>Swiss SCCs. </strong></p>
                <p>In relation to personal data that is protected by the Swiss DPA, the EU SCCs shall apply as set out in Section 3.a of this Addendum amended as follows: (i) references to ‘Regulation (EU) 2016/679’ in the EU SCCs shall be deemed to refer to the Swiss DPA; (ii) references to specific articles of ‘Regulation (EU) 2016/679’ shall be deemed replaced with the equivalent article or section of the Swiss DPA; (iii) references to ‘EU’, ‘Union’ and ‘Member State’ shall be deemed replaced with ‘Switzerland’; (iv) references to the ‘competent supervisory authority’ shall be replaced with the ‘Swiss Federal Data Protection Information Commissioner’; and (v) in Clause 18(b), disputes shall be resolved before the competent courts of Switzerland.</p>
                </li>
                <li data-list-text="d.">
                <p><b>Other jurisdictions. </b>In relation to personal data that is protected by another Applicable Data Law, the Parties agree that such SCCs shall automatically apply to the transfer of personal data from Company to Pixfuture and, where applicable shall be deemed completed on a mutatis mutandis basis to the completion of the SCCs as described above.</p></li></ol>
                <h3>SECTION 4   LGPD. </h3>
                <p>In respect of data subjects whose personal data is processed in the course of providing the Services, Company will be responsible for providing notice in accordance with the LGPD, including but not limited to notice as required under Article 18 of the LGPD. Each Party shall separately be responsible for fulfilling requests they receive from data subjects to exercise their rights under the LGPD.</p>
                <h3>SECTION 5   UNITED STATES.</h3>
                <ol id="l4"><li data-list-text="a."><p>To the extent either Party collects and shares the personal information of California residents, each Party (i) shall be considered a business under the CCPA; and (ii) will only process personal information in furtherance of the Purpose, unless required by Applicable Data Law.</p></li>
                <li data-list-text="b."><p>Each Party (the “<b>Discloser</b>”) may disclose personal information of United States data subjects to a person or entity that is a processor, contractor and/or service provider (each a “<b>Vendor</b>”) in order to fulfill the Purpose, provided the Discloser prohibits the Vendor from (i) selling or sharing such personal information to any third party in violation of Applicable Data Law;</p>
                </li>
                </ol>
                <p>(ii) retaining, using, or disclosing such personal information for any reason other than for the Purpose, detecting data security incidents, and/or protecting against fraudulent or illegal activity;</p>
                <p>(iii) combining such personal information with other personal information unless permited by Applicable Data Law; (iv) in the case of a contractor, accessing such personal information without first certifying that said contractor understands the restrictions of Applicable Data Law; (v) accessing such personal information unless the Parties can monitor the Vendor’s compliance. Without limiting the foregoing, each Party will ensure that any Vendor that may receive such personal information first executes a written contract compatible with Applicable Data Law.</p>
        <br/>
        <h3>SECTION 6   Miscellaneous.</h3>
                <ol>
                    <li data-list-text="a.">
                        <p><strong>Conflicts. </strong></p>
                        <p>In the event of any inconsistency between the Agreement, this Addendum and/or any SCCs, the superiority of governing terms and conditions are: first, the SCCs for the relevant jurisdiction; second, this Addendum; and third, the Agreement.</p>
                    </li>
                    <li data-list-text="b."><p><strong>Entire agreement.</strong></p><p>This Addendum is the Parties’ entire agreement as it relates to the Parties’ obligations under Applicable Data Law and supersedes all related prior and contemporaneous oral understandings, representations, prior discussions, letters of intent, or agreements (executed or otherwise).</p></li>
                    <li data-list-text="c."><p><strong>No further amendment.</strong></p><p>Except as modified by this Addendum, the Agreement remains unmodified and in full force and effect.</p>
                    </li>
                </ol>
        <br/>
        <form className="center-align">
            <Grid container sx={{ p: 2 }} alignItems="center" justify="center">
                <Grid item sx={{ p: 2 }} xs={12} md={6} className='center-align' flexDirection='column'>
                    <h5>Pixfuture Media Inc.</h5><br/>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Signed : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Name : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Title : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Email : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Address : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Date : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} md={6} className='center-align' flexDirection='column'>
                    <h5>__________________("Company").</h5><br/>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Signed : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Name : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Title : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Email : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Address : </b></label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                    <Grid container sx={{ p: 2}} className='center-align'>
                        <Grid item xs={4} md={2}><label><b>Date : </b> </label></Grid>
                        <Grid item xs={8} md={10}><input type="text" style={{width:'80%',height:'20px'}}/></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
        <h3>SCHEDULE 1</h3>
                <ol>
                    <li data-list-text="A.">
                        <p><strong>LIST OF PARTIES</strong></p>
                        <p><strong>Data exporter(s):</strong>Same as Company (see information above).</p>
                        <p>Activities relevant to the personal data transferred under the Clauses: Facilitate the sale of space to display ads on any website, webpage, and/or application or any other similar property that is owned, operated or controlled by Company, or on which Company has a contractual right to serve ads and is approved for Services by Pixfuture; Placement of ads on any website, webpage, and/or application or any other similar property; Measuring and optimizing the performance of the Parties’ digital marketing activities.</p>
                        <p>Signature and date: See above.</p>
                        <p>Role (controller/processor): Independent Data Controller</p>
                        <p><strong>Data importer(s):</strong> Pixfuture</p>
                        <p>Activities relevant to the personal data transferred under the Clauses: Facilitate the sale of space to display ads on any website, webpage, and/or application or any other similar property that is owned, operated or controlled by Company, or on which Company has a contractual right to serve ads and is approved for Services by Pixfuture; Placement of ads on any website, webpage, and/or application or any other similar property; Measuring and optimizing the performance of the Parties’ digital marketing activities.</p>
                        <p>Signature and date: See above.</p>
                        <p>Role (controller/processor): Independent Data Controller</p>
                    </li>
                    <li data-list-text="B.">
                        <p><strong>DESCRIPTION OF TRANSFER</strong></p>
                        <p>Categories of data subjects whose personal data is transferred: End users of data exporter (or Company).</p>
                        <p>Categories of personal data transferred: Online identifiers provided by devices, applications, tools and protocols, such as internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags. Other data: exporter’s general marketing and transactional communications and personal data use may span broad categories of any data relevant to data exporter’s relationship with the data subject, and may vary from time to time.</p>
                        <p>Sensitive data transferred (if applicable) and applied restrictions or safeguards that fully take into consideration the nature of the data and the risks involved, such as for instance strict purpose limitation, access restrictions (including access only for staff having followed specialized training), keeping a record of access to the data, restrictions for onward transfers or additional security measures: None.</p>
                        <p>The frequency of the transfer (e.g., whether the data is transferred on a one-off or continuous basis): Daily.</p>
                        <p>Nature of the processing: For the data importer to provide the Services and the processing of personal data of end users of data exporter.</p>
                        <p><i>Purpose(s) of the data transfer and further processing: </i>For the data importer to provide the Services, including but not limited to the following: Facilitate the sale of space to display ads on any website, webpage, and/or application or any other similar property that is owned, operated or controlled by Company, or on which Company has a contractual right to serve ads and is approved for Services by Pixfuture; Placement of ads on any website, webpage, and/or application or any other similar property; Measuring and optimizing the performance of the Parties’ digital marketing activities.</p>
                        <p>The period for which the personal data will be retained, or, if that is not possible, the criteria used to determine that period: The greater of the Term or twelve (12) months.</p>
                        <p>For transfers to (sub-) processors, also specify subject matter, nature and duration of the processing: Not applicable to the extent each party is an Independent Data Controller. Notwithstanding the foregoing, each Party shall for all personal data exchanged as part of this Agreement and independently enter into an agreement with their respective processors specifying subject matter, nature and duration of the processing. In the event of the use of processors and/or sub-processors, each Party shall be responsible for complying with the requirements of Article 28 of the EU GDPR. Accordingly, each Party shall, inter alia: use only processors that can provide the necessary guarantees that they implement appropriate technical and organizational measures in such a way as to ensure that processing complies with the requirements of the EU GDPR and safeguards the rights of the data subject; ensure that a valid data processing arrangement is in place between the relevant Party and the processor; and ensure that there is a valid sub-processor arrangement between the processor and any sub-processor.</p>
                    </li>
                    <li data-list-text="C.">
                        <p><strong>COMPETENT SUPERVISORY AUTHORITY</strong></p>
                        <p>Identify the competent supervisory authority/ies in accordance with Clause 13: The Data Protection Commission of Ireland.</p>
            <br/>
            <h3>SCHEDULE 2 </h3>
                        <p>TECHNICAL AND ORGANIZATIONAL MEASURES INCLUDING TECHNICAL AND ORGANIZATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA:</p>
                        <p>Each Party shall be responsible for implementing appropriate technical and organizational measures to ensure and demonstrate that processing is in compliance with the EU GDPR; taking into account the nature, scope, context and purposes of the processing involved, as well as the risks of varying degrees of likelihood and severity for the rights and freedoms of natural persons. The measures shall be reviewed and updated as necessary (Article 24 of the EU GDPR) but shall include but not be limited to the following:</p>
                        <ol id="l7">
                        <li data-list-text="1.">
                            <p>Measures of pseudonymisation and encryption of personal data: <span>Pseudonymization of personal data of data subjects where possible. Application of security controls, e.g., data siloing, restricting and monitoring access, designating confidential status, employing best-practice technologies.</span></p>
                        </li>
                        <li data-list-text="2."><p>Measures for ensuring ongoing confidentiality, integrity, availability and resilience of processing systems and services: Development and implementation of technologies and systems that accord with industry standards. Evaluation and monitoring of security of each important third-party partner during initiation of and periodically over the life of its relationship with Pixfuture.</p></li>
                        <li data-list-text="3."><p>Measures for ensuring the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident: <span>Development and implementation of response plans for incidents of concern, which permit investigation, mitigation and notification. Such plans are organized according to security risk and include internal and external messaging protocols.</span></p></li>
                        <li data-list-text="4."><p>Processes for regularly testing, assessing and evaluating the effectiveness of technical and organizational measures in order to ensure the security of the processing:<span>Performance of periodic reviews by privacy, security and engineering teams to ensure all measures align with best industry practices.</span></p></li>
                        <li data-list-text="5."><p>Measures for user identification and authorization: <span>Development and implementation of procedures to authenticate and respond to DSARs and to limit systems access to authorized individuals.</span></p></li>
                        <li data-list-text="6."><p>Measures for the protection of data during storage: <span>Rejection of all sensitive data and/or special categories of personal data potentially sourced by Company; </span><span>pseudonymization and minimization of personal data of data subjects where possible; storage of data only so long as needed and in accordance with agreed-upon timeframes.</span></p></li>
                        <li data-list-text="7."><p>Measures for ensuring physical security of locations at which personal data are processed: <span>Restriction of access to storage facilities on need-to-know basis; implementation of access and security controls in accordance with industry standards; training of all relevant personnel regarding security and protection of data.</span></p></li>
                        <li data-list-text="8."><p>Measures for ensuring system configuration, including default configuration: <span>Implementation of configuration management tools where appropriate.</span></p></li>
                        <li data-list-text="9."><p>Measures for internal IT and IT security governance and management: <span>Appointment of persons responsible for maintaining security management and data protection.</span></p></li>
                        <li data-list-text="10."><p>Measures for certification/assurance of processes and products: <span>Implementation of relevant controls and processes.</span></p></li>
                        <li data-list-text="11."><p>Measures for ensuring data minimisation: <span>Rejection of all sensitive data and/or special categories of personal data potentially sourced by Company; pseudonymization and minimization of personal data of data subjects where possible; storage of data only so long as needed and in accordance with agreed-upon timeframes</span>; limitation to data necessary to perform the Services.</p></li>
                        <li data-list-text="12."><p>Measures for ensuring limited data retention: <span>Implementation and periodic review of data retention and destruction policies and procedures.</span></p></li>
                        <li data-list-text="13."><p>Measures for ensuring accountability: <span>Implementation and periodic review of data mapping and data protection policies and procedures.</span></p></li>
                        <li data-list-text="14."><p>Measures for allowing data portability and ensuring erasure: <span>Development and implementation of procedures to authenticate and respond to DSARs.</span></p></li>
                        </ol>
        </li>
        </ol>
    </div>
    );
}

export default DpaTerms;