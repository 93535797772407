import React, { useState, useEffect } from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import parse from 'html-react-parser';
import 'assets/styles/blog.css';
import 'assets/styles/company.css';

const BlogDetailsArticle = ({ blogs,blog }) => {

    const [prev, setPrev] = useState('');
    const [prevUrl, setPrevUrl] = useState('');
    const [next, setNext] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    useEffect(() => {
        let idx = blogs.indexOf(blog);
        setPrev(idx + 1);
        setPrevUrl(blogs[prev]?.url)
        setNext(idx - 1);
        setNextUrl(blogs[next]?.url)
    },
        [blogs,blog,prev,next]
    )
    return (
        <WideContainer>
            <div className='ck-content blog-content center-align'>
                <NarrowContainer>
                    {blog.html && parse('' + blog.html)}
                    <div className='btn-container'>
                        <Button size="small" href={prev < blogs.length ? `/blogs/blog/${prevUrl}` : ''} style={{ marginLeft: 0, marginRight: 'auto', display: prev < blogs.length.toString() ? 'flex' : 'none' }}><KeyboardArrowLeft />Prev</Button>
                        <Button size="small" href={next >=0 ? `/blogs/blog/${nextUrl}` : ''} style={{ marginLeft: 'auto', marginRight: 0, display: next >=0 ? 'flex' : 'none' }}>Next<KeyboardArrowRight /></Button>
                    </div>
                </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default BlogDetailsArticle;
