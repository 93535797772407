import Desktop from 'components/video/screens/desktop';
import React,{ useEffect} from 'react';

const DesktopVideo=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div>
            <Desktop/>
        </div>
    );
}

export default DesktopVideo;