import React from 'react';
import GostoryFeatures from './gostoryFeatures';
import GostoryMonetize from '../gostory/gostoryMonetize';

const GostoryArticle=()=> {
    return (
        <div>
            <GostoryFeatures/>
            <GostoryMonetize/>
        </div>
    );
}

export default GostoryArticle;