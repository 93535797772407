import React, { useState, useEffect } from 'react';
import { Grid, Grow, Slide, Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer'
import MegaButton from 'components/mui/buttons/magaButton';
import HeaderParticles from './effects/headerParticles';
import MobileParticles from './effects/mobileParticles';
import NewsTab from './newsTab';


const Header = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [smallScreen, setSmallScreen] = useState();

    const handleCheckWidth = () => {
        setWindowWidth(window.innerWidth)
    }
    const backgroundCss = {
        backgroundImage: `url('/images/home/banner-back.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    const containerRef = React.useRef(null);

    useEffect(() => {
        window.addEventListener('resize', handleCheckWidth);
        setSmallScreen(windowWidth < 900 ? true : false)
        return (() => {
            window.removeEventListener('resize', handleCheckWidth);
        })
    }, [windowWidth])

    return (
        <div className='home-header-container' style={backgroundCss}>
            {
                smallScreen ? <MobileParticles id="mobile-tsparticles" /> : <HeaderParticles id="header-tsparticles" />
            }
            <div className='home-title-container'>
                <div className='home-title'>
                    <div className='words-container'>
                        <Slide timeout={{ enter: 1200 }} in={inView} direction="down" container={containerRef.current}>
                            <div className='title'>
                                <h1>The Future of Audience</h1>
                                <h1>Monetization and Growth</h1>
                            </div>
                        </Slide>
                        <Slide timeout={{ enter: 1200 }} in={inView} direction="up" container={containerRef.current}>
                            <div>
                                <p className='pxft-text-small'>We help publishers maximize revenue and optimize ad operations</p>
                                <p className='pxft-text-small'>Monetization and audience engagement.</p>
                            </div>
                        </Slide>
                    </div>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="up" container={containerRef.current}>
                        <div className='btn-container'>
                            <a href="https://portal.pixfuture.com/registration" target="_blank" rel="noreferrer">
                                <MegaButton width='200px' height='50px' content='get started' />
                            </a>
                        </div>
                    </Slide>
                    <div className='shapes'>
                        <Grow timeout={{ enter: 2200 }} in={inView}>
                            <svg className='block' xmlns="http://www.w3.org/2000/svg" width="310" height="310" viewBox="0 0 310 310">
                                <g id="blocks" transform="translate(6.309 5)">
                                    <path id="Path_4536" data-name="Path 4536" d="M440.876,327.338l.005-48.619H740.876v48.619" transform="translate(-442.185 -278.718)" fill="none" stroke="#fcb402" strokeLinecap="round" strokeWidth="10" />
                                    <path id="Path_4537" data-name="Path 4537" d="M0,46.42V0H300V46.42" transform="translate(298.692 300) rotate(180)" fill="none" stroke="#fcb402" strokeLinecap="round" strokeWidth="10" />
                                </g>
                            </svg>
                        </Grow>
                        <Fade timeout={{ enter: 2800 }} in={inView}>
                            <svg className='rectangle' xmlns="http://www.w3.org/2000/svg" width="430" height="460" viewBox="0 0 430 460">
                                <path d="M0,0H584V632H0Z" fill="rgba(160,160,160,0.1)" />
                            </svg>
                        </Fade>
                    </div>

                    <div className='observer' ref={inViewRef}></div>
                </div>
                <Grid container className='center-align home-badge-contianer'>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="up" container={containerRef.current}>
                        <Grid container className='center-align home-badge'>
                            <Grid item xs={3} className='center-align badge-img'>
                                <a href='https://developers.google.com/third-party-ads/googleads-vendors' target="_blank" rel="noreferrer">
                                    <img loading='lazy' src='/images/home-badge-1.png' alt='' />
                                </a>
                            </Grid>
                            <Grid item xs={3} className='center-align badge-img'>
                                <a href='https://iabeurope.eu/?s=pixfuture&post_type=vendor' target="_blank" rel="noreferrer">
                                    <img loading='lazy' src='/images/home-badge-2.png' alt='' />
                                </a>
                            </Grid>
                            <Grid item xs={3} className='center-align badge-img'>
                                <a href='https://www.dnb.com/business-directory/company-profiles.pixfuture_media_inc.5e62bee9419dbb3f134205b33b10f85d.html' target="_blank" rel="noreferrer">
                                    <img loading='lazy' src='/images/home-badge-3.png' alt='' />
                                </a>
                            </Grid>
                            <Grid item xs={3} className='center-align badge-img'>
                                <a href='https://docs.prebid.org/dev-docs/bidders/pixfuture.html' target="_blank" rel="noreferrer">
                                    <img loading='lazy' src='/images/home-badge-4.png' alt='' />
                                </a>
                            </Grid>
                        </Grid>
                    </Slide>
                </Grid>
            </div>

            {/* <NewsTab/> */}
        </div>
    );
}

export default Header;