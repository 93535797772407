import React from 'react';
import {WideContainer, NarrowContainer } from 'components/mui/containers/PxftContainer';
import { BlueDivider,YellowDivider } from 'components/mui/divider/divider';
import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import GostorCard from 'components/mui/cards/gostoryCard';
import GostoryPlayer from './gostoryPlayer';



const GostoryFeatures=()=> {
    const features = [
        {
            title:'Highlight\nYour Existing\n Content to Visitors.',
            article:'Web Stories are visually great-looking and eye-catching. Created to engage with visitors and show top content.',
            picsrc:'',
        },
        {
            title:'Customize\nPlayer the Way\nYou Want.',
            article:'Customize your player just the way you want it to fit your current website layout.',
            picsrc:'/images/publishers/gostory/feature-1.png',
        },
        {
            title:'Enjoy\nBlazing-fast\nLoading speed.',
            article:'GoStory has achieved high evaluation on Google Web Vitals..',
            picsrc:'/images/publishers/gostory/feature-3.gif',
        },
        {
            title:'Engage\nwith\nYour Visitors.',
            article:'Publish the most appealing content to improve user engagement of your visitors and return rate.',
            picsrc:'/images/publishers/gostory/feature-2.png',
        },
]
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const [inViewRef4, inView4] = useInView({
        triggerOnce: true,
    });
    const [inViewRef5, inView5] = useInView({
        triggerOnce: true,
    });
    const containerStyle={
        backgroundColor:'var(--pxft-lightgrey)',
        position:'relative'
    }
    return (
        <WideContainer>
            <div className='center-align gostory-content'>
                <NarrowContainer>
                    <YellowDivider width='57%' height='80px' marginBottom='30px' marginLeft='0'/>
                    <Grid className='center-align mobile-reverse' width='100%'>
                        <Grid item xs={12} sm={6} md={6} width='100%'>
                            <GostoryPlayer/>
                        </Grid>
                        <Slide timeout={{enter:1200}} in={inView1} direction="left">
                            <Grid item xs={12} sm={6} md={6} className='center-align'>
                                <GostorCard title={features[0].title} content={features[0].article}/>
                            </Grid>
                        </Slide>
                        <div className='observer' ref={inViewRef1}></div>
                    </Grid>
                </NarrowContainer>
            </div>
            <div style={containerStyle}>
                <div className='center-align gostory-content'>
                <BlueDivider width='60%' height='20px' marginRight='0' marginBottom='30%'/>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView2} direction="right">
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            <GostorCard title={features[1].title} content={features[1].article}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView2}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                              <img loading='lazy' className='contentPic' src={features[1].picsrc} alt=''/>
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef2}></div>
                </div>
            </div>
                <div className='center-align gostory-content'>
                <YellowDivider width='40px' height='120px' marginTop='0px' marginRight='20%'/>
                <BlueDivider width='60%' height='40px' marginBottom='30px' marginLeft='0'/>
                <NarrowContainer className='mobile-reverse'>
                    <Grow timeout={{enter:1200}} in={inView3}>
                        <Grid item xs={12} sm={6} md={6}>
                                <div className='pic-speed-des'>
                                  <img loading='lazy' className='contentPic' src={features[2].picsrc} alt=''/>
                                <div>
                                    <span className='des-1'>Google Web Vitals</span>
                                    <p className='des-2'>97%</p>
                                    <span className='des-3'>Performance Score</span>
                                </div>
                            </div>
                        </Grid>
                    </Grow>
                    <Slide timeout={{enter:800}} in={inView3} direction="left">
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <GostorCard title={features[2].title} content={features[2].article}/>
                        </Grid>
                    </Slide>
                </NarrowContainer>
                <div className='observer' ref={inViewRef3}></div>
            </div>
            <div style={containerStyle}>
                <div className='center-align gostory-content'>
                <YellowDivider width='60%' height='20px' marginTop='30px' marginRight='0'/>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView4} direction="right">
                        <Grid item xs={12} sm={5} md={5} className='center-align'>
                            <GostorCard title={features[3].title} content={features[3].article}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView4}>
                        <Grid item xs={12} sm={7} md={7} className='center-align'>
                              <img loading='lazy' className='contentPic' src={features[3].picsrc} alt=''/>
                        </Grid>
                    </Grow>
                    <Grid container>
                        <Slide timeout={{enter:1500}} in={inView5} direction="up">
                        <Grid item xs={4} className='rate-box'>
                            <div className='number'>
                                <h1>2.7</h1>
                                <span>x</span>
                            </div>
                            <p>Increase in return rate</p>
                        </Grid>
                        </Slide>
                        <Slide timeout={{enter:2000}} in={inView5} direction="up">
                        <Grid item xs={4} className='rate-box'>
                            <div className='number'>
                                <h1>5.3</h1>
                                <span>x</span>
                            </div>
                            <p>Higher user engagement</p>
                        </Grid>
                        </Slide>
                        <Slide timeout={{enter:2500}} in={inView5} direction="up">
                        <Grid item xs={4} className='rate-box'>
                            <div className='number'>
                                <h1>2.1</h1>
                                <span>x</span>
                            </div>
                            <p>Increase in time spend</p>
                        </Grid>
                        </Slide>
                        <div ref={inViewRef5}></div>
                    </Grid>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef4}></div>
                </div>
            </div>
        </WideContainer>
    );
}

export default GostoryFeatures;