import React from 'react';
import { Grid,Paper} from '@mui/material';
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    max-width:350px;
    height:120px;
    margin:10px 5px;
    overflow: hidden;

    & .smallblogcard {
        height:100%;
    }
    & .smallblogcard-pic {
        height:100%;
        overflow: hidden;
        display:flex;
        justify-content:center;
    }
    & .smallblogcard img{
        height:120px;
        weight:120px;
        object-fit: cover;
        -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1s cubic-bezier(0, 0, 0.2, 1); };
    }

    & .smallblogcard:hover img{
        height:120px;
        weight:120px;
        object-fit: cover;
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    & .smallblogcard-title {
        color: black;
        white-space: pre-line;
        justify-content:center;
        padding:6px 0;
        cursor: pointer;
    }
    & .smallblogcard-time {
        position:absolute;
        bottom:10px;
        color: var(--pxft-midgrey);
        display:flex;
        align-items:center;
        flex-direction:row;
    }

    & .smallblogcard-content {
        position:relative;
        padding:10px 15px;
        color: var(--pxft-midgrey);
        white-space: pre-line;
        cursor: pointer;
    }
    `

const BlogCardSmall = ({post})=> {
    const blogLink ='/blogs/blog/'+post.url
    return (
        <CardPaper elevation={8}>
            <a href={blogLink} rel="noopener noreferrer">
                <Grid container className='smallblogcard'>
                    <Grid item xs={4} className='smallblogcard-pic'>
                          <img loading='lazy' src={post.img_src} alt=''/>
                    </Grid>
                    <Grid item xs={8} className='smallblogcard-content'>
                        <h5 className='smallblogcard-title'>
                            {post.title}
                        </h5>
                        <p className='smallblogcard-time'><CalendarMonthIcon sx={{pr:0.5}}/> {post.time}</p>
                    </Grid>
                </Grid>
            </a>
        </CardPaper>
    );
}

export default BlogCardSmall;