import React from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const ArticlePaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    padding: 50px 30px;
    margin: 40px 0;

    & .title {
        color: black;
        white-space: pre-line;
    }
    & .quotation img{
        width:80px;
        position:absolute;
        top:-40px;
        right:20px;
    }
    & p {
        color: var(--pxft-midgrey);
        white-space: pre-line;
    }
`
const QuoteCard = ({title='',content=''}) => {
    return (
        <ArticlePaper elevation={10}>
            <div className='quotation'>
                  <img loading='lazy' src='/images/quotation.png' alt=''/>
            </div>
            <h1 className='title'>{title}</h1>
            <p>{content}</p>
        </ArticlePaper>
    );
}

export default QuoteCard;